import { createReducer, on } from "@ngrx/store";
import { CardOrder } from "../../models/cards/card-order";
import * as CardsActions from '../actions/cards.actions';
import { PaginatedApiResponse } from "../../models/paginated-api-response";

export interface CardsState {
  cardOrders: PaginatedApiResponse<CardOrder>;
}

export const initialCardsState: CardsState = {
  cardOrders: {
    "pageNumber": 1,
    "pageSize": 5,
    "totalPages": 0,
    "totalRecords": 0,
    "data": []
  },
}

export const cardsReducer = createReducer(
  initialCardsState,
  on(CardsActions.storeCardOrdersAction, (state, { data }) => ({
    ...state,
    cardOrders: data
  })),
  on(CardsActions.updateCardOrderAction, (state, { data }) => ({
    ...state,
    cardOrders: {
      ...state.cardOrders,
      data: state?.cardOrders?.data.map(item => item.id === data?.id ? data : item)
    }
  })),
  on(CardsActions.deleteCardOrderAction, (state, { id}) => ({
    ...state,
    cardOrders: {
      ...state.cardOrders,
      data: state?.cardOrders?.data.filter(item => item.id !== id)
    }
  })),
);
