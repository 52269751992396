<div class="d-flex classes">
  <div class="d-flex flex-column w-100">
    <ng-container
      *ngIf="futureClasses$ | async as futureClasses"
      [ngTemplateOutlet]="bookedClasses"
      [ngTemplateOutletContext]="{ futureClasses }"
    >
    </ng-container>

    <ng-container
      *ngIf="futureClasses"
      [ngTemplateOutlet]="bookedClasses"
      [ngTemplateOutletContext]="{ futureClasses }"
    >
    </ng-container>

    <ng-container *ngIf="pastClasses$ | async as pastClasses">
      <ng-container *ngIf="pastClasses?.length">
        <div class="text-grey mb-3 mt-4 font-weight-bold">
          {{ translateEnum.Common.CLASSES_HISTORY | translate }}
        </div>
        <div class="class-box mb-4" *ngFor="let cls of pastClasses">
          <div *ngIf="!cls.checkRemoval" class="class-box-content d-flex">
            <div
              class="
                d-flex
                justify-content-between
                left-section
                align-items-center
              "
            >
              <div class="d-flex flex-column big-gap justify-content-between">
                <div class="d-flex flex-column small-gap">
                  <div class="font-weight-bold">{{ cls.name }}</div>
                  <div class="text-medium">
                    {{ translateEnum.Common.WITH | translate }}
                    <span class="font-weight-bold">{{ cls.trainerName }}</span>
                  </div>
                </div>
                <div class="d-flex flex-column small-gap text-medium">
                  {{ datePipe.transform(cls.date, dateFormat) }}
                  <div class="font-weight-bold">
                    {{ datePipe.transform(cls.date, tableHourFormat) }}
                  </div>
                </div>
              </div>
              <div
                class="
                  d-flex
                  flex-column
                  big-gap
                  text-small
                  justify-content-between
                "
              >
                <div class="d-flex flex-column small-gap align-items-end">
                  <div>{{ cls.classType }}</div>
                  <div>{{ cls.roomName }}</div>
                </div>
                <div class="d-flex flex-column small-gap align-items-end">
                  <div>{{ cls.durationLabel }}</div>
                  <div>
                    {{ cls.classAttendanceNumber }}/{{ cls.classCapacity }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="right-section d-flex flex-column justify-content-center"
            >
              <div
                class="history-label text-capitalize"
                [class.red]="!cls.checkedIn"
              >
                {{
                  (cls.checkedIn
                    ? translateEnum.Common.PRESENT
                    : translateEnum.Common.ABSENCE
                  ) | translate
                }}
              </div>
            </div>
          </div>
          <div
            *ngIf="cls.checkRemoval"
            class="
              class-box-content
              d-flex
              flex-column
              align-items-center
              justify-content-between
            "
          >
            <div>{{ translateEnum.Common.REMOVE_CHECKIN | translate }}</div>
            <div class="d-flex big-gap">
              <div class="accept-remove" (click)="removeEntry(cls.bookingId)">
                {{ translateEnum.Common.YES | translate }}
              </div>
              <div class="decline-remove" (click)="cls.checkRemoval = false">
                {{ translateEnum.Common.NO | translate }}
              </div>
            </div>
          </div>
          <div
            class="blue-overlay"
            *ngIf="cls.removable && !cls.checkRemoval"
            (click)="cls.checkRemoval = true"
          >
            {{ translateEnum.Common.DELETE | translate }}
          </div>
        </div></ng-container
      >
    </ng-container>
  </div>
</div>

<ng-template #bookedClasses let-futureClasses="futureClasses">
  <div class="d-flex flex-row justify-content-between mb-3">
    <div class="font-weight-bold d-flex flex-column justify-content-center">
      <span class="font-weight-bold">{{
        translateEnum.Common.RESERVED_CLASSES | translate
      }}</span>
    </div>
    <div *ngIf="loadInfo" class="decline-remove" (click)="book()">Rezerva</div>
  </div>

  <ng-container *ngIf="!futureClasses.length">
    <div
      class="
        d-flex
        justify-content-center
        flex-column
        align-items-center
        mt-2
        p-2
      "
    >
      <img
        class="empty-classes-img"
        alt="empty"
        src="assets/images/no-classes.svg"
      />
      <div class="empty-classes-label">
        Nu exista clase rezervate pentru moment
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="futureClasses.length">
    <div class="class-box d-flex mb-4" *ngFor="let cls of futureClasses">
      <div class="class-box-content d-flex">
        <div
          class="d-flex justify-content-between left-section align-items-center"
        >
          <div class="d-flex flex-column big-gap">
            <div class="d-flex flex-column small-gap">
              <div class="font-weight-bold">{{ cls.name }}</div>
              <div class="text-medium">
                {{ translateEnum.Common.WITH | translate }}
                <span class="font-weight-bold">{{ cls.trainerName }}</span>
              </div>
            </div>
            <div class="d-flex flex-column small-gap text-medium">
              <div>
                {{ datePipe.transform(cls.date, dateFormat) }}
              </div>
              <div class="font-weight-bold">
                {{ datePipe.transform(cls.date, tableHourFormat) }}
              </div>
            </div>
          </div>
          <div
            class="
              d-flex
              flex-column
              big-gap
              text-small
              justify-content-between
            "
          >
            <div class="d-flex flex-column small-gap align-items-end">
              <div>{{ cls.classType }}</div>
              <div>{{ cls.roomName }}</div>
            </div>
            <div class="d-flex flex-column small-gap align-items-end">
              <div class="duration">{{ cls.durationLabel }}</div>
              <div>{{ cls.classAttendanceNumber }}/{{ cls.classCapacity }}</div>
            </div>
          </div>
        </div>
        <div class="padding-small d-flex flex-column justify-content-center">
          <div class="action-button" (click)="checkInUser(cls)">
            <span *ngIf="!loadInfo">{{
              cls.checkedIn
                ? (translateEnum.Common.DELETE | translate)
                : "Check in"
            }}</span>
            <span *ngIf="loadInfo">Check in</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
