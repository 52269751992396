import { Component, Input, OnInit } from '@angular/core';
import { EntryLog } from '../../../../models/member-logs/entry-log';
import { MemberDetailsService } from '../../../../services/member-details.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { dateFormatHour } from '../../../utilities/constants';
import { DatePipe } from '@angular/common';
import { TranslationEnum } from '../../../../../assets/i18n/translation-enum';

@Component({
  selector: 'app-check-in-history',
  templateUrl: './check-in-history.component.html',
  styleUrls: ['./check-in-history.component.scss'],
})
export class CheckInHistoryComponent implements OnInit {
  entries$: Observable<EntryLog[]>;
  @Input() memberId: string;
  @Input() locationId: string;
  dateFormatHour = dateFormatHour;
  blob: Blob;

  constructor(
    private memberDetailsService: MemberDetailsService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.entries$ = this.memberDetailsService
      .getEntityLogs('entries', this.memberId, this.locationId, 1, 10)
      .pipe(map((data) => data.data));
  }

  downloadReport() {
    this.memberDetailsService
      .downloadMemberEntriesLogs(this.memberId, this.locationId)
      .subscribe((data: Blob) => {
        this.blob = new Blob([data], { type: 'text/csv' });

        const currentDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');

        const downloadURL = window.URL.createObjectURL(this.blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `report-entries-logs-${this.memberId}-${currentDate}.csv`;
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(() => {
          window.URL.revokeObjectURL(downloadURL);
          link.remove();
        }, 100);
      });
  }

  TranslationEnum = TranslationEnum;
}
