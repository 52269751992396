<div
  [class.noActions]="!actions || !actionsDropdown"
  [class.noData]="
    !paginatedResponse?.totalRecords || !paginatedResponse?.data?.length
  "
>
  <p-table
    #pTable
    *ngIf="showTable"
    [value]="listData"
    [columns]="columns"
    [paginator]="paginator"
    [autoLayout]="true"
    [lazy]="true"
    (onLazyLoad)="loadData($event)"
    [rows]="paginatedResponse?.pageSize"
    [totalRecords]="paginatedResponse?.totalRecords"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [loading]="loading"
    [rowTrackBy]="trackEntity"
    [globalFilterFields]="globalFilterFields"
    [class.table-compressed]="tableCompressed"
    responsiveLayout="scroll"
    editMode="row"
    dataKey="id"
  >
    <ng-template pTemplate="header" let-columns>
      <tr
        *ngIf="!!filterFields"
        class="d-flex justify-content-between filter-row table-custom-header"
      >
        <div class="position-relative">
          <input
            class="search-input"
            pInputText
            type="text"
            [(ngModel)]="model"
            (ngModelChange)="changeInput($event)"
            [placeholder]="(placeholderSearchInput | translate) ?? ''"
          />
          <img
            src="assets/images/icons/input-search.svg"
            alt=""
            class="search-icon"
          />
        </div>
        <div class="d-flex mt-3 mt-md-0 filter-front">
          <div
            *ngIf="!!filterFields.length"
            class="d-inline-block mr-2"
            ngbDropdown
            #filterDrop="ngbDropdown"
            placement="bottom-right"
            [autoClose]="false"
          >
            <button
              type="button"
              class="btn btn-default"
              id="dropdownFilter"
              ngbDropdownToggle
            >
              {{ TranslationEnum.Table.FILTER | translate }}
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownFilter"
              class="custom-filter-table"
            >
              <div class="filter-title">
                {{ TranslationEnum.Table.FILTER_OPTIONS | translate }}
              </div>
              <ng-container *ngFor="let filterField of filterFields">
                <ng-container [ngSwitch]="filterField.type">
                  <div class="filter-name">
                    {{ filterField?.fieldName | translate }}
                  </div>
                  <div
                    *ngSwitchCase="filterType.SELECT"
                    class="d-flex flex-column filter-body"
                  >
                    <p-columnFilter
                      [field]="filterField?.field"
                      matchMode="in"
                      [showMenu]="false"
                    >
                      <ng-template pTemplate="filter" let-value>
                        <p-dropdown
                          [ngModel]="value"
                          [options]="filterField.values"
                          (onChange)="
                            addFilterValue(filterField.field, $event?.value)
                          "
                        >
                          <ng-template let-option pTemplate="item">
                            <span>{{ option.label }}</span>
                          </ng-template>
                        </p-dropdown>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                  <div
                    *ngSwitchCase="filterType.SEARCH_SELECT"
                    class="d-flex flex-column filter-body"
                  >
                    <p-columnFilter
                      [field]="filterField.field"
                      matchMode="in"
                      [showMenu]="false"
                    >
                      <ng-template
                        pTemplate="filter"
                        let-value
                        let-filter="filterCallback"
                      >
                        <p-dropdown
                          [ngModel]="value"
                          [options]="filterField.values"
                          (onChange)="
                            addFilterValue(filterField.field, $event?.value)
                          "
                          optionLabel="label"
                          optionValue="value"
                        >
                          <ng-template let-option pTemplate="item">
                            <div class="p-multiselect-representative-option">
                              <span class="ml-1">{{ option.label }}</span>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                  <div
                    *ngSwitchCase="filterType.CALENDAR"
                    class="d-flex flex-column filter-body"
                  >
                    <p-columnFilter
                      [field]="filterField.field"
                      matchMode="in"
                      [showMenu]="false"
                    >
                      <ng-template pTemplate="filter">
                        <app-date-range-picker
                          [resetOption]="resetPickerOption"
                          (selectedDates)="
                            addFilterValueRangeDate(filterField.field, $event)
                          "
                        ></app-date-range-picker>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </ng-container>
              </ng-container>
              <div class="d-flex justify-content-end filter-buttons">
                <div class="btn btn-gray btn-filter" (click)="resetFilters()">
                  {{ TranslationEnum.Table.RESET | translate }}
                </div>
                <div class="btn btn-primary btn-filter" (click)="filterTable()">
                  {{ TranslationEnum.Table.APPLY | translate }}
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="hasAddEntity"
            class="btn btn-default"
            (click)="addNew.emit()"
          >
            {{
              TranslationEnum.Table.ADD_ENTITY
                | translate: { entityName: entity }
            }}
          </div>
        </div>
      </tr>
      <tr class="column-headers" [class.headerSpacing]="filterFields">
        <th *ngFor="let col of columns">
          <span class="header"> {{ col.header | translate }}</span>
        </th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-item
      let-columns="columns"
      let-editing="editing"
      let-ri="rowIndex"
    >
      <tr
        class="row-body"
        [class.clickable]="rowClickable"
        [pEditableRow]="item"
        #tr
        (click)="rowClickAction(item)"
        [class.refunded]="item.price < 0"
      >
        <td
          *ngFor="let col of columns"
          class="position-relative"
          [ngbPopover]="
            col.displayTooltipOnOverflow && item[col.field]?.length > 10
              ? item[col.field]
              : null
          "
          placement="bottom"
          triggers="hover"
          [class.column-overflow]="col.displayTooltipOnOverflow"
        >
          <ng-container [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'actions'">
              <div class="actions">
                <ng-container
                  *ngIf="!!col.decorations?.length; else listActions"
                >
                  <ng-container
                    *ngIf="
                      col.decorations?.includes(
                        columnDecorationType.ACTIONS_BUTTONS
                      )
                    "
                  >
                    <div
                      *ngIf="editing"
                      pButton
                      pRipple
                      type="button"
                      pSaveEditableRow
                      icon="pi pi-check"
                      (click)="onEdit(pTable, item, actions[0].id)"
                      class="btn btn-default"
                      [class.disabled]="saveEditDisabled"
                    >
                      {{ TranslationEnum.Common.SAVE | translate }}
                    </div>
                    <div
                      *ngIf="!editing"
                      (click)="
                        actionClick($event, editing, item, actions[0].id)
                      "
                      class="btn btn-check"
                      [class.active]="item.checkedIn"
                    >
                      {{
                        (item.checkedIn
                          ? actions[0].activeName
                          : actions[0].name
                        ) | translate
                      }}
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      col.decorations?.includes(
                        columnDecorationType.MORE_BUTTON
                      )
                    "
                  >
                    <div
                      *ngIf="
                        !col.actionsDisplayCondition ||
                        (col.actionsDisplayCondition &&
                          col.conditionToDisplay(item))
                      "
                      class="d-inline-block position-static"
                      ngbDropdown
                      #myDrop="ngbDropdown"
                      placement="bottom-right"
                    >
                      <button
                        type="button"
                        class="open-more"
                        id="dropdownManual"
                        ngbDropdownAnchor
                        (focus)="myDrop.open()"
                      >
                        <img
                          src="/assets/images/icons/ant-design_edit-filled.svg"
                          alt=""
                          class="dots"
                        />
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownManual">
                        <button
                          ngbDropdownItem
                          *ngFor="
                            let action of !!col.decorations.includes(
                              columnDecorationType.ACTIONS_BUTTONS
                            )
                              ? actions.slice(1)
                              : actions
                          "
                          (click)="editAction(action, col, item, pTable)"
                        >
                          <div
                            class="action-icon {{ action.name }}"
                            *ngIf="action.icon"
                          >
                            <img src="{{ action.icon }}" alt="" />
                          </div>
                          <span class="action-name">
                            {{
                              (action.id === changeStatusAction
                                ? item.status === "Active"
                                  ? TranslationEnum.Common.DEACTIVATE
                                  : TranslationEnum.Common.ACTIVATE
                                : action.name
                              ) | translate
                            }}</span
                          >
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-template #listActions>
                  <ng-container *ngFor="let action of actions">
                    <ng-container
                      [ngSwitch]="action.id"
                      *ngIf="!editable || !editing"
                    >
                      <img
                        alt="action_icon"
                        *ngSwitchDefault
                        style="width: 20px; height: 20px; margin-right: 10px"
                        [src]="action.icon"
                        (click)="checkConfirmation(action.id, item.id)"
                        [ngbTooltip]="action.name"
                        class="cursor-pointer"
                      />
                      <tg-toggle
                        [showConfirmationModal]="true"
                        [confirmationText]="
                          'Are you sure you want to change the status?'
                        "
                        [confirmationButton]="'Change'"
                        class="mr-2"
                        [switchActive]="item['status'] === 'Active'"
                        (switchActiveChange)="
                          actionCallback.emit({
                            actionId: action.id,
                            parameter: item.id
                          })
                        "
                        *ngSwitchCase="actionType.DEACTIVATE"
                      >
                      </tg-toggle>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="editableInline">
                    <div
                      pButton
                      pRipple
                      pInitEditableRow
                      *ngIf="!editing"
                      type="button"
                      (click)="onRowEditInit(item)"
                      class="btn-edit-row mr-2"
                    >
                      <img
                        src="assets/images/icons/ant-design_edit-filled.svg"
                        alt="Edit"
                      />
                    </div>

                    <div
                      pCancelEditableRow
                      class="cancel-action"
                      *ngIf="editing"
                      (click)="onRowEditCancel(item, ri)"
                    >
                      <img
                        class="cancel"
                        type="button"
                        src="assets/images/icons/delete-new.svg"
                        alt=""
                      />
                    </div>

                    <div
                      class="check-action"
                      pSaveEditableRow
                      *ngIf="editing"
                      (click)="onRowEditSave(item)"
                    >
                      <img
                        class="save mr-2"
                        type="button"
                        src="assets/images/icons/check.svg"
                        alt=""
                      />
                    </div>
                  </ng-container>
                </ng-template>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <p-cellEditor *ngIf="col.editable; else nonEditable">
                <ng-template pTemplate="input">
                  <ng-container [ngSwitch]="col.inputEditableType">
                    <p-dropdown
                      *ngSwitchCase="'select'"
                      [options]="col.selectOptions"
                      [(ngModel)]="item[col.field]"
                      class="customDropdownCards"
                    >
                      <ng-container *ngIf="!!col.disableOptionCondition">
                        <ng-template let-option pTemplate="item">
                          <div
                            (click)="
                              col.disableOptionCondition(option)
                                ? $event.stopPropagation()
                                : null
                            "
                            [style]="
                              col.disableOptionCondition(option)
                                ? 'color: #ccc; cursor: default;'
                                : ''
                            "
                          >
                            {{ option }}
                          </div>
                        </ng-template>
                      </ng-container>
                    </p-dropdown>
                    <ng-container *ngSwitchCase="'complex_calendar'">
                      <span
                        >{{ item[col.labelColumn]?.split('-')?.[0] + ' - '}}</span
                      >
                      <app-date-picker
                        [dateSelected]="transformDate(item[col.field])"
                        (dateSelectedChange)="changeDate($event, item)"
                      ></app-date-picker>
                    </ng-container>
                    <ng-container *ngSwitchCase="'complex_input'">
                      <input
                        [(ngModel)]="item[col.field]"
                        (input)="complexInputCondition($event, item[col.labelColumn]?.split('din')?.[1])"
                      />
                      din
                      <span>{{ item[col.labelColumn]?.split('din')?.[1]}}</span>
                      <span
                        *ngIf="displayComplexInputError"
                        class="error-complex-input"
                      >
                        {{ complexInputErrorText }}
                      </span>
                    </ng-container>
                    <input
                      *ngSwitchDefault
                      pInputText
                      type="text"
                      [(ngModel)]="item[col.field]"
                    />
                  </ng-container>
                </ng-template>
                <ng-template pTemplate="output">
                  <span *ngIf="!col.link && col.field !== 'status'">
                    {{
                      !!col.parseValue
                        ? col.parseValue(item[col.field])
                        : col.labelColumn
                        ? item[col.labelColumn]
                        : item[col.field] || "-"
                    }}
                  </span>
                  <span
                    *ngIf="col.field === 'status' && advancedStatus"
                    [class]="item[col.field].toLowerCase()"
                    [class.badge]="col.field === 'status'"
                  >
                    {{
                      (!!col.parseValue
                        ? col.parseValue(item[col.field])
                        : item[col.field]
                      ) | translate
                    }}
                  </span>
                </ng-template>
              </p-cellEditor>
              <ng-template #nonEditable>
                <ng-container
                  *ngIf="col.decorations?.length; else simpleColumn"
                >
                  <div
                    *ngIf="
                      col.decorations.includes(
                        columnDecorationType.CUSTOM_ICON
                      );
                      else defaultDecoration
                    "
                    class="position-relative"
                  >
                    <span>{{ item[col.field] }}</span>
                    <img
                      *ngIf="col.conditionToDisplay(item) && col.iconPath"
                      [src]="col.iconPath"
                      class="ml-2 decoration-icon"
                    />
                  </div>
                  <ng-template #defaultDecoration>
                    <div class="d-flex flex-column">
                      <span>{{ item[col.field] }}</span>
                      <div class="d-flex flex-row">
                        <div class="custom-progress">
                          <div
                            class="bar-green"
                            [ngStyle]="{ width: item.progress + '%' }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-template #simpleColumn>
                  <ng-container *ngIf="col.dateFormat; else notDate">
                    <span>{{
                      item[col.field]
                        ? datePipe.transform(item[col.field], col.dateFormat)
                        : "-"
                    }}</span>
                  </ng-container>
                  <ng-template #notDate>
                    <span
                      *ngIf="
                        !col.link &&
                        col.field !== 'status' &&
                        col.field !== 'timeDifference'
                      "
                      [ngClass]="{
                        automatic:
                          col.field === 'manualCheckOut' &&
                          item[col.field] === false,
                        'badge checkinType': col.field === 'manualCheckOut'
                      }"
                    >
                      {{
                        !!col.parseValue
                          ? col.parseValue(item[col.field])
                          : item[col.field] || "-"
                      }}</span
                    >
                    <ng-container *ngIf="col.link">
                      <a
                        class="name-field"
                        *ngIf="!rowClickable"
                        (click)="redirectToRoute(item.id)"
                        >{{ item[col.field] }}</a
                      >
                      <span *ngIf="rowClickable">
                        {{ item[col.field] }}
                      </span>
                    </ng-container>

                    <span
                      class="badge"
                      *ngIf="col.field === 'status'"
                      [ngClass]="item[col.field] | lowercase"
                    >
                      {{
                        (!!col.parseValue
                          ? col.parseValue(item[col.field])
                          : item[col.field]
                        ) | translate
                      }}
                    </span>

                    <span
                      class="badge time"
                      *ngIf="col.field === 'timeDifference'"
                    >
                      {{ item[col.field] }}
                    </span>
                  </ng-template>
                </ng-template>
              </ng-template>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <div *ngIf="displayTotalRecords">
        {{ TranslationEnum.Table.TOTAL_RECORDS | translate }}:
        {{ paginatedResponse?.totalRecords }}
      </div>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr class="noDataRow">
        <td>
          <div class="noDataTemplate">
            <img
              [src]="'/assets/images/illustrations/' + noDataPicture + '.svg'"
              alt="Gym Girl"
              class="NoData"
            />
            <h6 class="noData-title">{{ noDataTitle }}</h6>
            <p class="noData-description">{{ noDataDescription }}</p>
            <div
              class="btn btn-default"
              (click)="addNew.emit()"
              *ngIf="showAction && !redirectToPos"
            >
              <span>
                <img
                  src="/assets/images/icons/add_square.svg"
                  alt=""
                  class="add-sign"
                />
              </span>
              {{
                TranslationEnum.Table.ADD_ENTITY
                  | translate: { entityName: entity }
              }}
            </div>

            <div *ngIf="member; else noMemberSelected">
              <span
                class="btn btn-default"
                *ngIf="redirectToPos"
                (click)="redirectPos(member)"
              >
                POS
              </span>
            </div>
            <ng-template #noMemberSelected>
              <a
                class="btn btn-default"
                *ngIf="redirectToPos"
                routerLink="/gym/pos"
              >
                POS
              </a>
            </ng-template>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #deleteModal let-modal>
  <app-confirmation-modal
    titleText="{{
      TranslationEnum.Table.DELETE_MODAL_TITLE
        | translate: { entityName: entity }
    }}"
    confirmText="{{
      TranslationEnum.Table.DELETE_MODAL_CONFIRM
        | translate: { entityName: entity }
    }}"
    cancelText="{{ TranslationEnum.Common.CANCEL | translate }}"
    [modal]="modal"
  ></app-confirmation-modal>
</ng-template>
