<div class="toggle-section">
  <div class="d-flex flex-wrap mb-3 align-items-center">
    <div>
      <p *ngFor="let setting of generalSettings" class="mr-2 mb-0 setting-name">
        {{ setting.name }}
      </p>
    </div>

    <div class="ml-4 toggle-wrapper">
      <div *ngFor="let setting of generalSettings" class="position-relative">
        <tg-toggle
          [switchActive]="setting.active"
          (switchActiveChange)="settingToggleChange($event, setting)"
          [disabled]="disableSetting(setting)"
        ></tg-toggle>
        <div
          [ngbPopover]="setting.tooltipText | translate"
          placement="top"
          triggers="hover"
          class="setting-tooltip"
        >
          i
        </div>
      </div>
    </div>
  </div>
</div>
