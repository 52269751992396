import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AccountService } from '../../services/account.service';
import * as UserActions from '../actions/user.actions';
import * as Sentry from '@sentry/angular';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Profile } from '../../models/user/profile';
import { Router } from '@angular/router';
import { Permission } from '../../models/user/permission';
import { markImpersonated } from '../actions/user.actions';
import { Store } from '@ngrx/store';
import { logoutAction, setAuthTokenAction } from '../actions/misc.actions';
import { Auth } from 'aws-amplify';
import { from } from 'rxjs';

@Injectable()
export class UserEffects {
  private static QUERY_PARAMS_STORAGE_LABEL = 'queryParams';

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private router: Router,
    private store: Store
  ) {}

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      map(() => from(Auth.signOut())),
      tap(() => Sentry.setUser(null)),
      switchMap(() => {
        return [
          UserActions.storeProfileAction({ profile: null }),
          setAuthTokenAction({ accessToken: null }),
        ];
      })
    )
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getProfileAction),
      switchMap(() => this.accountService.getProfile()),
      map((profile: Profile) => {
        if (profile?.impersonated) {
          this.store.dispatch(markImpersonated({ impersonated: true }));
        }
        Sentry.setUser(
          profile
            ? {
                id: profile.id,
                username: profile.username,
              }
            : null
        );
        return UserActions.storeProfileAction({ profile });
      })
    )
  );

  getMainGym$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.storeProfileAction),
      filter((value) => !!value?.profile?.gymId),
      switchMap((value) => {
        return this.accountService.getProfileGym(value?.profile?.gymId);
      }),
      map((gym) => UserActions.storeMainGymAction({ gym }))
    )
  );

  private static storeQueryParams() {
    let queryParams = window.location.search;
    if (queryParams) {
      queryParams = queryParams.slice(1);
      const paramsObject = {};
      const queryParamsValues = queryParams.split('&');
      queryParamsValues.forEach((value) => {
        const values = value.split('=');
        paramsObject[values[0]] = values[1];
      });
      localStorage.setItem(
        UserEffects.QUERY_PARAMS_STORAGE_LABEL,
        JSON.stringify(paramsObject)
      );
    }
  }

  private static getQueryParams() {
    const queryParams = localStorage.getItem(
      UserEffects.QUERY_PARAMS_STORAGE_LABEL
    );
    localStorage.removeItem(UserEffects.QUERY_PARAMS_STORAGE_LABEL);
    return queryParams ? JSON.parse(queryParams) : null;
  }

  getProfileRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getProfileRedirectAction),
      switchMap(() => {
        return this.accountService.getProfile();
      }),
      map((profile) => {
        if (profile.permissions?.includes(Permission.MANAGE_APP)) {
          if (profile?.impersonated) {
            this.router.navigate(['/gym/dashboard']);
          } else {
            this.router.navigate(['/admin-gyms']);
          }
        } else if (
          profile.permissions?.includes(Permission.MANAGE_ORDERS_PROVIDER)
        ) {
          this.router.navigate(['/order-card']);
        } else {
          this.router.navigate(['/gym/dashboard']);
        }

        if (profile?.impersonated) {
          this.store.dispatch(markImpersonated({ impersonated: true }));
        }

        return UserActions.storeProfileAction({ profile });
      })
    )
  );
}
