export enum TableActions {
  IMPERSONATE = 'Impersonate',
  DELETE = 'Delete',
  EDIT = 'Edit',
  EDIT_INLINE = 'Edit inline',
  DEACTIVATE = 'Inactivate',
  CHECKIN = 'Check In',
  APPROVE = 'Approve',
  DOWNLOAD = 'Download',
}
