import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ActionCallback } from 'src/app/models/table-data/action-callback';
import { TableActions } from 'src/app/models/table-data/table-actions';
import { FormErrors } from 'src/app/shared/utilities/constants';
import { TranslationEnum } from 'src/assets/i18n/translation-enum';
import {
  getDefaultLocationId,
  getPermissionsSelector,
} from 'src/app/store/selectors/user.selector';
import { MemberMembership } from 'src/app/models/member-membership';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Permission } from '../../../models/user/permission';

@Component({
  selector: 'app-membership-edit-small',
  templateUrl: './membership-edit.component.html',
  styleUrls: ['./membership-edit.component.scss'],
})
export class MembershipEditComponent implements OnInit, OnDestroy {
  @Input()
  membership: MemberMembership;

  @Output() closeModal = new EventEmitter<boolean>();
  @Output()
  actionCallback: EventEmitter<ActionCallback> = new EventEmitter<ActionCallback>();

  membershipForm = new FormGroup({
    sessions: new FormControl({ value: 0 }),
    ptSessions: new FormControl({ value: 0, disabled: true }),
  });

  loading = false;
  formErrors = FormErrors;
  locationId$: Observable<string>;
  success = false;
  hasEditPermission = true;

  permisions$: Observable<any>;

  TranslationEnum = TranslationEnum;
  confirmModal = null;

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.locationId$ = this.store.select(getDefaultLocationId);
    this.permisions$ = this.store.select(getPermissionsSelector);
    this.permisions$.pipe(takeUntil(this.destroy)).subscribe((response) => {
      if (!response.includes(Permission.MANAGE_SOLD_MEMBERSHIP)) {
        this.hasEditPermission = false;
      }
    });
    this.setFormValues();
  }

  hasErrors(control: AbstractControl) {
    return control.invalid && (control.dirty || control.touched);
  }

  setFormValues() {
    if (this.membership.personalTrainerTotalSessions > -1) {
      this.membershipForm.get('ptSessions').enable();
    }
    this.membershipForm.patchValue({
      sessions: this.membership.usedSessions,
      ptSessions: this.membership.personalTrainerUsedSessions,
    });
  }

  saveMembership() {
    if (this.membershipForm?.invalid || this.loading) {
      return;
    }

    this.loading = true;

    this.membership.usedSessions =
      this.membership.totalSessions > -1
        ? this.membershipForm.get('sessions')?.value
        : -1;

    this.membership.personalTrainerUsedSessions = this.membershipForm.get(
      'ptSessions'
    ).enabled
      ? this.membershipForm.get('ptSessions')?.value
      : -1;

    this.membershipForm.disable();

    this.actionCallback.emit({
      actionId: TableActions.EDIT,
      parameter: this.membership.id,
    });

    this.closeModal.emit(true);
    this.resetForm();
  }

  closeModule(value) {
    this.closeModal.emit(value);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  resetForm() {
    this.loading = false;
    this.membershipForm.reset();
    this.membershipForm.enable();
    this.membershipForm.get('sessions').setValue(null);
  }

  deleteClick() {
    this.closeModule(false);
    this.actionCallback.emit({
      actionId: TableActions.DELETE,
      parameter: this.membership.id,
    });
  }

  transformDate(dateString: string) {
    const date = new Date(dateString);
    return NgbDate.from({
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    } as NgbDateStruct);
  }

  changeDate(date: NgbDate, item: MemberMembership) {
    const dateEnded = new Date(date.year, date.month - 1, date.day);
    dateEnded.setHours(23, 59);
    item.dateEnded = dateEnded.toISOString();
  }
}
