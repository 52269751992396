import { createAction, props } from '@ngrx/store';
import { ToasterNotification } from '../../models/toaster-notification';

export const appInit = createAction('[App] Initialization');

export const saveToasterNotification = createAction(
  '[App] Save Toaster Notification',
  props<{ toasterNotification: ToasterNotification }>()
);

export const setAuthTokenAction = createAction(
  '[Shared] Set auth token',
  props<{ accessToken: string }>()
);

export const logoutAction = createAction('[Shared] Logout user');
