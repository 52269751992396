<div class="d-flex h-100">
  <div class="sidebar d-flex flex-column align-items-center">
    <div
      *ngFor="let item of sidebarInfo"
      class="
        d-flex
        flex-column
        justify-content-center
        align-items-center
        gap-small
        sidebarItem
      "
      [class.selected]="item.selected"
      (click)="selectSidebarItem(item)"
    >
      <img *ngIf="item.selected" [src]="item.selectedUrl" alt="icon" />
      <img *ngIf="!item.selected" [src]="item.url" alt="icon" />
      {{ item.label | translate }}
    </div>
  </div>
  <div
    class="checkInWrapper w-100"
    [class.success]="status === 'success' && checkin"
    [class.empty]="status === 'empty'"
    [class.checkout]="status === 'success' && !checkin"
    [class.warning]="status === 'warning'"
  >
    <app-check-in-header
      *ngIf="!editMembership"
      [checkInResponse]="checkInResponse"
      [hasActiveMembership]="!!memberships?.length"
      [locationId]="locationId"
      (close)="close.emit()"
    ></app-check-in-header>

    <ng-container *ngIf="sidebarInfo[0].selected || sidebarInfo[1].selected">
      <div
        class="d-flex flex-column mb-3 active-memberships"
        [class.noMargin]="!!editMembership"
      >
        <h6
          *ngIf="!editMembership && memberships?.length"
          class="font-weight-bold"
        >
          {{ TranslationEnum.Member.ACTIVE_MEMBERSHIPS | translate }}
        </h6>
        <ng-container *ngIf="sidebarInfo[0].selected && memberships?.length">
          <ng-container *ngFor="let membership of memberships">
            <div
              class="d-flex membership-box mt-3"
              [class.checkedIn]="membership.checkedIn"
            >
              <div class="d-flex flex-column w-100">
                <h4>{{ membership.name }}</h4>
                <div
                  class="
                    d-flex
                    mt-2
                    justify-content-between
                    w-100
                    space-big
                    font-small
                  "
                >
                  <div class="d-flex justify-content-between w-100 space">
                    <div class="d-flex space w-100">
                      <div class="d-flex flex-column">
                        <span>{{
                          TranslationEnum.Report.SESSIONS | translate
                        }}</span>
                        <span>{{
                          TranslationEnum.TableColumns.TYPE_ID | translate
                        }}</span>
                      </div>
                      <div class="d-flex w-100 flex-column space">
                        <span class="font-weight-bold">{{
                          membership.totalSessions === -1
                            ? (TranslationEnum.Common.UNLIMITED | translate)
                            : membership.usedSessions +
                              "/" +
                              membership.totalSessions +
                              " " +
                              (TranslationEnum.Common.USED | translate)
                        }}</span>
                        <span class="font-weight-bold">{{
                          membership.type === membershipType.FITNESS ||
                          membership.type === membershipType.FITNESS_PT
                            ? (TranslationEnum.Common.FITNESS | translate)
                            : membership.type === membershipType.CLASSES ||
                              membership.type === membershipType.CLASSES_PT
                            ? (TranslationEnum.Classes.CLASSES | translate)
                            : (TranslationEnum.Common.POOL | translate)
                        }}</span>
                      </div>
                    </div>
                    <div class="d-flex flex-column w-100 space align-items-end">
                      <div class="d-flex justify-content-end space w-100">
                        <span>{{
                          TranslationEnum.Common.VALID_UNTIL | translate
                        }}</span>
                        <span class="font-weight-bold">{{
                          datePipe.transform(
                            membership.dateEnded,
                            pipeDateFormat
                          )
                        }}</span>
                      </div>
                      <div>
                        {{ TranslationEnum.Membership.MEMBERSHIP | translate }}
                        {{
                          " " +
                            membership.periodUnit +
                            " " +
                            ((membership.period === period.DAY
                              ? TranslationEnum.Common.DAY
                              : membership.period === period.HOUR
                              ? TranslationEnum.Common.HOUR
                              : membership.period === period.MONTH
                              ? TranslationEnum.Common.MONTH
                              : TranslationEnum.Common.YEAR
                            ) | translate)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="line"></div>
              <div class="action-button">
                <div
                  [class.checkedIn]="membership.checkedIn && !ptUsed"
                  class="
                    content
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  [class.disabled]="
                    membership.totalSessions > -1 && membership.consumedSessions
                  "
                  (click)="checkIn(membership)"
                >
                  {{
                    membership.type === membershipType.CLASSES ||
                    membership.type === membershipType.CLASSES_PT
                      ? (TranslationEnum.Classes.BOOK_CLASS | translate)
                      : membership?.checkedIn && !ptUsed
                      ? (TranslationEnum.Common.CHECK_OUT | translate)
                      : (TranslationEnum.Common.CHECK_IN | translate)
                  }}
                </div>
              </div>
            </div>
            <div
              *ngIf="
                membership.type === membershipType.FITNESS_PT ||
                membership.type === membershipType.CLASSES_PT ||
                membership.type === membershipType.POOL_PT
              "
              class="d-flex"
            >
              <div
                class="color-block"
                [class.checkedIn]="membership.checkedIn"
              ></div>
              <div class="pt-white-box w-100 font-small">
                <div class="personal-trainer-box d-flex w-100 space-30">
                  <div class="d-flex justify-content-between w-100">
                    <div
                      class="
                        d-flex
                        flex-column
                        justify-content-between
                        space-big
                      "
                    >
                      <span class="font-weight-bold pt-text">{{
                        TranslationEnum.Membership.PERSONAL_TRAINER | translate
                      }}</span>
                      <div>
                        <ng-select
                          [items]="trainers$ | async"
                          bindLabel="trainerName"
                          (ngModelChange)="selectTrainer($event, membership)"
                          [ngModel]="membership.lastTrainer"
                          [searchable]="false"
                          [clearable]="false"
                          [placeholder]="
                            membership.lastTrainer
                              ? ''
                              : (TranslationEnum.Classes.TRAINER | translate)
                          "
                          class="choose-trainer"
                          [class.select-error]="
                            !membership.lastTrainer && membership?.selected
                          "
                        ></ng-select>
                      </div>
                    </div>
                    <div
                      class="
                        font-small
                        d-flex
                        flex-column
                        justify-content-between
                        align-items-end
                        space-big
                      "
                    >
                      <span class="font-weight-bold">{{
                        TranslationEnum.Common.SESSIONS | translate
                      }}</span>
                      <div class="vertical-align">
                        {{
                          membership.personalTrainerTotalSessions <= -1
                            ? (TranslationEnum.Common.UNLIMITED | translate)
                            : membership.personalTrainerUsedSessions +
                              "/" +
                              membership.personalTrainerTotalSessions +
                              " " +
                              (TranslationEnum.Common.USED | translate)
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="line line-black"></div>
                  <div class="action-button">
                    <div
                      class="
                        content
                        border-black
                        d-flex
                        align-items-center
                        justify-content-center
                        height-90
                      "
                      [class.disabled]="
                        membership.personalTrainerTotalSessions > -1 &&
                        membership.consumedPtSessions
                      "
                      [class.checkedIn]="membership.checkedIn && ptUsed"
                      (click)="checkIn(membership, true)"
                    >
                      {{
                        membership.type === membershipType.CLASSES_PT
                          ? (TranslationEnum.Classes.BOOK_CLASS | translate)
                          : membership.checkedIn && ptUsed
                          ? (TranslationEnum.Common.CHECK_OUT | translate)
                          : (TranslationEnum.Common.CHECK_IN | translate)
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <app-check-in-classes
            *ngIf="checkInResponse?.memberBookedClasses?.length"
            [futureClasses]="checkInResponse.memberBookedClasses"
            [info]="{locationId, memberId: checkInResponse?.memberId, loadClasses: false}"
            (refreshData)="refreshCheckInInfo.emit(checkInResponse.memberId)"
          ></app-check-in-classes>
        </ng-container>
        <ng-container
          *ngIf="
            (sidebarInfo[0].selected || sidebarInfo[1].selected) &&
            !memberships?.length
          "
        >
          <div
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              empty-banner
            "
            (click)="goToPos()"
          >
            <img
              src="/assets/images/no-membership2.svg"
              alt="empty"
              class="empty-img"
            />
            <div class="font-weight-bold text-uppercase">
              no active membership
            </div>
            <div class="pos-link">Click to sell one</div>
          </div>
        </ng-container>
        <ng-container *ngIf="membershipsSelected()">
          <app-check-in-memberships
            *ngIf="!editMembership"
            [edit]="true"
            [memberships]="memberships"
            (goToEdit)="editMembership = $event"
          ></app-check-in-memberships>
          <app-membership-edit-small
            class="h-100"
            *ngIf="!!editMembership"
            [membership]="editMembership"
            (closeModal)="editMembership = null"
            (actionCallback)="
              actionCallback($event?.actionId, $event?.parameter)
            "
          ></app-membership-edit-small>
        </ng-container>
      </div>

      <div
        class="d-flex flex-column mt-5"
        *ngIf="checkInResponse?.membershipsHistory?.length && !editMembership"
      >
        <h6 class="mb-2 font-grey">
          {{ TranslationEnum.Member.HISTORY_MEMBERSHIPS | translate }}
        </h6>
        <app-check-in-memberships
          [memberships]="checkInResponse.membershipsHistory"
        ></app-check-in-memberships>
      </div>
    </ng-container>

    <ng-container *ngIf="classesSelected()">
      <app-check-in-classes
        [info]="{locationId, memberId: checkInResponse?.memberId, loadClasses: true}"
        [memberName]="checkInResponse.username"
      ></app-check-in-classes>
    </ng-container>

    <ng-container *ngIf="historySelected()">
      <app-check-in-history
        [memberId]="checkInResponse.memberId"
        [locationId]="locationId"
      ></app-check-in-history>
    </ng-container>

    <ng-container *ngIf="docsSelected()">
      <app-check-in-docs
        [memberId]="checkInResponse?.memberId"
        [memberName]="checkInResponse.username"
      ></app-check-in-docs>
    </ng-container>

    <ng-container *ngIf="settingsSelected()">
      <app-check-in-settings
        [info]="{locationId, memberId: checkInResponse?.memberId}"
      ></app-check-in-settings>
    </ng-container>
  </div>
</div>
