enum Login {
  LOGIN = 'login.login',
  FORGET_PASSWORD = 'login.forget_password',
  SET_NEW_PASSWORD = 'login.set_new_password',
  PASSWORD_CHANGED_SUCCESSFULLY = 'login.password_changed_successfully',
  CHOOSE_NEW_PASSWORD = 'login.choose_new_password',
  LINK_NO_LONGER_VALID = 'login.link_no_longer_valid',
  RESET_PASSWORD = 'login.reset_password',
  SHOW_PASSWORD = 'login.show_password',
  SUBMIT = 'login.submit',
  BACK_TO_LOGIN = 'login.back_to_login',
  ENTER_EMAIL = 'login.enter_email',
  RESET_PASSWORD_LINK_SENT = 'login.reset_password_link_sent',
  EMAIL_SENT_TO_ADDRESS = 'login.email_sent_to_address',
  CREATE_NEW_PASSWORD = 'login.create_new_password',
  RESEND_EMAIL = 'login.resend_email',
}

enum Member {
  SEE_PROFILE = 'member.see_profile',
  ADD_NEW_MEMBER = 'member.add_member',
  REGISTERED_SUCCESS = 'member.registered_successfully',
  REGISTER_SUCCESS = 'member.register_successfully',
  UPLOAD_DATE = 'member.upload_date',
  SEARCH_FOR_MEMBER = 'member.search_for_member',
  ADD_MEMBER = 'member.add_member',
  MEMBER_PROFILE = 'member.member_profile',
  MEMBER_PROFILE_DESCRIPTION = 'member.member_profile_description',
  MEMBER_FROM = 'member.member_from',
  ACTION_CHANGE_CARD = 'member.change_card',
  ACTION_RELEASE_CARD = 'member.release_card',
  ACTION_RESEND_REGISTER_EMAIL = 'member.resend_register_email',
  ACTION_DELETE_MEMBER = 'member.delete_member',
  TODAY_ENTRIES = 'member.today_entries',
  TOTAL_ENTRIES = 'member.total_entries',
  CREDIT = 'member.credit',
  TOTAL_SPENT = 'member.total_spent',
  OPEN_ORDERS = 'member.open_orders',
  TAKE_PHOTO = 'member.take_photo',
  MEMBERSHIP = 'member.membership',
  MEMBERSHIPS = 'member.memberships',
  ENTRIES_LOG = 'member.entries_log',
  DOWNLOAD_REPORT = 'member.download_report',
  SALES_LOG = 'member.sales_log',
  PRODUCT = 'member.product',
  THIS_MONTH = 'member.this_month',
  DOCUMENTS = 'member.documents',
  SETTINGS = 'member.settings',
  PROFILE_DETAILS = 'member.profile_details',
  NAME = 'member.name',
  ADDRESS = 'member.address',
  CONTACT_PHONE = 'member.contact_phone',
  EMAIL = 'member.email',
  BIRTHDATE = 'member.birthdate',
  GENDER = 'member.gender',
  ID_NUMBER = 'member.id_number',
  EMERGENCY_CONTACT_NAME = 'member.emergency_contact_name',
  EMERGENCY_PHONE_NUMBER = 'member.emergency_phone_number',
  DETAILS = 'member.details',
  LOCKER_KEY = 'member.locker_key',
  MEMBER_NAME = 'member.member_name',
  PHONE = 'member.phone',
  STATUS = 'member.status',
  SUBSCRIBER_ID = 'member.subscriber_id',
  INTERNAL_ID = 'member.internal_id',
  CONFIRMATION_CHANGE_CARD = 'member.confirmation_change_card',
  CONFIRMATION_RELEASE_CARD = 'member.confirmation_release_card',
  SWIPE_NEW_CARD = 'member.swipe_new_card',
  GO_TO_DASHBOARD = 'member.go_to_dashboard',
  MEMBER_DELETED = 'member.member_deleted',
  DOWNLOAD_CONTRACT = 'member.download_contract',
  DOCUMENT_UPLOAD_SUCCESS = 'member.document_upload_success',
  DOCUMENT_UPLOAD_ERROR = 'member.document_upload_error',
  DOCUMENT_DELETE_SUCCESS = 'member.document_delete_success',
  DOCUMENT_DELETE_ERROR = 'member.document_delete_error',
  DOCUMENTS_LIMIT_REACHED = 'member.documents_limit_reached',
  TOOLTIP_GDPR = 'member.tooltip_gdpr',
  TOOLTIP_BIRTHDAY = 'member.tooltip_birthday',
  TOOLTIP_MARKETING = 'member.tooltip_marketing',
  TOOLTIP_TRANSACTIONS = 'member.tooltip_transactions',
  ACTIVE_MEMBERSHIPS = 'member.active_memberships',
  HISTORY_MEMBERSHIPS = 'member.history_memberships',
  WRITE_COMMENT = 'member.write_comment',
  GROUP = 'member.group',
}

enum Membership {
  MEMBERSHIP = 'membership.membership',
  MEMBERSHIP_DETAILS = 'membership.membership_details',
  CATEGORY = 'membership.category',
  TYPE = 'membership.type',
  LIMIT_BY = 'membership.limit_by',
  SESSIONS = 'membership.sessions',
  HOUR = 'membership.hour',
  PERIOD = 'membership.period',
  VAT = 'membership.vat',
  MEMBERSHIP_NOTE = 'membership.membership_note',
  NOTE = 'membership.note',
  UNLIMITED = 'membership.unlimited',
  MIN_HOUR = 'membership.min_hour',
  MAX_HOUR = 'membership.max_hour',
  MEMBERSHIP_LIMIT = 'membership.membership_limit',
  MEMBERSHIP_VALIDITY = 'membership.membership_validity',
  ADD_MEMBERSHIP = 'membership.add_membership',
  SEARCH_FOR_MEMBERSHIP = 'membership.search_for_membership',
  USED_SESSIONS = 'membership.used_sessions',
  VALID_BETWEEN = 'membership.valid_between',
  AVAILABILITY = 'membership.availability',
  PERSONAL_TRAINER = 'membership.personalTrainer',
  PT_SESSIONS = 'membership.ptSessions',
}

enum Pos {
  SELL_MEMBERSHIP = 'pos.sell_membership',
  POS = 'pos.pos',
  POS_DESCRIPTION_PRODUCTS = 'pos.description_products',
  POS_DESCRIPTION_MEMBERSHIPS = 'pos.description_memberships',
  SEARCH_PRODUCTS = 'pos.search_products',
  SEARCH_MEMBERSHIPS = 'pos.search_memberships',
  SEARCH_MEMBERS = 'pos.search_members',
  MEMBERSHIPS = 'pos.memberships',
  PRODUCTS = 'pos.products',
  CURRENT_ORDER = 'pos.current_order',
  DISCOUNT = 'pos.discount',
  SUBTOTAL = 'pos.subtotal',
  TOTAL = 'pos.total',
  PAYMENT_DONE = 'pos.payment_done',
  CHECK_IN = 'pos.check_in',
  VIEW_FULL_PROFILE = 'pos.view_full_profile',
  ANOTHER_PAYMENT = 'pos.another_payment',
  STOCK = 'pos.stock',
  ALPHABETICAL = 'pos.alphabetical',
  MOST_SOLD = 'pos.most_sold',
  SORT_BY = 'pos.sort_by',
  NEW_ORDER = 'pos.new_order',
  HOLD_DONE = 'pos.hold_done',
  HOLD_DONE_DETAILS = 'pos.hold_done_details',
  AMOUNT = 'pos.amount',
  PERCENTAGE = 'pos.percentage',
  DISCOUNT_TOOLTIP = 'pos.discount_tooltip',
  INVALID_AMOUNT_ERROR = 'pos.invalid_amount_error',
}

enum Classes {
  SEARCH_FOR_CLASS = 'classes.search_for_class',
  CLASS_NAME = 'classes.class_name',
  TRAINER = 'classes.trainer',
  TYPE = 'classes.type',
  ROOM = 'classes.room',
  CLASS_DURATION = 'classes.class_duration',
  MIN_MEMBERS = 'classes.min_members',
  MAX_MEMBERS = 'classes.max_members',
  LOCATION = 'classes.location',
  ADD_EDIT = 'classes.add_edit',
  CLASSES = 'classes.classes',
  ROOMS = 'classes.rooms',
  ZONES = 'classes.zones',
  CALENDAR = 'classes.calendar',
  ADD_CLASS = 'classes.add_class',
  SESSIONS = 'classes.sessions',
  DURATION = 'classes.duration',
  SESSIONS_USED_AT_CHECKING = 'classes.sessions_used_at_checking',
  MIN_PARTICIPANTS = 'classes.min_participants',
  MAX_PARTICIPANTS = 'classes.max_participants',
  MAX_WAITLIST = 'classes.max_waitlist',
  ALLOWED_MEMBERSHIPS_CAT = 'classes.allowed_memberships_cat',
  ALLOW_BOOKING_BEFORE = 'classes.allow_booking_before',
  DENY_BOOKING_BEFORE = 'classes.deny_booking_before',
  ALLOW_CANCELATION_BEFORE = 'classes.allow_cancelation_before',
  DATE_OF_OCCURENCE = 'classes.date_of_occurence',
  HOUR = 'classes.hour',

  TOGGLE_SEND_CLASS_NOTIFICATIONS = 'classes.toggle_send_class_notification',
  TOGGLE_ALLOW_CANCEL_RESERVATIONS = 'classes.toggle_allow_cancel_reservations',
  TOGGLE_AUTOMATIC_CANCELATION = 'classes.toggle_automatic_cancelation',
  TOGGLE_AUTOMATIC_CHECKIN = 'classes.toggle_automatic_checkin',
  ROOM_NAME = 'classes.room_name',
  SEARCH_FOR_ROOM = 'classes.search_for_room',
  ADD_EDIT_ROOM = 'classes.add_edit_room',
  ZONE = 'classes.zone',
  BOOK_CLASS = 'classes.book_class',
}

enum Invoices {
  INVOICE_NUMBER = 'invoices.number',
  INVOICE_AMOUNT = 'invoices.amount',
  INVOICE_BALANCE = 'invoices.balance',
  INVOICE_DUE_DATE = 'invoices.dueDate',
  INVOICE_DATE = 'invoices.issueDate',
  INVOICE_DOWNLOAD = 'invoices.download',
  SEARCH_FOR_INVOICES = 'invoices.search_for_invoice',
  INVOICE_STATUS_PAID = 'invoices.status_paid',
  INVOICE_STATUS_UNPAID = 'invoices.status_unpaid',
}

enum Product {
  ADD_PRODUCT = 'product.add_product',
  EDIT_PRODUCT = 'product.edit_product',
  PRODUCT_DETAILS = 'product.product_details',
  BARCODE = 'product.barcode',
  STOCK = 'product.stock',
  SEARCH_PRODUCT = 'product.search_product',
  BY_EDITING_THE = 'product.by_editing_the',
  ALL_USAGE = 'product.all_usage',
  CONFIRM_EDIT = 'product.confirm_edit',
}

enum Card {
  ORDER_CARDS = 'card.order_cards',
  DESCRIPTION_ADMIN = 'card.description_admin',
  DESCRIPTION_SUPER = 'card.description_super',
  DESCRIPTION_VENDOR = 'card.description_vendor',
  LABEL_NUMBER_CARDS = 'card.label_number_cards',
  LABEL_COLOR_CARDS = 'card.label_color_cards',
  LABEL_TRANSPORT_CARDS = 'card.label_transport_cards',
  NOTES_CARDS = 'card.notes_cards',
  CONFIRMATION_AGREEMENT = 'card.confirmation_agreement',
  ORDER = 'card.order',
  CARD_ORDERS = 'card.card_orders',
  STAFF_NAME = 'card.staff_name',
  ORDER_DATE = 'card.order_date',
  NUMBER_OF_CARDS = 'card.number_of_cards',
  AMOUNT = 'card.amount',
  AWB = 'card.awb',
  INVOICE_NUMBER = 'card.invoice_number',
  ISSUE_INVOICE = 'card.issue_invoice',
  LAST_UPDATED = 'card.last_updated',
  ORDER_SUCCESS_MESSAGE = 'card.order_success_message',
  ORDER_ERROR_MESSAGE = 'card.order_error_message',
  PERIOD_FILTER_LABEL = 'card.period_filter_label',
  GYM_FILTER_LABEL = 'card.gym_filter_label',
  STATUS_FILTER_LABEL = 'card.status_filter_label',
  EXPORT_XLS = 'card.export_xls',
  VENDOR_CARD_ORDERS = 'card.vendor_card_orders',
  TOTAL_NO_CARDS = 'card.total_no_cards',
  CYCLE_TIME = 'card.cycle_time',
  TOTAL_INVOICED = 'card.total_invoiced',
  GYM = 'card.gym',
  DELIVERY_ADDRESS = 'card.delivery_address',
  PHONE = 'card.phone',
  CARD_COLOR = 'card.card_color',
  COURIER = 'card.courier',
  GOD_CARD_ORDERS = 'card.god_card_orders',
  APPROVE_ORDER = 'card.approve_order',
  VIEW_ORDER = 'card.view_order',
  PRINT_BARCODES = 'card.print_barcodes',
  UPLOAD_BARCODES = 'card.upload_barcodes',
  DOWNLOAD_BARCODES = 'card.download_barcodes',
  WHITE = 'card.white',
  BLACK = 'card.black',
  SILVER = 'card.silver',
  GOLD = 'card.gold',
  UBER = 'card.uber',
  PENDING_APPROVAL = 'card.pending_approval',
  APPROVED = 'card.approved',
  PRINT = 'card.print',
  NO_ORDER_FOUND = 'card.no_order_found',
  CARD_TYPE = 'card.type',
}

enum Settings {
  SETTINGS = 'settings.settings',
  DESCRIPTION = 'settings.description',
  GYM_SETTINGS = 'settings.gym_settings',
  STAFF = 'settings.staff',
  EMAIL_SETTINGS = 'settings.email_settings',
  COMPANY = 'settings.company_settings',
  CONTRACT_TEMPLATE = 'settings.contract_template',
  AUTO_CHECK_OUT = 'settings.auto_check_out',
  AUTO_CHECK_OUT_TIME = 'settings.auto_check_out_time',
  AUTO_CHECK_OUT_TIME_FIRST = 'settings.auto_check_out_time_first',
  AUTO_CHECK_OUT_TIME_LAST = 'settings.auto_check_out_time_last',
  TIMEZONE = 'settings.timezone',
  EDIT_STAFF = 'settings.edit_staff',
  ADD_NEW_STAFF = 'settings.add_new_staff',
  CONTACT_PHONE = 'settings.contact_phone',
  PERMISSIONS = 'settings.permissions',
  TAKE_PHOTO = 'settings.take_photo',
  SEARCH_STAFF = 'settings.search_staff',
  SEND_HAPPY_BIRTHDAY = 'settings.send_happy_birthday',
  NEW_MEMBERSHIP_TO_MEMBER = 'settings.new_membership_to_member',
  MEMBERSHIP_EXPIRATION_EMAILS = 'settings.membership_expiration_emails',
  NOTIFY_EXPIRATION_DAYS = 'settings.notify_expiration_days',
  NOTIFY_REMAINING_SESSIONS = 'settings.notify_remaining_sessions',
  NOTIFY_REMAINING_SESSIONS_DETAIL = 'settings.notify_remaining_sessions_detail',
  INACTIVE_MEMBER_EMAIL = 'settings.inactive_member_email',
  NOTIFY_MEMBER_NO_SUBSCRIPTION = 'settings.notify_member_no_subscription',
  NOTIFY_MEMBER_NO_SUBSCRIPTION_DETAILS = 'settings.notify_member_no_subscription_details',
  NAME = 'settings.name',
  ADDRESS = 'settings.address',
  OWNER_NAME = 'settings.owner_name',
  EMAIL = 'settings.email',
  ERROR_INPUT_VALUE = 'settings.error_input_value',
  CONTRACT_SETTINGS = 'settings.contract_settings',
  CONTRACT_NUMBER = 'settings.contract_number',
  CONTRACT_SERIES = 'settings.contract_series',
  MEMBER_TAGS = 'settings.member_tags',
  GYM_TAGS = 'settings.gym_tags',
  CONTRACT_SAVE_SUCCESS = 'settings.contract_save_success',
  CONTRACT_SAVE_ERROR = 'settings.contract_save_error',
  CONTRACT_PLACEHOLDER_TEXT = 'settings.contract_placeholder_text',
}

enum Dashboard {
  DASHBOARD = 'dashboard.dashboard',
  DESCRIPTION = 'dashboard.description',
  SUBSCRIBERS = 'dashboard.subscribers',
  TOTAL_INCOME = 'dashboard.total_income',
  MEMBERSHIPS_TODAY = 'dashboard.memberships_today',
  MEMBERSHIPS_EXPIRE_TODAY = 'dashboard.memberships_expire_today',
  ENTRIES_LOG = 'dashboard.entries_log',
  MEMBERS_CHECK_IN_HOUR_GRAPH = 'dashboard.members_check_in_hour_graph',
  INCOME_LOG = 'dashboard.income_log',
  MEMBERSHIPS_INCOME_HOUR_GRAPH = 'dashboard.membership_income_hour_graph',
  MEMBERS_LOG = 'dashboard.members_log',
  ENTRIES_HISTORY = 'dashboard.entries_history',
  MEMBERSHIP = 'dashboard.membership',
  CHECK_IN = 'dashboard.check_in',
  CHECK_OUT = 'dashboard.check_out',
  CHECK_OUT_TYPE = 'dashboard.check_out_type',
  MANUAL = 'dashboard.manual',
  AUTOMATIC = 'dashboard.automatic',
  MEMBERSHIPS_INCOME = 'dashboard.memberships_income',
  MEMBERSHIPS_INCOME_DESCRIPTION = 'dashboard.memberships_income_description',
  EXPIRING_MEMBERSHIP = 'dashboard.expiring_memberships',
  EXPIRING_MEMBERSHIP_DESCRIPTION = 'dashboard.expiring_memberships_description',
  OPEN_ORDERS = 'dashboard.open_orders',
  OPEN_ORDERS_DESCRIPTION = 'dashboard.open_orders_description',
  MEMBERSHIP_OR_PRODUCT = 'dashboard.membership_or_product',
  QUANTITY = 'dashboard.quantity',
  DISCOUNT = 'dashboard.discount',
  PRICE = 'dashboard.price',
  DATETIME = 'dashboard.datetime',
  SOLD_PRODUCTS = 'dashboard.sold_products',
  SOLD_PRODUCTS_DESCRIPTION = 'dashboard.sold_products_description',
  PRODUCT_NAME = 'dashboard.product_name',
  PRICE_AFTER_DISCOUNT = 'dashboard.price_after_discount',
  PAYMENT_TYPE = 'dashboard.payment_type',
  SALE_HOUR = 'dashboard.sale_hour',
  PRODUCTS_STOCK = 'dashboard.products_stock',
  PRODUCTS_STOCK_DESCRIPTION = 'dashboard.products_stock_description',
  AVAILABLE_STOCK = 'dashboard.available_stock',
  STOCK_UPDATED_DATE = 'dashboard.stock_updated_date',
}

enum Report {
  REPORTS = 'report.reports',
  MEMBERSHIP_SALES = 'report.membership_sales',
  PRODUCT_SALES = 'report.products_sales',
  MEMBERS_ACTIVITY = 'report.members_activity',
  CHANGE_LOG = 'report.change_log',
  EXPIRING_MEMBERSHIPS = 'report.expiring_memberships',
  MEMBERS_WITH_MEMBERSHIPS = 'report.members_with_memberships',
  MEMBERS_REGISTERED = 'report.members_registered',
  RETURNING_MEMBERS = 'report.returning_members',
  SELECT_REPORT = 'report.select_report',
  FILTER_OPTION = 'report.filter_options',
  PERIOD_FILTER_LABEL = 'report.period_filter_label',
  COMPARE_TO = 'report.compare_to',
  STAFF = 'report.staff',
  PAYMENT_TYPE = 'report.payment_type',
  MEMBER_GROUP = 'report.member_group',
  EXPORT_XLS = 'report.export_xls',
  EXPORT_PDF = 'report.export_pdf',
  SELECT_STAFF = 'report.select_staff',
  SELECT_PAYMENT_TYPE = 'report.select_payment_type',
  SELECT_MEMBER_GROUP = 'report.select_member_group',
  MEMBERSHIP = 'report.membership',
  PRODUCT = 'report.product',
  SELECT_MEMBERSHIP = 'report.select_membership',
  SELECT_PRODUCT = 'report.select_product',
  MEMBERSHIP_GRAPH_DESCRIPTION = 'report.membership_graph_description',
  PRODUCT_GRAPH_DESCRIPTION = 'report.product_graph_description',
  MEMBERSHIP_COMPARE = 'report.membership_compare',
  PRODUCT_COMPARE = 'report.product_compare',
  STAFF_NAME = 'report.staff_name',
  MEMBER = 'report.member',
  MEMBERS_ACTIVITY_GRAPH_DESCRIPTION = 'report.members_activity_graph_description',
  MEMBERS_ACTIVITY_COMPARE = 'report.members_activity_compare',
  TOTAL_TIME_SPENT = 'report.total_time_spent',
  SESSIONS = 'report.sessions',
  CHECKOUT_TYPE = 'report.checkout_type',
  UNIQUE_MEMBERS = 'report.unique_members',
  AVERAGE_ENTRIES = 'report.average_entries',
  AVERAGE_TIME = 'report.average_time',
  BUSIEST_HOUR = 'report.busiest_hour',
  TOTAL_MEMBERSHIPS_INCOME = 'report.total_memberships_income',
  AVERAGE_MEMBERSHIPS_INCOME = 'report.average_memberships_income',
  TOTAL_MEMBERSHIPS_SOLD = 'report.total_memberships_sold',
  TOTAL_DELETED_MEMBERSHIPS = 'report.total_deleted_memberships',
  TOTAL_PRODUCTS_INCOME = 'report.total_products_income',
  AVERAGE_PRODUCTS_INCOME = 'report.average_products_income',
  TOTAL_ORDERS = 'report.total_orders',
  TOTAL_PRODUCTS = 'report.total_products',
  ENTITY_TYPE = 'report.entity_type',
  ENTITY_NAME = 'report.entity_name',
  CHANGE_TYPE = 'report.change_type',
  FIELD_NAME = 'report.field_name',
  OLD_VALUE = 'report.old_value',
  NEW_VALUE = 'report.new_value',
  MEMBERS_SUBSCRIPTION = 'report.members_subscription',
  TYPE_CREATE = 'report.type_create',
  TYPE_UPDATE = 'report.type_update',
  TYPE_DELETE = 'report.type_delete',
  EXPIRING_DATE = 'report.expiring_date',
  MEMBER_PHONE = 'report.member_phone',
  MEMBER_EMAIL = 'report.member_email',
  MEMBER_AGE = 'report.member_age',
  REGISTER_DATE = 'report.register_date',
  REGISTERED_IN_BETWEEN = 'report.registered_in_between',
  ACTIVE_IN_BETWEEN = 'report.active_in_between',
  EXPIRE_IN_BETWEEN = 'report.expire_in_between',
  SELECT_MEMBERSHIP_CATEGORY = 'report.select_membership_category',
  MEMBER_STATUS = 'report.member_status',
  AGE_BETWEEN = 'report.age_between',
  GENDER_SELECT = 'report.gender_select',
  MEMBER_ACTIVE_IN_BETWEEN = 'report.member_active_in_between',
  MEMBER_RETURNED_IN_BETWEEN = 'report.member_returned_in_between',
  SALE_DATE = 'report.sale_date',
  RETURNING_MEMBERS_COUNT = 'report.returning_members_count',
  TOTAL_MEMBERS_COUNT = 'report.total_members_count',
}

enum Profile {
  PROFILE_DETAILS = 'profile.profile_details',
  PROFILE_DESCRIPTION = 'profile.profile_description',
  PROFILE_PHOTO = 'profile.profile_photo',
  PERSONAL_INFO = 'profile.personal_info',
  NAME = 'profile.name',
  NAME_PLACEHOLDER = 'profile.name_placeholder',
  CONTACT_PHONE = 'profile.contact_phone',
  CONTACT_PHONE_PLACEHOLDER = 'profile.contact_phone_placeholder',
  EMAIL = 'profile.email',
  EMAIL_PLACEHOLDER = 'profile.email_placeholder',
  LANGUAGE = 'profile.language',
  SET_LANGUAGE_DESCRIPTION = 'profile.set_language_description',
  CHANGE_PASSWORD = 'profile.change_password',
  OLD_PASSWORD = 'profile.old_password',
  NEW_PASSWORD = 'profile.new_password',
  CONFIRM_PASSWORD = 'profile.confirm_password',
  WRONG_PASSWORD_ERROR = 'profile.wrong_password_error',
  PROFILE_UPDATED_SUCCESSFULLY = 'profile.profile_updated_successfully',
  PASSWORD_UPDATED_SUCCESSFULLY = 'profile.password_updated_successfully',
}

enum Empty {
  MEMBERS_LOG_TITLE = 'empty.members_log_title',
  MEMBERS_LOG_DESCRIPTION = 'empty.members_log_description',
  MEMBERSHIPS_INCOME_TITLE = 'empty.memberships_income_title',
  MEMBERSHIPS_INCOME_DESCRIPTION = 'empty.memberships_income_description',
  MEMBERS_NO_DATA_TITLE = 'empty.members_no_data_title',
  MEMBERS_NO_DATA_DESCRIPTION = 'empty.members_no_data_description',
  MEMBERS_NO_SUBSCRIPTION_TITLE = 'empty.members_no_subscription_title',
  MEMBERS_NO_SUBSCRIPTION_DESCRIPTION = 'empty.members_no_subscription_description',
  MEMBERSHIP_NO_DATA_TITLE = 'empty.membership_no_data_title',
  MEMBERSHIP_NO_DATA_DESCRIPTION = 'empty.membership_no_data_description',
  POS_NO_SELECTION_TITLE = 'empty.pos_no_selection_title',
  POS_NO_SELECTION_DESCRIPTION = 'empty.pos_no_selection_description',
  POS_NO_SELECTION_MEMBERSHIPS_TITLE = 'empty.pos_no_selection_memberships_title',
  POS_NO_SELECTION_PRODUCTS_TITLE = 'empty.pos_no_selection_products_title',
  POS_NO_MEMBERSHIP = 'empty.pos_no_membership',
  POS_NO_PRODUCTS = 'empty.pos_no_products',
  STAFF_NO_DATA_TITLE = 'empty.staff_no_data_title',
  STAFF_NO_DATA_DESCRIPTION = 'empty.staff_no_data_description',
  CLASSES_NO_DATA_TITLE = 'empty.classes_no_data_title',
  CLASSES_NO_DATA_DESCRIPTION = 'empty.classes_no_data_description',
  ROOMS_NO_DATA_TITLE = 'empty.rooms_no_data_title',
  ROOMS_NO_DATA_DESCRIPTION = 'empty.rooms_no_data_description',
  EXPIRING_MEMBERSHIPS_TITLE = 'empty.expiring_memberships_title',
  EXPIRING_MEMBERSHIPS_DESCRIPTION = 'empty.expiring_memberships_description',
  OPEN_ORDERS_TITLE = 'empty.open_orders_title',
  OPEN_ORDERS_DESCRIPTION = 'empty.open_orders_description',
  SOLD_PRODUCTS_TITLE = 'empty.sold_products_title',
  SOLD_PRODUCTS_DESCRIPTION = 'empty.sold_products_description',
  PRODUCTS_STOCK_TITLE = 'empty.products_stock_title',
  PRODUCTS_STOCK_DESCRIPTION = 'empty.products_stock_description',

  INVOICES_NO_DATA_TITLE = 'empty.invoices_no_data_title',
  INVOICES_NO_DATA_DESCRIPTION = 'empty.invoices_no_data_description',
}

enum Common {
  HISTORY = 'common.history',
  CLOSE = 'common.close',
  CANCEL = 'common.cancel',
  SAVE = 'common.save',
  YES = 'common.yes',
  NO = 'common.no',
  ALLOWED_FILE_TYPES = 'common.allowed_file_types',
  EDIT = 'common.edit',
  ADD = 'common.add',
  DETAILS = 'common.details',
  NAME = 'common.name',
  EMAIL = 'common.email',
  PHONE = 'common.phone',
  PRICE = 'common.price',
  CREATE = 'common.create',
  HOUR = 'common.hour',
  DAY = 'common.day',
  DAYS = 'common.days',
  MONTH = 'common.month',
  YEAR = 'common.year',
  STATUS = 'common.status',
  ACTIONS = 'common.actions',
  ACTIVATE = 'common.activate',
  DEACTIVATE = 'common.deactivate',
  DELETE = 'common.delete',
  LIMITED = 'common.limited',
  UNLIMITED = 'common.unlimited',
  ALL = 'common.all',
  ACTIVE = 'common.active',
  INACTIVE = 'common.inactive',
  CLEAR_ALL = 'common.clear_all',
  START_DATE = 'common.start_date',
  END_DATE = 'common.end_date',
  NOTE = 'common.note',
  VAT = 'common.vat',
  HOLD = 'common.hold',
  CASH = 'common.cash',
  CARD = 'common.card',
  OP = 'common.op',
  ONLINE = 'common.online',
  OTHER = 'common.other',
  QUANTITY = 'common.quantity',
  DISCOUNT = 'common.discount',
  TOTAL_PRICE = 'common.total_price',
  CATEGORY = 'common.category',
  SETTING = 'common.settings',
  SELECT_OPTION = 'common.select_option',
  PERIOD = 'common.period',
  SELECT_PERIOD = 'common.select_period',
  TODAY = 'common.today',
  YESTERDAY = 'common.yesterday',
  THIS_WEEK = 'common.this_week',
  LAST_WEEK = 'common.last_week',
  THIS_MONTH = 'common.this_month',
  LAST_MONTH = 'common.last_month',
  LAST_TWO_MONTHS = 'common.last_two_months',
  CUSTOM_RANGE = 'common.custom_range',
  DATE = 'common.date',
  CONFIRM = 'common.confirm',
  SESSIONS = 'common.sessions',
  PRODUCT = 'common.product',
  MEMBERSHIP = 'common.membership',
  STAFF_NAME = 'common.staff_name',
  CHECK_IN = 'common.check_in',
  CHECK_OUT = 'common.check_out',
  TIME = 'common.time',
  AMOUNT = 'common.amount',
  VALID_BETWEEN = 'common.valid_between',
  VALID_UNTIL = 'common.valid_until',
  SELL_DATE = 'common.sale_date',
  SESSIONS_AVAILABLE = 'common.sessions_available',
  SESSIONS_USED = 'common.sessions_used',
  USED = 'common.used',
  TOTAL_PAID = 'common.total_paid',
  UNIT_PRICE = 'common.unit_price',
  DOWNLOAD = 'common.download',
  UPLOAD = 'common.upload',
  NO_HISTORY_RECORDED = 'common.no_history_recorded',
  POOL = 'common.pool',
  FITNESS = 'common.fitness',
  SEE_PROFILE = 'common.see_profile',
  WITH = 'common.with',
  PRESENT = 'common.present',
  ABSENCE = 'common.absence',
  CLASSES_HISTORY = 'common.classes_history',
  RESERVED_CLASSES = 'common.reserved_classes',
  REMOVE_CHECKIN = 'common.remove_checkin',
  CONFIRM_CHANGE = 'common.confirm_change',
  BOOK_CLASS_SUCCESS = 'common.book_class_success',
  BOOK_CLASS_ERROR = 'common.book_class_error',
  BOOK_NOT_OPEN = 'common.book_class_error_open',
  BOOK_NO_MORE_SEATS = 'common.book_class_error_seats',
  BOOK_EXISTS = 'common.book_class_error_exists',
}

enum Error {
  PASSWORD_REQUIRED = 'error.password_required',
  USERNAME_REQUIRED = 'error.username_required',
  USERNAME_LENGTH = 'error.username_length',
  PASSWORD_LENGTH = 'error.password_length',
  PASSWORD_FORMAT = 'error.password_format',
  CASH_REGISTER_GENERIC = 'error.cash_register_generic',
  BARCODE_NO_REGISTERED_PRODUCT = 'error.barcode_no_registered_product',
  EMPTY_MEMBER_CARD = 'error.empty_member_card',
  OUT_OF_STOCK = 'error.out_of_stock',
  POS_ITEM_DISABLED = 'error.pos_item_disabled',
}

enum Table {
  FILTER = 'table.filter',
  ADD_ENTITY = 'table.add_entity',
  FILTER_OPTIONS = 'table.filter_options',
  RESET = 'table.reset',
  APPLY = 'table.apply',
  NO_RECORDS_FOUND = 'table.no_records_found',
  DELETE_MODAL_TITLE = 'table.delete_modal_title',
  DELETE_MODAL_CONFIRM = 'table.delete_modal_confirm',
  DELETE_ACTIVE_MEMBER = 'table.delete_active_member',
  DELETE_MODAL_TITLE_CLASS = 'table.delete_modal_title_class',
  DELETE_MODAL_TITLE_ROOM = 'table.delete_modal_title_room',
  DELETE_CLASS_SUCCESS = 'table.delete_class_success',
  TOTAL_RECORDS = 'table.total_records'
}

enum TableColumns {
  SESSIONS = 'table_columns.sessions',
  SESSIONS_REMAINING = 'table_columns.sessions_remaining',
  VAT_ID = 'table_columns.vat_id',
  REMINDER_SCHEDULE_ID = 'table_columns.reminder_schedule_id',
  STOCK = 'table_columns.stock',
  NAME = 'table_columns.name',
  END_DATE = 'table_columns.end_date',
  START_DATE = 'table_columns.start_date',
  PRICE = 'table_columns.price',
  NOTE = 'table_columns.note',
  CATEGORY_ID = 'table_columns.category_id',
  BARCODE = 'table_columns.barcode',
  TYPE_ID = 'table_columns.type_id',
  START_HOUR = 'table_columns.start_hour',
  END_HOUR = 'table_columns.end_hour',
  PERIOD = 'table_columns.period',
  PERIOD_UNIT = 'table_columns.period_unit',
  STATUS_ID = 'table_columns.status_id',
  VAT_POSITION = 'table_columns.vat_position',
  DEPARTMENT = 'table_columns.department',
  ARTICLE_GROUP = 'table_columns.article_group',
}

enum Menu {
  DASHBOARD = 'menu.dashboard',
  MEMBERS = 'menu.members',
  MEMBERSHIPS = 'menu.memberships',
  PRODUCTS = 'menu.products',
  POS = 'menu.pos',
  REPORTS = 'menu.reports',
  ORDER_CARD = 'menu.order_card',
  INVOICES = 'menu.invoices',
  GYMS = 'menu.gyms',
  SEND_NOTIFICATIONS = 'menu.send_notifications',
  CACHE_MANAGER = 'menu.cache_manager',
  CARDS_ORDERS = 'menu.cards_orders',
  SETTINGS = 'menu.settings',
  LOG_OUT = 'menu.log_out',
  PROFILE = 'menu.profile',
  CLASSES = 'menu.classes',
}

enum Gym {
  IDENTIFICATION_NUMBER = 'gym.identificationNumber',
  REGISTRATION_NUMBER = 'gym.registrationNumber',
  BANK_NAME = 'gym.bankName',
  BANK_ACCOUNT = 'gym.bankAccount',
  EMAIL = 'gym.email',
  ADMIN_NAME = 'gym.adminName',
  SEARCH_INPUT = 'gym.search_input',
  NAME = 'gym.name',
  OWNER_NAME = 'gym.owner_name',
  OWNER_PHONE = 'gym.owner_phone',
  OWNER_EMAIL = 'gym.owner_email',
  STATUS = 'gym.status',
  EDIT_GYM = 'gym.edit_gym',
  ADD_GYM = 'gym.add_gym',
  GYM_DETAILS = 'gym.gym_details',
  GYM_NAME = 'gym.gym_name',
  ADDRESS = 'gym.address',
  PHONE = 'gym.phone',
  COMPANY_DETAILS = 'gym.company_details',
  COMPANY_NAME = 'gym.company_name',
  ADMIN_FULL_NAME = 'gym.admin_full_name',
  SENDER_NAME = 'gym.senderName',
  SENDER_EMAIL = 'gym.senderEmail',
  CUI = 'gym.cui',
  INVOICING_SETUP = 'gym.invoicing_setup',
  INVOICE_NINJA_CUSTOMER_ID = 'gym.invoice_ninja_customer_id',
  NEW_CARD_INVOICE = 'gym.new_card_invoice',
  DISABLE_GYM = 'gym.disable_gym',
  CARD_SETUP = 'gym.card_setup',
  ORDER_CARDS = 'gym.order_cards',
  CUSTOM_CARDS = 'card.custom_cards',
  CARD_TYPE = 'card.card_type',
  CARD_LENGTH = 'card.card_length',
  CASH_REGISTER = 'gym.cash_register',
  CASH_REGISTER_ID = 'gym.cash_register_id',
  MULTI_LOCATIONS = 'gym.multi_locations',
  ENABLE_GYM_LOCATIONS = 'gym.enable_gym_locations',
  AUTO_CHECK_IN = 'gym.auto_check_in',
  SHOW_MEMBER_MODAL = 'gym.show_member_modal',
  ENABLE_SHOW_MEMBER_MODAL = 'gym.enable_show_member_modal',
  ENABLE_AUTO_CHECK_IN = 'gym.enable_auto_check_in',
  MODULES = 'gym.modules',
  PACKAGE_NAME = 'gym.package_name',
  PRODUCTS = 'gym.products',
  PRODUCTS_POS = 'gym.products_pos',
  REPORTS = 'gym.reports',
  ENABLE_REPORTS = 'gym.enable_reports',
  MEMBERSHIP_SALES = 'gym.membership_sales',
  PRODUCT_SALES = 'gym.product_sales',
  MEMBER_ACTIVITY = 'gym.member_activity',
  STAFF_CHANGES = 'gym.staff_changes',
  EXPIRING_MEMBERSHIPS = 'gym.expiring_memberships',
  MEMBERS_WITH_MEMBERSHIPS = 'gym.members_with_memberships',
  MEMBERS_REGISTERED = 'gym.members_registered',
  RETURNING_MEMBERS = 'gym.returning_members',
  NOTIFICATIONS = 'gym.notifications',
  EMAIL_NOTIFICATIONS = 'gym.email_notifications',
  HAPPY_BIRTHDAY = 'gym.happy_birthday',
  NEW_MEMBERSHIP_DETAILS = 'gym.new_membership_details',
  TRANSACTIONAL = 'gym.transactional',
  INACTIVE_MEMBER = 'gym.inactive_member',
  MEMBER_DOCUMENTS = 'gym.member_documents',
  MEMBER_CONTRACT = 'gym.member_contract',
  UPLOAD_DOCUMENTS = 'gym.upload_documents',
  MEMBER_PROFILE = 'gym.member_profile',
  ENABLE_MEMBER_LOGIN = 'gym.enable_member_login',
  MARKETING = 'gym.marketing',
  ENABLE_MARKETING = 'gym.enable_marketing',
  CLASSES = 'gym.classes',
  TRAINERS_AND_BOOK = 'gym.trainers_and_book',
  TIMEZONE = 'gym.timezone',
  SHOW_DASHBOARD_GRAPHS = 'gym.show_dashboard_graphs',
  ENABLE_DASHBOARD_GRAPHS = 'gym.enable_dashboard_graphs',
}

export const TranslationEnum = {
  Login,
  Member,
  Membership,
  Pos,
  Classes,
  Product,
  Card,
  Settings,
  Dashboard,
  Report,
  Profile,
  Common,
  Error,
  Table,
  Empty,
  Menu,
  Gym,
  TableColumns,
  Invoices,
};
