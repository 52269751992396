import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';
import { setAuthTokenAction } from '../store/actions/misc.actions';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  constructor(private store: Store) {}

  checkUserSession() {
    Auth.currentUserInfo().then((currentUser) => {
      if (currentUser) {
        Auth.currentSession().then((session) => {
          const accessToken = session?.getAccessToken()?.getJwtToken() || null;
          this.store.dispatch(setAuthTokenAction({ accessToken: accessToken }));
        });
      } else {
        this.store.dispatch(setAuthTokenAction({ accessToken: null }));
      }
    });
  }

  setAccessTokenRefreshInterval(){
    return setInterval(() => {
        try {
          Auth.currentAuthenticatedUser({ bypassCache: true }).then((currentUser) => {
            if(currentUser){
              Auth.currentSession().then((currentSession) => {
                const accessToken = currentSession?.getAccessToken()?.getJwtToken() || null;
                this.store.dispatch(setAuthTokenAction({ accessToken: accessToken }));
              });
            } 
            else {
              this.store.dispatch(setAuthTokenAction({ accessToken: null }));
            }
          });
          
        } catch (error) {
          this.store.dispatch(setAuthTokenAction({ accessToken: null }));
        }
      }, 1800000);
  }
}
