import { createReducer, on } from '@ngrx/store';
import { storeDocumentsAction } from '../actions/member.actions';
import { MemberDocument } from '../../models/member/member-document';

export interface MemberState {
  documents: MemberDocument[];
}

const initialMemberState: MemberState = {
  documents: [],
};

export const memberReducer = createReducer(
  initialMemberState,
  on(storeDocumentsAction, (state, { documents }) => ({
    ...state,
    documents,
  }))
);
