import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService extends HttpClient {
  apiUrl = '';
  constructor(handler: HttpHandler, private configService: ConfigService) {
    super(handler);
    this.apiUrl = this.configService.config.apiUrl;
  }

  get(url, options?, customUrl = false): Observable<any> {
    const fullUrl = customUrl ? url : this.apiUrl + url;

    return super.get(fullUrl, options);
  }

  post(url, body, options?, customUrl = false): Observable<any> {
    const fullUrl = customUrl ? url : this.apiUrl + url;

    return super.post(fullUrl, body, options);
  }

  put(url, body, options?, customUrl = false): Observable<any> {
    const fullUrl = customUrl ? url : this.apiUrl + url;

    return super.put(fullUrl, body, options);
  }

  delete(url, body?, options?, customUrl = false): Observable<any> {
    const fullUrl = customUrl ? url : this.apiUrl + url;

    return super.delete(fullUrl, { ...options, responseType: 'json', body });
  }
}
