import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MemberSetting } from '../../../models/member-logs/member-setting';
import { switchMap } from 'rxjs/operators';
import { MemberDetailsService } from '../../../services/member-details.service';
import { TranslationEnum } from '../../../../assets/i18n/translation-enum';

@Component({
  selector: 'app-member-consents',
  templateUrl: './member-consents.component.html',
  styleUrls: ['./member-consents.component.scss'],
})
export class MemberConsentsComponent implements OnInit {
  @Input()
  memberId: string;

  @Input()
  locationId: string;

  @Output()
  updateConsent = new EventEmitter<any>();

  @Input()
  set memberSettings(settings) {
    this.processMemberSettings(settings);
  }

  generalSettings = [
    {
      id: MemberSetting.GdprConsent,
      name: 'Gdpr consent',
      active: false,
      tooltipText: TranslationEnum.Member.TOOLTIP_GDPR,
    },
    {
      id: MemberSetting.BirthdateNotification,
      name: 'Birthday notifications',
      active: false,
      tooltipText: TranslationEnum.Member.TOOLTIP_BIRTHDAY,
    },
    {
      id: MemberSetting.MarketingNotification,
      name: 'Marketing notifications',
      active: false,
      tooltipText: TranslationEnum.Member.TOOLTIP_MARKETING,
    },
    {
      id: MemberSetting.TransactionalNotification,
      name: 'Transactional notifications (expire)',
      active: false,
      tooltipText: TranslationEnum.Member.TOOLTIP_TRANSACTIONS,
    },
  ];

  hasGdprConsent: boolean = false;

  constructor(private memberDetailsService: MemberDetailsService) {}

  ngOnInit(): void {}

  settingToggleChange(active, setting) {
    if (this.memberId) {
      this.memberDetailsService
        .updateMemberSetting(this.memberId, this.locationId, setting.id, active)
        .pipe(
          switchMap(() =>
            this.memberDetailsService.getMemberSettings(
              this.memberId,
              this.locationId
            )
          )
        )
        .subscribe((data) => {
          this.processMemberSettings(data);
        });
    } else {
      if (setting.id === MemberSetting.GdprConsent) {
        this.hasGdprConsent = active;
      }

      this.generalSettings = this.generalSettings.map((item) => {
        if (setting.id === MemberSetting.GdprConsent) {
          return {
            ...item,
            active:
              (item.id === MemberSetting.GdprConsent || item.active) && active,
          };
        }

        return {
          ...item,
          active: item.id === setting.id ? active : item.active,
        };
      });

      this.updateConsent.emit(this.generalSettings);
    }
  }

  disableSetting(setting) {
    return !this.hasGdprConsent && setting.id !== MemberSetting.GdprConsent;
  }

  private processMemberSettings(data) {
    this.generalSettings.map((setting) => {
      setting.active = data?.indexOf(setting.id) >= 0;
    });

    this.hasGdprConsent = this.hasGdprConsent =
      data?.indexOf(MemberSetting.GdprConsent) >= 0;
  }
}
