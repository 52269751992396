<!-- Custom menu -->

<div class="tg-main">
  <div class="tg-menu_head">
    <div class="logo">
      <img
        src="/assets/images/logo-new.svg"
        alt="Logo"
        class="logo-img img-fluid mr-2"
      />
    </div>
  </div>
  <div class="tg-menu_body">
    <ul ngbNav class="main-menu" orientation="vertical">
      <li [ngbNavItem]="entry.route" *ngFor="let entry of entries">
        <a
          ngbNavLink
          [routerLink]="entry.route"
          [routerLinkActive]="['active']"
          [class.active]="navLinkActive(entry.route)"
        >
          <img
            [src]="
              !router.url.includes(entry.route)
                ? '/assets/images/menu/' + entry.icon + '.svg'
                : '/assets/images/menu/' + entry.icon + '-active.svg'
            "
            alt="Profile Icon"
            class="img-fluid menu-icon"
          />
          {{ entry.label | translate }}
        </a>
      </li>
    </ul>
  </div>
  <div class="tg-menu_footer">
    <ul ngbNav class="bottom-menu" orientation="vertical">
      <li *ngIf="isGym && hasGymPermission" [ngbNavItem]="'/settings'">
        <a
          ngbNavLink
          [routerLink]="'/settings'"
          [class.active]="router.url.includes('/settings')"
        >
          <img
            [src]="
              !router.url.includes('/settings')
                ? '/assets/images/menu/settings.svg'
                : 'assets/images/menu/settings-active.svg'
            "
            alt="Profile Icon"
            class="img-fluid menu-icon"
          />
          {{ TranslationEnum.Menu.SETTINGS | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
