import { createReducer, on } from '@ngrx/store';
import * as GymActions from '../actions/gym.actions';
import { ProductCategory } from '../../models/membership-category';

export interface GymState {
  productCategories: ProductCategory[];
}

export const initialUserState: GymState = {
  productCategories: [],
};

export const gymReducer = createReducer(
  initialUserState,
  on(GymActions.storeProductCategories, (state, { productCategories }) => ({
    ...state,
    productCategories,
  }))
);
