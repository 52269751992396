import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getDocumentsAction,
  storeDocumentsAction,
} from '../actions/member.actions';
import { filter, map, switchMap } from 'rxjs/operators';
import { MemberDetailsService } from '../../services/member-details.service';
import { MemberDocument } from '../../models/member/member-document';

@Injectable()
export class MemberEffects {
  constructor(
    private actions$: Actions,
    private memberService: MemberDetailsService
  ) {}

  getMemberDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocumentsAction),
      filter((action) => !!action?.gymId && !!action?.memberId),
      switchMap((action) =>
        this.memberService.getDocuments(action.gymId, action.memberId)
      ),
      map((documents: MemberDocument[]) => storeDocumentsAction({ documents }))
    )
  );
}
