import { Injectable } from '@angular/core';
import { ListRequest } from '../models/requests/list-request';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpService } from './http.service';
import { OrderStatistics } from '../models/cards/order-statistics';
import { VendorSuperFilters } from '../models/cards/vendor-super-filters';

@Injectable({
  providedIn: 'root',
})
export class CardsService {
  constructor(private http: HttpService) {}

  getCardOrders(path: string, listRequest: ListRequest) {
    return this.http
      .post(`card/${path}`, listRequest)
      .pipe(catchError(() => of(null)));
  }

  editCardOrder(
    path: string,
    orderId: string,
    cardStatus?: string,
    cardTransport?: string,
    awb?: string,
    barcodes?: string[],
    issueInvoice?: boolean
  ) {
    return this.http
      .put(`card/${path}`, {
        orderId,
        cardTransport,
        cardStatus,
        awb,
        issueInvoice,
        barcodes,
      })
      .pipe(catchError(() => of(null)));
  }

  getStatistics(
    path: string,
    filters?: VendorSuperFilters
  ): Observable<OrderStatistics> {
    return this.http
      .post(`card/${path}`, !!filters ? filters : {})
      .pipe(catchError(() => of(null)));
  }

  orderCards(
    nrCards: number,
    cardColor: string,
    cardTransport: string,
    locationId: string,
    staffId: string,
    notes: string
  ): Observable<any> {
    return this.http
      .post('card', {
        nrCards,
        cardColor,
        cardTransport,
        locationId,
        staffId,
        notes,
      })
      .pipe(catchError(() => of(null)));
  }

  deleteCardsOrder(orderId: string): Observable<any> {
    return this.http
      .delete(`card/delete-super/${orderId}`)
      .pipe(catchError(() => of(null)));
  }

  exportXls(path: string, listRequest: ListRequest): Observable<Blob> {
    return this.http
      .post(`card/${path}`, listRequest, { responseType: 'blob' })
      .pipe(catchError(() => of(null)));
  }

  getOrderBarcodes(orderId: string): Observable<any> {
    return this.http
      .get(`card/barcodes-download-url/${orderId}`)
      .pipe(catchError(() => of(null)));
  }
}
