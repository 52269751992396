<div
  (click)="toggleSwitch()"
  [class.active]="switchActive"
  class="toggle-wrapper"
  [class.disabled]="disabled"
></div>

<ng-template #confirmationModal let-modal>
  <app-confirmation-modal
    [titleText]="confirmationText"
    [confirmText]="confirmationButton"
    cancelText="Cancel"
    [modal]="modal"
  ></app-confirmation-modal>
</ng-template>
