<div class="membership-box" *ngFor="let mem of memberships">
  <div class="inactive-memberships d-flex justify-content-between mb-2 mt-2">
    <div class="d-flex flex-column title w-100">
      <div class="font-weight-bold">{{ mem.name }}</div>
      <div>
        {{
          datePipe.transform(mem.dateStarted, dateFormat) +
            " - " +
            datePipe.transform(mem.dateEnded, dateFormat)
        }}
      </div>
    </div>
    <div class="line"></div>
    <div
      class="
        small-text
        right-section
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <div class="font-weight-bold" *ngIf="mem.totalSessions === -1">
        {{ TranslationEnum.Membership.UNLIMITED | translate }}
      </div>
      <div *ngIf="mem.totalSessions > -1">
        <span class="font-weight-bold">{{ mem.usedSessions }}</span
        >/<span class="font-grey font-weight-bold">{{
          mem.totalSessions
        }}</span>
      </div>
    </div>
    <div class="blue-overlay" *ngIf="edit" (click)="goToEdit.emit(mem)">
      Edit
    </div>
  </div>
</div>
