import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function customLength(length: number): ValidatorFn {
  return (formControl: FormControl): ValidationErrors => {
    return formControl.value?.length !== 0 &&
      (formControl.value?.length < length || formControl.value?.length > length)
      ? { customLengthError: true }
      : null;
  };
}
