<div
  class="toast-wrapper"
  [ngClass]="notification?.type"
  [class.show]="showNot"
>
  <div class="icon-notification">
    <ng-container [ngSwitch]="notification?.type">
      <img
        src="/assets/images/icons/done-solid.svg"
        alt="notification-success"
        class="not-icon"
        *ngSwitchCase="'success'"
      />
      <img
        src="/assets/images/icons/exclamation-solid.svg"
        alt="notification-error"
        class="not-icon"
        *ngSwitchCase="'danger'"
      />
    </ng-container>
  </div>
  <div class="d-flex">
    <p
      class="mb-0 toast-message"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(notification?.message)"
    ></p>
  </div>

  <div class="close" (click)="hideNotification()">
    <img
      src="/assets/images/menu/close-menu-success.svg"
      alt="close-toast"
      class="close"
    />
  </div>
</div>
