<div class="d-flex flex-column documents">
  <div class="d-flex justify-content-between">
    <div class="font-weight-bold d-flex align-items-center">
      {{ translateEnum.Member.DOCUMENTS | translate }}
    </div>
    <div class="d-flex">
      <ng-container>
        <div
          class="upload-button mr-2 d-inline-block"
          [class.disabled]="
            documentsUploadedCounter >= documentsPermissions.maxDocuments
          "
          [ngbPopover]="
            documentsUploadedCounter >= documentsPermissions.maxDocuments
              ? (translateEnum.Member.DOCUMENTS_LIMIT_REACHED | translate)
              : null
          "
          placement="bottom"
          triggers="mouseenter:mouseleave"
        >
          <label for="file-upload2">
            {{ translateEnum.Common.UPLOAD | translate }}
          </label>
          <input
            [disabled]="
              documentsUploadedCounter >= documentsPermissions.maxDocuments
            "
            type="file"
            id="file-upload2"
            *ngIf="
              documentsPermissions.uploadDocumentsEnabled &&
              documentsPermissions.maxDocuments > 0
            "
            class="input-hidden"
            accept="image/png,image/jpg,image/jpeg,application/pdf"
            (change)="uploadMemberDocument($event)"
          />
        </div>
      </ng-container>
      <div
        class="contract-button d-flex align-items-center"
        (click)="downloadContract()"
      >
        {{ translateEnum.Member.DOWNLOAD_CONTRACT | translate }}
      </div>
    </div>
  </div>
  <div class="d-flex flex-column big-gap mt-5">
    <div
      class="d-flex d-flex justify-content-between document"
      *ngFor="let doc of documents"
    >
      <div class="d-flex flex-column info">
        <div class="font-weight-bold">{{ doc.name }}</div>
        <div class="font-size-small">
          {{ translateEnum.Member.UPLOAD_DATE | translate }}: {{ doc.date }}
        </div>
      </div>
      <div
        class="
          font-weight-bold
          text-uppercase
          d-flex
          align-items-center
          actions
        "
      >
        <img
          src="assets/images/import.svg"
          alt="import"
          class="cursor-pointer"
          (click)="downloadDocument(doc)"
        />
        <img
          src="assets/images/trash.svg"
          alt="trash"
          class="cursor-pointer"
          (click)="deleteDocument(doc)"
        />
      </div>
    </div>
  </div>
</div>
