import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'tg-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ToggleComponent,
    },
  ],
})
export class ToggleComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input()
  switchActive = false;

  @Input()
  disabled = false;

  @Input()
  confirmationText: string;

  @Input()
  confirmationButton: string = 'Yes';

  @Input()
  showConfirmationModal: boolean = false;

  onChange = (value) => {};
  onTouched = () => {};
  touched = false;

  @ViewChild('confirmationModal', { static: true })
  confirmationModal: TemplateRef<any>;

  @Output() switchActiveChange = new EventEmitter<boolean>();

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  toggleSwitch() {
    if (this.disabled) {
      return;
    }

    if (!this.showConfirmationModal) {
      this.toggleSwitchInternal();
      return;
    }

    this.modalService
      .open(this.confirmationModal)
      .closed.pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.toggleSwitchInternal();
        }
      });
  }

  toggleSwitchInternal() {
    this.switchActive = !this.switchActive;
    this.switchActiveChange.emit(this.switchActive);
    this.onChange(this.switchActive);
  }

  writeValue(value: boolean) {
    this.switchActive = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!!value && value !== true && value !== false) {
      return {
        mustBeBoolean: true,
      };
    }

    return null;
  }
}
