export interface Membership {
  id: string;
  locationId: string;
  name: string;
  price: number;
  sessions: number;
  startHour: number;
  endHour: number;
  period: MembershipPeriod;
  periodUnit: number;
  note: string;
  vatPosition: number;
  department: number;
  articleGroup: number;
  type: MembershipType;
  category: string;
  status: MembershipStatus;
  vatId: number;
  validity: string;
  personalTrainerSessions: number;
}

export enum MembershipType {
  FITNESS = 'Fitness',
  CLASSES = 'Class',
  POOL = 'Pool',
  FITNESS_PT = 'FitnessPT',
  CLASSES_PT = 'ClassPT',
  POOL_PT = 'PoolPT',
}

export enum MembershipPeriod {
  HOUR = 'Hour',
  DAY = 'Day',
  MONTH = 'Month',
  YEAR = 'Year',
}

export enum MembershipStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
