import { Column } from './../../models/table-data/table-data';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import { getNotification } from '../../store/selectors/user.selector';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { saveToasterNotification } from '../../store/actions/misc.actions';
import { jsPDF } from 'jspdf';
import { VALID_DATE_REGEX } from './constants';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export function changePasswordControlTypeToText(controlId: string) {
  if (controlId === null) {
    return;
  }
  const control = document.getElementById(controlId);
  if (control === null) {
    return;
  }
  control.setAttribute(
    'type',
    control.getAttribute('type') === 'password' ? 'text' : 'password'
  );
}

export function formatDate(date: NgbDate) {
  return dayjs(`${date.year}-${date.month}-${date.day}`).toISOString();
}

export function getProgress(start: string, end: string) {
  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();
  const now = new Date().getTime();

  const daysDiff = Math.ceil((endDate - startDate) / (1000 * 3600 * 24));

  const todayDiff = Math.ceil((now - startDate) / (1000 * 3600 * 24));

  return Math.round(((todayDiff * 100) / daysDiff) * 100) / 100;
}

export function isEmptyDate(date: Date) {
  return (
    date.getDate() === 1 && date.getMonth() === 0 && date.getFullYear() === 1
  );
}

export function getISOStringDateNoTime(ngbDate: NgbDate): string {
  let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  date = new Date(date.valueOf() - date.getTimezoneOffset() * 60 * 1000);
  return date.toISOString();
}

export function getISOStringEndOfDayNoTime(ngbDate: NgbDate): string {
  let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  date.setHours(23, 59);
  date = new Date(date.valueOf() - date.getTimezoneOffset() * 60 * 1000);
  return date.toISOString();
}

export function getNotifications(
  store: Store<AppState>,
  setNotif = (val) => {}
) {
  return store.select(getNotification).pipe(
    filter((value) => !!value),
    distinctUntilChanged(),
    tap((value) => {
      setNotif(true);
      store.dispatch(saveToasterNotification({ toasterNotification: null }));
      setTimeout(() => setNotif(false), 3000);
    })
  );
}

export function createHeaders(columns: Column[], translateService: any) {
  const result = [];
  for (let i = 0; i < columns.length; i += 1) {
    result.push({
      id: columns[i].field,
      name: columns[i].field,
      prompt: translateService.instant(columns[i].header).toUpperCase(),
      width: 65,
      align: 'center',
      padding: 0,
    });
  }
  return result;
}

export function downloadAsPDF(fileName, columns: Column[], headers, data) {
  const parsedData = data.map((entry) => {
    const resObj = {};

    columns.forEach((col) => {
      if (entry[col.field] === null || entry[col.field] === undefined) {
        resObj[col.field] = '-';
      } else {
        resObj[col.field] = col.parseValue
          ? col.parseValue(entry[col.field])
          : isNaN(entry[col.field])
          ? entry[col.field]
          : entry[col.field]?.toString();
      }
    });

    return resObj;
  });

  var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' });
  doc.table(1, 1, parsedData, headers, { autoSize: true });
  doc.save(`${fileName}.pdf`);
}

export function logInvalidControl(form) {
  // good for debugging
  const invalid = [];
  const controls = form.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
}

export function downloadAsXls(data: Blob, reportName: string) {
  const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadURL;

  link.download = `${reportName}.xls`;
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(() => {
    window.URL.revokeObjectURL(downloadURL);
    link.remove();
  }, 100);
}

export function downloadFileAsPdf(data: Blob, name: string) {
  const blob = new Blob([data], { type: 'application/pdf' });
  const downloadURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadURL;

  link.download = `${name}.pdf`;
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(() => {
    window.URL.revokeObjectURL(downloadURL);
    link.remove();
  }, 100);
}

export function parseDateToFormat(date, currentFormat, newFormat) {
  if (dayjs(date, currentFormat, true).isValid()) {
    return dayjs(date, currentFormat).format(newFormat);
  }

  return date;
}

export const validateMemberBirthday = (birthday: NgbDate) => {
  const day =
    birthday?.day && (birthday.day < 10 ? `0${birthday.day}` : birthday.day);
  const month =
    birthday?.month &&
    (birthday.month < 10 ? `0${birthday.month}` : birthday.month);
  const date = `${day}/${month}/${birthday?.year}`;

  return (
    !(birthday.day || birthday.month || birthday.year) ||
    (VALID_DATE_REGEX.test(date) && dayjs().diff(dayjs(date, 'DD/MM/YYYY')) > 0)
  );
};
