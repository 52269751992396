<div
  [class.editSection]="membership?.id"
  [class.noPermission]="membership?.id && !hasEditPermission"
>
  <div class="title-section">
    <div class="d-flex justify-content-between">
      <span class="title font-weight-bold">
        {{ TranslationEnum.Common.EDIT | translate }}
        {{ TranslationEnum.Membership.MEMBERSHIP | translate }}
      </span>
      <div class="close-btn cursor-pointer" (click)="closeModule(false)">
        <img
          src="assets/images/icons/close.svg"
          alt="close"
          class="icon-close"
        />
      </div>
    </div>
  </div>

  <div [formGroup]="membershipForm" class="content-wrapper">
    <div class="d-flex justify-content-between">
      <span class="title mb-4 mt-0 d-block">
        {{ membership.name }}
      </span>
    </div>

    <div class="d-flex flex-column">
      <div
        *ngIf="membership.totalSessions !== -1"
        class="
          d-flex
          justify-content-between
          mb-4
          mobile-full
          align-items-center
        "
      >
        <span class="label">
          {{ TranslationEnum.Membership.USED_SESSIONS | translate }}
        </span>
        <input
          formControlName="sessions"
          type="number"
          [max]="membership?.totalSessions"
          min="0"
          [class.error]="hasErrors(membershipForm.get('sessions'))"
          class="mb-0 custom-size-group"
        />
        <div
          class="error-container"
          *ngIf="
            membershipForm.controls['sessions'].invalid &&
            (membershipForm.controls['sessions'].dirty ||
              membershipForm.controls['sessions'].touched)
          "
        >
          <span *ngIf="membershipForm.controls['sessions'].errors.required">
            {{ formErrors.mandatoryField }}
          </span>
        </div>
      </div>

      <div
        class="
          d-flex
          justify-content-between
          mb-4
          mobile-full
          align-items-center
        "
      >
        <span class="label">
          {{ TranslationEnum.Membership.VALID_BETWEEN | translate }}
        </span>
        <div class="custom-size-group d-flex">
          <app-date-picker
            [dateSelected]="transformDate(membership.dateStarted)"
            [disabled]="true"
          ></app-date-picker>
          <app-date-picker
            [minDate]="transformDate(membership.dateStarted)"
            class="ml-3 dp-left"
            [dateSelected]="transformDate(membership.dateEnded)"
            [startDate]="transformDate(membership.dateEnded)"
            (dateSelectedChange)="changeDate($event, membership)"
          ></app-date-picker>
        </div>
      </div>
    </div>

    <ng-container *ngIf="membership.personalTrainerTotalSessions > -1">
      <div class="d-flex justify-content-between">
        <span class="title mb-4 mt-0 d-block">
          {{ TranslationEnum.Membership.PT_SESSIONS | translate }}
        </span>
      </div>

      <div class="d-flex justify-content-between mb-4">
        <span class="label">
          {{ TranslationEnum.Membership.USED_SESSIONS | translate }}
        </span>
        <input
          formControlName="ptSessions"
          type="number"
          [max]="membership?.personalTrainerTotalSessions"
          min="0"
          [class.error]="hasErrors(membershipForm.get('ptSessions'))"
          class="mb-0 custom-size-group"
        />
      </div>
    </ng-container>

    <div class="d-flex justify-content-end mt-auto">
      <button
        class="btn btn-danger mr-auto"
        (click)="deleteClick()"
        [disabled]="loading"
      >
        {{ TranslationEnum.Common.DELETE | translate }}
      </button>

      <span class="btn btn-abort mr-4" (click)="closeModule(false)">
        {{ TranslationEnum.Common.CANCEL | translate }}
      </span>

      <button
        class="btn btn-primary"
        (click)="saveMembership()"
        [disabled]="membershipForm.invalid || loading"
      >
        {{ TranslationEnum.Common.SAVE | translate }}
      </button>
    </div>
  </div>
</div>
