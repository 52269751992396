import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PosMembership, PosProduct } from '../models/pos/pos-entity';
import { Order } from '../models/pos/order';
import { PlaceOrderRequest } from '../models/pos/place-order-request';
import { ProductOrderRequest } from '../models/pos/product-order-request';
import { MembershipOrderRequest } from '../models/pos/membership-order-request';
import { PosMember } from '../models/pos/pos-member';
import { SellItemVat } from '../models/sell-item-vat';
import { SortType } from '../models/pos/sort-type';

@Injectable({
  providedIn: 'root',
})
export class PosService {
  constructor(private http: HttpService) {}

  getMemberships(
    locationId: string,
    searchTerm = null,
    sortByValue: SortType = null
  ): Observable<PosMembership[]> {
    return this.http
      .get(
        `order/memberships/${locationId}?sortBy=${sortByValue}${
          searchTerm ? `&searchTerm=${searchTerm}` : ''
        }`
      )
      .pipe(catchError(() => of(null)));
  }

  getProducts(
    locationId: string,
    searchTerm = null,
    sortBy: SortType = null
  ): Observable<PosProduct[]> {
    const params = new URLSearchParams({});

    if (searchTerm) {
      params.set('searchTerm', searchTerm);
    }
    if (sortBy) {
      params.set('sortBy', sortBy);
    }

    const paramsString = params.toString();
    return this.http
      .get(
        `order/products/${locationId}` +
          (paramsString?.length ? `?${paramsString}` : '')
      )
      .pipe(catchError(() => of(null)));
  }

  getMembers(locationId: string, searchTerm = null): Observable<PosMember[]> {
    return this.http
      .get(
        `order/members/${locationId}` +
          (searchTerm ? `?searchTerm=${searchTerm}` : '')
      )
      .pipe(catchError(() => of(null)));
  }

  getPendingOrder(locationId: string, memberId: string): Observable<Order> {
    return this.http
      .get(`order/${locationId}` + (memberId ? `?memberId=${memberId}` : ''))
      .pipe(catchError(() => of(null)));
  }

  placeOrder(orderRequest: PlaceOrderRequest): Observable<Order> {
    return this.http.post(`order`, orderRequest).pipe(
      map((order) => this.modifyOrder(order)),
      catchError(() => of(null))
    );
  }

  changeOrder(orderRequest: PlaceOrderRequest): Observable<Order> {
    return this.http
      .put(`order`, orderRequest)
      .pipe(catchError(() => of(null)));
  }

  addModifyProduct(orderRequest: ProductOrderRequest): Observable<Order> {
    return this.http
      .post(`order/add-modify-product`, orderRequest)
      .pipe(catchError(() => of(null)));
  }

  addModifyMembership(orderRequest: MembershipOrderRequest): Observable<Order> {
    return this.http
      .post(`order/add-modify-membership`, orderRequest)
      .pipe(catchError(() => of(null)));
  }

  deleteOrder(orderId: string, locationId: string): Observable<Order> {
    return this.http
      .delete(`order/${orderId}/${locationId}`)
      .pipe(catchError(() => of(null)));
  }

  getProductsVat(locationId: string): Observable<SellItemVat[]> {
    return this.http
      .get(`settings/vats/${locationId}`)
      .pipe(catchError(() => of(null)));
  }

  modifyOrder(order) {
    return {
      ...order,
      membershipInfo: order.membershipInfo
        ? {
            ...order.membershipInfo,
            startDate: new Date(order.membershipInfo.startDate),
            endDate: new Date(order.membershipInfo.endDate),
          }
        : null,
    } as Order;
  }

  holdOrder(
    orderId: string,
    startDate: string,
    endDate: string,
    locationId: string
  ): Observable<Order> {
    return this.http
      .post(`order/hold`, { orderId, startDate, endDate, locationId })
      .pipe(catchError(() => of(null)));
  }

  forwardToCashRegister(command: string): Observable<boolean> {
    return new Observable((subscriber) => {
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.onload = function () {
        subscriber.next(true);
      };
      xmlhttp.onerror = function () {
        subscriber.next(false);
      };
      xmlhttp.open('POST', 'http://localhost:6969/cash');
      xmlhttp.send(JSON.stringify({ cashRegisterCommand: command }));
    });
  }
}
