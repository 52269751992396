import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationEnum } from '../../../../../assets/i18n/translation-enum';
import { dateFormat } from '../../../utilities/constants';
import { DatePipe } from '@angular/common';
import { MemberMembership } from '../../../../models/member-membership';

@Component({
  selector: 'app-check-in-memberships',
  templateUrl: './check-in-memberships.component.html',
  styleUrls: ['./check-in-memberships.component.scss'],
})
export class CheckInMembershipsComponent implements OnInit {
  @Input() memberships: MemberMembership[];
  @Input() edit = false;
  @Output() goToEdit = new EventEmitter<MemberMembership>();

  constructor(public datePipe: DatePipe) {}

  ngOnInit(): void {}

  TranslationEnum = TranslationEnum;
  dateFormat = dateFormat;
}
