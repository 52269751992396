import { createAction, props } from '@ngrx/store';
import { Profile } from '../../models/user/profile';
import { UserGym } from '../../models/user/user-gym';
import { SortType } from '../../models/pos/sort-type';

export const getProfileAction = createAction('[User] Get user profile');

export const storeProfileAction = createAction(
  '[User] Store user profile',
  props<{ profile: Profile }>()
);

export const getMainGymAction = createAction('[User] Get main gym');

export const storeMainGymAction = createAction(
  '[User] Store main gym',
  props<{ gym: UserGym }>()
);

export const markImpersonated = createAction(
  '[User] Mark impersonated',
  props<{ impersonated: boolean }>()
);

export const getProfileRedirectAction = createAction(
  '[User] Get profile redirect'
);

export const setRefreshInProgress = createAction(
  '[User] Set refresh in progress',
  props<{ inProgress: boolean }>()
);

export const updatePosOrderPreferences = createAction(
  '[User] Update pos order preferences',
  props<{ memberships: SortType; products: SortType }>()
);
