import { Component, Inject, OnInit } from '@angular/core';
import { PaginatedApiResponse } from '../../../../models/paginated-api-response';
import { Observable, Subject } from 'rxjs';
import { Product } from '../../../../models/product';
import {
  Column,
  ColumnDecoration,
  FilterField,
  FilterType,
} from '../../../../models/table-data/table-data';
import { TableAction } from '../../../../models/table-data/action-callback';
import { TableActions } from '../../../../models/table-data/table-actions';
import { EntityService } from '../../../../services/entity.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getDefaultLocationId } from '../../../../store/selectors/user.selector';
import { ListRequest } from '../../../../models/requests/list-request';
import {
  filter,
  first,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { getProductCategories } from '../../../../store/actions/gym.actions';
import { productCategories } from '../../../../store/selectors/gym.selector';
import { TranslationEnum } from '../../../../../assets/i18n/translation-enum';
import { TranslateService } from '@ngx-translate/core';

export function parseCategory(item) {
  return item?.name;
}

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  paginatedResponse: PaginatedApiResponse<Product>;
  loading = false;
  success = false;
  actionLoading = false;
  locationId$: Observable<string>;
  productEntity = 'product';
  showAddProduct = false;
  showEditProduct = false;
  productEditedId: string;

  listRequest = null;
  pageSize = 10;
  rowsPerPageOptions = [10, 20, 30];
  TranslationEnum = TranslationEnum;

  columns: Column[] = [
    {
      field: 'name',
      header: TranslationEnum.Common.NAME,
      link: true,
      parseValue: null,
    },
    {
      field: 'price',
      header: TranslationEnum.Common.PRICE,
      link: false,
      parseValue: null,
    },
    {
      field: 'barcode',
      header: TranslationEnum.Product.BARCODE,
      link: false,
      parseValue: null,
    },
    {
      field: 'category',
      header: TranslationEnum.Common.CATEGORY,
      link: false,
      parseValue: parseCategory,
    },
    {
      field: 'stock',
      header: TranslationEnum.Product.STOCK,
      link: false,
      parseValue: null,
    },
    {
      field: 'status',
      header: TranslationEnum.Common.STATUS,
      link: false,
      parseValue: null,
    },
    // {
    //   field: 'actions',
    //   header: TranslationEnum.Common.ACTIONS,
    //   link: false,
    //   parseValue: null,
    //   decorations: [ColumnDecoration.MORE_BUTTON],
    // },
  ];

  filterFields: FilterField[] = [
    {
      type: FilterType.SEARCH_SELECT,
      field: 'category',
      values: [],
      defaultValue: 'All',
      fieldName: this.translateService.instant(TranslationEnum.Common.CATEGORY),
    },
    {
      field: 'status',
      type: FilterType.SELECT,
      values: [
        {
          label: this.translateService.instant(TranslationEnum.Common.ACTIVE),
          value: 'Active',
        },
        {
          label: this.translateService.instant(TranslationEnum.Common.INACTIVE),
          value: 'Inactive',
        },
        {
          label: this.translateService.instant(TranslationEnum.Common.ALL),
          value: 'All',
        },
      ],
      defaultValue: 'All',
      fieldName: this.translateService.instant(TranslationEnum.Common.STATUS),
    },
  ];

  globalFilterFields = ['name'];
  placeholderSearchInput = 'Search for a product...';

  actions: TableAction[] = [
    {
      id: TableActions.EDIT,
      name: this.translateService.instant(TranslationEnum.Common.EDIT),
    },
    {
      id: TableActions.DEACTIVATE,
      name: this.translateService.instant(TranslationEnum.Common.INACTIVE),
    },
    {
      id: TableActions.DELETE,
      name: this.translateService.instant(TranslationEnum.Common.DELETE),
    },
  ];

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject('ProductService')
    private productService: EntityService<Product>,
    private router: Router,
    private store: Store,
    private translateService: TranslateService
  ) {
    this.productService.setEntity('product');
  }

  ngOnInit(): void {
    this.locationId$ = this.store.select(getDefaultLocationId);
    this.store.dispatch(getProductCategories());
    this.store.select(productCategories).subscribe((productCategories) => {
      this.filterFields[0].values = [
        {
          label: this.translateService.instant(TranslationEnum.Common.ALL),
          value: 'All',
        },
        ...productCategories
          .map((item) => ({
            label: item.name,
            value: item.id,
          }))
          .sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          ),
      ];
    });

    if (history.state.openModal) {
      this.showAddProduct = true;
    }
  }

  loadProducts(listRequest: ListRequest) {
    if (this.loading) {
      return;
    }
    this.loading = true;

    this.locationId$
      .pipe(
        takeUntil(this.destroy),
        filter((value) => !!value),
        switchMap((locationId: string) => {
          return this.callApi(listRequest, locationId);
        })
      )
      .subscribe((response) => {
        this.paginatedResponse = response;
        this.loading = false;
      });
  }

  callApi(listRequest: ListRequest, locationId: string) {
    if (!listRequest) {
      return;
    }
    listRequest.linkedEntityId = !locationId
      ? listRequest.linkedEntityId
      : locationId;
    listRequest.filters = listRequest.filters
      ? {
          ...listRequest.filters,
          status:
            listRequest.filters.status === 'All'
              ? null
              : listRequest.filters.status,
        }
      : null;
    this.listRequest = listRequest;
    return this.productService.getEntityList(listRequest).pipe(shareReplay(1));
  }

  actionCallback(actionId: TableActions, parameter: string) {
    switch (actionId) {
      case TableActions.DELETE:
        this.modify(parameter);
        break;
      case TableActions.DEACTIVATE:
        this.modify(parameter, true);
        break;
      case TableActions.EDIT:
        this.editProduct(parameter);
    }
  }

  modify(gymId: string, inactivate: boolean = false) {
    if (this.actionLoading) {
      return;
    }

    this.actionLoading = true;
    this.locationId$
      .pipe(
        first(),
        filter((value) => !!value),
        switchMap((locationId: string) =>
          inactivate
            ? this.productService.inactivateEntity(gymId, locationId)
            : this.productService.deleteEntity(gymId, locationId)
        )
      )
      .subscribe((response) => {
        this.success = response;
        this.actionLoading = false;
        this.loadProducts(this.listRequest);
      });
  }

  addNew() {
    this.showAddProduct = true;
  }

  editProduct(id) {
    this.showEditProduct = true;
    this.productEditedId = id;
  }

  closeActionModal(refreshItems) {
    if (refreshItems) {
      this.loadProducts(this.listRequest);
    }
    this.showAddProduct = false;
    this.showEditProduct = false;
    this.productEditedId = null;
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
}
