import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';
import { UserGymLocationStatus } from '../../models/user/user-gym-location-status';
import { MiscState } from '../reducers/misc.reducer';
import { AdminSettings } from '../../models/admin-settings';
import { Profile } from '../../models/user/profile';

const selectUserState = (state: AppState) => state.userState;
const selectMiscState = (state: AppState) => state.miscState;

export const getNotification = createSelector(
  selectMiscState,
  (state: MiscState) => state?.toasterNotification
);

export const getPermissionsSelector = createSelector(
  selectUserState,
  (state: UserState) => state?.profile?.permissions ?? []
);

export const getGymPermissionsSelector = createSelector(
  selectUserState,
  (state: UserState) => state?.gym?.permissions ?? []
);

export const getGymFeaturesSelector = createSelector(
  selectUserState,
  (state: UserState) => state?.gym?.gymFeatures
);

export const getProfile = createSelector(
  selectUserState,
  (state: UserState) => state?.profile
);

export const getAuthToken = createSelector(
  selectMiscState,
  (state: MiscState) => state?.accessToken
);

export const getUsername = createSelector(
  selectUserState,
  (state: UserState) => state?.profile?.name ?? ''
);

export const getDefaultLocationId = createSelector(
  selectUserState,
  (state: UserState) => {
    return (
      state?.gym?.locations?.find(
        (location) => location?.statusId === UserGymLocationStatus.MAIN
      )?.id ?? null
    );
  }
);

export const getCurrentGymId = createSelector(
  selectUserState,
  (state: UserState) => state?.gym?.id
);

export const getCurrentGymCustomCardLength = createSelector(
  selectUserState,
  (state: UserState) => state?.gym?.cardLength
);

export const getGymInfo = createSelector(
  selectUserState,
  (state: UserState) => {
    return {
      autoCheckOut: state?.gym?.autoCheckOut,
      autoCheckOutTime: state?.gym?.autoCheckOutTime,
      gymId: state?.gym?.id,
      cashRegister: state?.gym?.cashRegister,
      chromeAppId: state?.gym?.chromeAppId,
    } as AdminSettings;
  }
);

export const isImpersonated = createSelector(
  selectUserState,
  (state: UserState) => {
    return state?.impersonated ?? false;
  }
);

export const getCardPrice = createSelector(
  selectUserState,
  (state: UserState) => state?.gym?.cardPrice
);

export const getGymName = createSelector(
  selectUserState,
  (state: UserState) => state?.gym?.name
);

export const getRefreshInProgress = createSelector(
  selectUserState,
  (state: UserState) => state?.isRefreshInProgress
);

export const getPosSortPreferences = createSelector(
  getProfile,
  (state: Profile) => ({
    memberships: state?.membershipOrderPreference,
    products: state?.productOrderPreference,
  })
);

export const getGymTrainers = createSelector(
  selectUserState,
  (state: UserState) =>
    state?.gym?.locations?.find(
      (location) => location?.statusId === UserGymLocationStatus.MAIN
    )?.trainers || []
);
