import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { MemberMembership } from '../models/member-membership';
import { CheckinRequest } from '../models/checkin-request';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { MemberSetting } from '../models/member-logs/member-setting';
import { Members } from '../models/members';
import { HttpErrorResponse } from '@angular/common/http';
import { errorsMessages } from '../models/toaster-notification';
import { emptyDate } from '../shared/utilities/constants';

@Injectable({
  providedIn: 'root',
})
export class MemberDetailsService {
  public static readonly invalidString = 'invalid';
  public updateData: Subject<string> = new Subject<string>();
  constructor(private http: HttpService) {}

  getActiveMemberships(
    entity: string = 'active-memberships',
    memberId: string,
    linkedEntity: string,
    pageNumber: number,
    pageSize: number,
    filters?: any
  ): Observable<PaginatedApiResponse<MemberMembership>> {
    return this.http
      .post(`member/${entity}/${memberId}`, {
        linkedEntityId: linkedEntity,
        paginationParam: {
          pageNumber,
          pageSize,
        },
        filters,
      })
      .pipe(catchError(() => of(null)));
  }

  checkIn(checkInRequest: CheckinRequest, checkIn: boolean) {
    return this.http
      .post(`member/check-` + (checkIn ? 'in' : 'out'), checkInRequest)
      .pipe(catchError(() => of(null)));
  }

  getEntityLogs(
    entity: string,
    memberId: string,
    linkedEntity: string,
    pageNumber: number,
    pageSize: number,
    filters?: any
  ): Observable<PaginatedApiResponse<any>> {
    return this.http
      .post(`member/${entity}/${memberId}`, {
        linkedEntityId: linkedEntity,
        paginationParam: {
          pageNumber,
          pageSize,
        },
        filters,
      })
      .pipe(
        map((result) => this.processEntriesLogResponse(result)),
        catchError(() => of(null))
      );
  }

  editMembership(
    id: string,
    locationId: string,
    dateEnded: string,
    sessionsRemaining: number,
    ptSessionsRemaining: number
  ): Observable<MemberMembership> {
    return this.http
      .put('member/member-membership', {
        id,
        locationId,
        dateEnded,
        sessionsRemaining,
        ptSessionsRemaining,
      })
      .pipe(catchError(() => of(null)));
  }

  editMemberComment(
    memberId: string,
    locationId: string,
    comment: string
  ): Observable<MemberMembership> {
    return this.http
      .put('member/comment', {
        memberId,
        locationId,
        comment,
      })
      .pipe(catchError(() => of(null)));
  }

  getMemberSettings(memberId: string, locationId: string) {
    return this.http
      .get(`member/settings/${memberId}/${locationId}`)
      .pipe(catchError(() => of(null)));
  }

  deleteMembership(
    id: string,
    locationId: string
  ): Observable<MemberMembership> {
    return this.http
      .delete('member/member-membership', {
        id,
        locationId,
      })

      .pipe(
        catchError((e: HttpErrorResponse) => {
          if (e.status === 400) {
            const errMsg = errorsMessages.get(e.error.message);
            return throwError(errMsg);
          }
          return of(null);
        })
      );
  }

  updateMemberSetting(
    memberId: string,
    locationId: string,
    settingId: MemberSetting,
    turnOn: boolean
  ) {
    return this.http
      .post(`member/settings/${locationId}`, {
        memberId,
        settingId,
        turnOn,
      })
      .pipe(catchError(() => of(null)));
  }

  deleteMember(memberId: string, locationId: string) {
    return this.http
      .delete(`member/${locationId}/${memberId}`)
      .pipe(catchError(() => of(null)));
  }

  checkInVerify(checkInRequest: CheckinRequest) {
    return this.http
      .post(`member/check-in-verify`, checkInRequest)
      .pipe(catchError(() => of(null)));
  }

  uploadMemberPhoto(formData: FormData): Observable<any> {
    return this.http.post(`member/photo`, formData);
  }

  deleteMemberPhoto(userId: string) {
    return this.http.delete(`member/photo/${userId}`, null);
  }

  changeCard(request) {
    return this.http
      .put(`member/change-card`, request)
      .pipe(catchError(() => of(null)));
  }

  releaseCard(memberId) {
    return this.http
      .put(`member/release-card/${memberId}`, null)
      .pipe(catchError(() => of(null)));
  }

  downloadMemberLogsReport(
    memberId: string,
    locationId: string,
    isProducts
  ): Observable<Blob> {
    return this.http
      .get(
        `member/download/${
          isProducts ? 'products' : 'memberships'
        }/${memberId}/${locationId}`,
        {
          responseType: 'blob',
        }
      )
      .pipe(catchError(() => of(null)));
  }

  downloadMemberEntriesLogs(
    memberId: string,
    locationId: string
  ): Observable<Blob> {
    return this.http
      .get(`member/download/member-entries/${memberId}/${locationId}`, {
        responseType: 'blob',
      })
      .pipe(catchError(() => of(null)));
  }

  sendRegisterEmail(memberId, locationId) {
    return this.http
      .get(`member/resend-register-email/${memberId}/${locationId}`)
      .pipe(catchError(() => of(null)));
  }

  getMemberByBarcode(barcode: string, locationId: string): Observable<Members> {
    return this.http
      .get(`member/barcode/${locationId}/${barcode}`)
      .pipe(catchError(() => of(null)));
  }

  getMemberContract(gymId: string, memberId: string) {
    return this.http
      .get(`member/documents/contract/${gymId}/${memberId}`, {
        responseType: 'blob',
      })
      .pipe(catchError(() => of(null)));
  }

  getDocuments(gymId: string, memberId: string) {
    return this.http
      .get(`account/document/${gymId}/${memberId}`)
      .pipe(catchError(() => of(null)));
  }

  uploadDocument(gymId: string, memberId: string, formData: FormData) {
    return this.http
      .post(`account/document/${gymId}/${memberId}`, formData)
      .pipe(catchError((err) => throwError(err)));
  }

  deleteDocument(gymId: string, memberId: string, fileName: string) {
    return this.http
      .delete(`account/document/${gymId}/${memberId}`, { fileName })
      .pipe(catchError((err) => throwError(err)));
  }

  processEntriesLogResponse(result) {
    const dataObj = {
      ...result,
      data: result.data.map((value) => ({
        ...value,
        checkOutDate:
          new Date(value.checkOutDate).getDate() === emptyDate.getDate()
            ? null
            : value.checkOutDate,
        timeDifference: this.getTimeDifference(
          value.checkInDate,
          value.checkOutDate
        ),
      })),
    };

    return dataObj;
  }

  getTimeDifference(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (
      startDate.getDate() === emptyDate.getDate() ||
      endDate.getDate() === emptyDate.getDate()
    ) {
      return '-';
    }
    const startDateTime = new Date(start).getTime();
    const endDateTime = new Date(end).getTime();
    const minutesDiff = Math.ceil((endDateTime - startDateTime) / (1000 * 60));
    if (minutesDiff < 60) {
      return `0:${minutesDiff < 10 ? '0' : ''}${minutesDiff}`;
    } else {
      const hours = Math.trunc(minutesDiff / 60);
      const minutes = minutesDiff - hours * 60;
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    }
  }
}
