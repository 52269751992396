import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import {
  getGymPermissionsSelector,
  getPermissionsSelector,
} from '../../store/selectors/user.selector';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private accountService: AccountService,
    private store: Store<AppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return combineLatest([
      this.store.select(getPermissionsSelector),
      this.store.select(getGymPermissionsSelector),
    ]).pipe(
      filter(([userPermissions, gymPermissions]) => {
        if (route?.data?.gymPermission && !route?.data?.masterPermissions) {
          return !!userPermissions?.length && !!gymPermissions?.length;
        }

        return !!userPermissions?.length;
      }),
      map(([userPermissions, gymPermissions]) => {
        const userPermissionsLowerCase = userPermissions.map((perm) =>
          perm.toLowerCase()
        );

        const gymPermission = route.data?.gymPermission;
        const masterPermissions =
          route.data?.masterPermissions?.map((perm) => perm.toLowerCase()) ||
          [];

        if (
          !!masterPermissions?.filter((perm) =>
            userPermissionsLowerCase.includes(perm)
          )?.length
        ) {
          return true;
        } else if (
          gymPermission &&
          !gymPermissions.find((perm) => perm === gymPermission)
        ) {
          return false;
        }

        const andPermissions =
          route.data?.andPermissions?.map((perm) => perm.toLowerCase()) || null;
        if (andPermissions) {
          return !andPermissions?.filter(
            (perm) => !userPermissionsLowerCase.includes(perm)
          )?.length;
        }

        const orPermissions =
          route.data?.orPermissions?.map((perm) => perm.toLowerCase()) || null;
        if (orPermissions) {
          return (
            orPermissions.filter((perm) =>
              userPermissionsLowerCase.includes(perm)
            )?.length >= 1
          );
        }

        return true;
      })
    );
  }
}
