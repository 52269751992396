<span class="label align-self-center" *ngIf="showLabel">
  {{ TranslationEnum.Member.BIRTHDATE | translate }}
</span>
<div class="birthday-block" [class.custom-input]="displayHorizontally">
  <input
    type="text"
    placeholder="DD"
    maxlength="2"
    pattern="\d*"
    [class.error]="!isBirthdayValid"
    [ngModel]="day"
    (input)="onBirthdayChange($event, 'day')"
  />
  <input
    type="text"
    placeholder="MM"
    maxlength="2"
    pattern="\d*"
    [class.error]="!isBirthdayValid"
    [ngModel]="month"
    (input)="onBirthdayChange($event, 'month')"
  />
  <input
    type="text"
    placeholder="YYYY"
    maxlength="4"
    pattern="\d*"
    [class.error]="!isBirthdayValid"
    [ngModel]="year"
    (input)="onBirthdayChange($event, 'year')"
  />
</div>
