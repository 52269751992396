<div class="mobile-menu">
  <div class="top-bar">
    <div class="mobile-menu_logo d-flex">
      <img
        src="/assets/images/logo-new.svg"
        alt="Logo"
        class="logo-img img-fluid mr-2"
      />
    </div>
    <div class="mobile-menu_trigger d-flex">
      <img
        src="/assets/images/menu/mobile-trigger.svg"
        alt="Logo"
        class="open-menu img-fluid mt-2"
        *ngIf="!menuOpen"
        (click)="showMenu()"
      />
      <img
        src="/assets/images/menu/close-menu.svg"
        alt="Logo"
        class="open-menu img-fluid mt-2"
        *ngIf="menuOpen"
        (click)="showMenu()"
      />
    </div>
  </div>
</div>

<div class="menu-inner" [class.show]="menuOpen">
  <ul ngbNav class="main-menu" orientation="vertical">
    <li [ngbNavItem]="entry.route" *ngFor="let entry of entries">
      <a
        ngbNavLink
        [routerLink]="entry.route"
        [class.active]="router.url.startsWith(entry.route)"
        (click)="closeMenu()"
      >
        <img
          src="/assets/images/menu/settings_1.svg"
          alt="Profile Icon"
          class="img-fluid menu-icon"
        />
        {{ entry.label | translate }}
      </a>
    </li>
  </ul>

  <div class="profile-section">
    <div
      class="profile"
      (click)="expandProfile()"
      [class.arrow-up]="profileExpand"
    >
      <div class="avatar">
        <img
          src="/assets/images/menu/profile-icon-new.svg"
          alt="Profile Icon"
          class="img-fluid profile"
        />
        <span class="ml-2 profile-name">{{ username$ | async }}</span>
      </div>
      <img
        src="/assets/images/menu/arrow-down.svg"
        alt="Profile Icon"
        class="img-fluid arrow-down"
      />
    </div>
    <div class="profile-menu" *ngIf="profileExpand">
      <ul class="more">
        <li>
          <a href="" (click)="logout()">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</div>
