import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, of } from 'rxjs';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { catchError } from 'rxjs/operators';
import { ListRequest } from '../models/requests/list-request';

@Injectable()
export class EntityService<T> {
  private entity = '';
  constructor(private http: HttpService) {}

  setEntity(param: string) {
    this.entity = param;
  }

  getEntity(id: string, linkedEntityId: string = null): Observable<T> {
    return this.http
      .get(
        `${this.entity}/` +
          (linkedEntityId ? `${linkedEntityId}` : '') +
          `/${id}`
      )
      .pipe(catchError(() => of(null)));
  }

  getEntityList(request: ListRequest): Observable<PaginatedApiResponse<T>> {
    return this.http.post(`${this.entity}/list`, request);
  }

  createEntity(entity: T) {
    return this.http
      .post(`${this.entity}`, entity)
      .pipe(catchError((err) => of(err)));
  }

  modifyEntity(id: string, entity: T): Observable<T> {
    return this.http
      .put(`${this.entity}`, entity)
      .pipe(catchError((err) => of(err)));
  }

  inactivateEntity(
    entityId: string,
    linkedEntity: string = null
  ): Observable<boolean> {
    return this.http
      .put(
        `${this.modifyEntityUrl(entityId, linkedEntity, 'inactivate')}`,
        null
      )
      .pipe(catchError(() => of(false)));
  }

  deleteEntity(
    entityId: string,
    linkedEntity: string = null
  ): Observable<boolean> {
    return this.http
      .delete(this.modifyEntityUrl(entityId, linkedEntity))
      .pipe(catchError(() => of(false)));
  }

  private modifyEntityUrl(
    entityId: string,
    linkedEntity: string = null,
    crumb: string = null
  ): string {
    return (
      `${this.entity}/` +
      (crumb ? `${crumb}/` : '') +
      (linkedEntity ? `${linkedEntity}/` : '') +
      `${entityId}`
    );
  }

  createListRequest(listRequest: ListRequest, locationId: string): ListRequest {
    if (!listRequest) {
      return;
    }

    return {
      ...listRequest,
      linkedEntityId: locationId ?? listRequest.linkedEntityId,
      filters: {
        ...listRequest.filters,
        status:
          listRequest.filters.status === 'All'
            ? null
            : listRequest.filters.status,
      },
    };
  }

  createGroup(entity: T) {
    return this.http
      .post(`${this.entity}`, entity)
      .pipe(catchError((err) => of(err)));
  }
}
