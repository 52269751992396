export enum Permission {
  READ = 'Read',
  MANAGE_APP = 'ManageApp',
  MANAGE_LOCATIONS = 'ManageLocations',
  MANAGE_GYM = 'ManageGym',
  MANAGE_GYM_DASHBOARD = 'ManageGymDashboard',
  MANAGE_MEMBERSHIP = 'ManageMemberships',
  MANAGE_SOLD_MEMBERSHIP = 'ManageSoldMemberships',
  MANAGE_MEMBERS = 'ManageMembers',
  MANAGE_POS = 'ManagePos',
  MANAGE_REPORTS = 'ManageReports',
  MANAGE_PRODUCTS = 'ManageProducts',
  MANAGE_ORDERS_PROVIDER = 'ManageOrdersProvider',
  IMPERSONATION = 'Impersonation',
  MANAGE_CARD_ORDERS = 'ManageCardOrders',
  MANAGE_CLASSES = 'ManageClasses',
  MANAGE_INVOICES = 'ManageInvoices',
}

export const permissionLabels = new Map([
  [Permission.MANAGE_REPORTS, 'view_reports'],
  [Permission.MANAGE_MEMBERSHIP, 'manage_memberships'],
  [Permission.MANAGE_SOLD_MEMBERSHIP, 'manage_sold_memberships'],
  [Permission.MANAGE_PRODUCTS, 'manage_products'],
  [Permission.MANAGE_MEMBERS, 'manage_members'],
  [Permission.MANAGE_CARD_ORDERS, 'order_cards'],
  [Permission.MANAGE_GYM, 'manage_gym'],
  [Permission.MANAGE_CLASSES, 'manage_classes'],
  [Permission.MANAGE_INVOICES, 'manage_invoices'],
]);
