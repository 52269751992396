import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToasterNotification } from '../../../models/toaster-notification';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-toaster-notifications',
  templateUrl: './toaster-notifications.component.html',
  styleUrls: ['./toaster-notifications.component.scss'],
})
export class ToasterNotificationsComponent implements OnInit {
  @Input() notification: ToasterNotification;
  @Input() showNot: boolean;
  @Output() hideEvent = new EventEmitter<any>();

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {}

  hideNotification() {
    this.hideEvent.emit();
  }
}
