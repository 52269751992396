import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { EmailFormatValidatorDirective } from './directives/email-format-validator.directive';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPopoverModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TopGymDataTableComponent } from './components/top-gym-data-table/top-gym-data-table.component';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToasterNotificationsComponent } from './components/toaster-notifications/toaster-notifications.component';
import { HttpService } from '../services/http.service';
import { HttpHandler } from '@angular/common/http';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FilterBlockComponent } from './components/filter-block/filter-block.component';
import { FilterCalendarComponent } from './components/filter-block/filter-calendar/filter-calendar.component';
import { CheckInComponent } from './components/check-in-modal/check-in/check-in.component';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { RestrictInputDirective } from './directives/restrict-input.directive';
import { MultiSelectModule } from 'primeng/multiselect';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { ChartModule } from 'primeng/chart';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from '../services/config.service';
import { AddPhotoComponent } from './components/add-photo/add-photo.component';
import { WebcamModule } from 'ngx-webcam';
import { QuillModule } from 'ngx-quill';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DatePickerMultipleComponent } from './components/date-picker-multiple/date-picker-multiple.component';
import { CheckInHeaderComponent } from './components/check-in-modal/check-in-header/check-in-header.component';
import { CheckInMembershipsComponent } from './components/check-in-modal/check-in-memberships/check-in-memberships.component';
import { CheckInClassesComponent } from './components/check-in-modal/check-in-classes/check-in-classes.component';
import { CheckInHistoryComponent } from './components/check-in-modal/check-in-history/check-in-history.component';
import { CheckInDocsComponent } from './components/check-in-modal/check-in-docs/check-in-docs.component';
import { CheckInSettingsComponent } from './components/check-in-modal/check-in-settings/check-in-settings.component';
import { MembershipEditComponent } from './components/membership-edit/membership-edit.component';
import { MemberBirthdayComponent } from './components/member-birthday/member-birthday.component';
import { MemberConsentsComponent } from './components/member-consents/member-consents.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { CalendarClassComponent } from './components/calendar-class/calendar-class.component';
import { ClassesService } from '../services/classes.service';
import { AddMemberConfirmationComponent } from './components/add-member-confirmation/add-member-confirmation.component';

const classesServiceFactory = (httpService: HttpService) =>
  new ClassesService(httpService);

@NgModule({
  declarations: [
    MainMenuComponent,
    EmailFormatValidatorDirective,
    MobileMenuComponent,
    TopGymDataTableComponent,
    ToggleComponent,
    ToasterNotificationsComponent,
    ConfirmationModalComponent,
    FormatDatePipe,
    FilterBlockComponent,
    FilterCalendarComponent,
    CheckInComponent,
    RestrictInputDirective,
    DatePickerComponent,
    DatePickerMultipleComponent,
    DateRangePickerComponent,
    AddPhotoComponent,
    RichTextEditorComponent,
    CheckInHeaderComponent,
    CheckInMembershipsComponent,
    CheckInClassesComponent,
    CheckInHistoryComponent,
    CheckInDocsComponent,
    CheckInSettingsComponent,
    MembershipEditComponent,
    MemberBirthdayComponent,
    MemberConsentsComponent,
    CalendarViewComponent,
    CalendarClassComponent,
    AddMemberConfirmationComponent,
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    RouterModule,
    NgbDropdownModule,
    TableModule,
    DropdownModule,
    FormsModule,
    ButtonModule,
    NgSelectModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    RippleModule,
    InputTextModule,
    MultiSelectModule,
    ChartModule,
    TranslateModule,
    WebcamModule,
    QuillModule.forRoot(),
    NgbPopoverModule,
    FullCalendarModule,
    ReactiveFormsModule,
  ],
  exports: [
    MainMenuComponent,
    EmailFormatValidatorDirective,
    TopGymDataTableComponent,
    MobileMenuComponent,
    ToggleComponent,
    NgSelectModule,
    ToasterNotificationsComponent,
    FormatDatePipe,
    NgbNavModule,
    ConfirmationModalComponent,
    FilterBlockComponent,
    FilterCalendarComponent,
    CheckInComponent,
    RestrictInputDirective,
    DatePickerComponent,
    DatePickerMultipleComponent,
    DateRangePickerComponent,
    TranslateModule,
    AddPhotoComponent,
    RichTextEditorComponent,
    FullCalendarModule,
    NgbPopoverModule,
    MembershipEditComponent,
    MemberBirthdayComponent,
    MemberConsentsComponent,
    CalendarViewComponent,
    CalendarClassComponent,
    AddMemberConfirmationComponent,
  ],
  providers: [
    ConfigService,
    {
      provide: 'HttpService',
      useFactory: () => httpServiceFactory,
      deps: [HttpHandler, ConfigService],
    },
    {
      provide: 'ClassesService',
      useFactory: classesServiceFactory,
      deps: [HttpService],
    },
  ],
})
export class SharedModule {}

const httpServiceFactory = (
  httpHandler: HttpHandler,
  configService: ConfigService
) => new HttpService(httpHandler, configService);
