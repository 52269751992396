import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { GymState } from '../reducers/gym.reducer';

const selectGymState = (state: AppState) => state.gymState;

export const productCategories = createSelector(
  selectGymState,
  (state: GymState) => state?.productCategories
);
