import { PaginationRequest, paginationRequest } from './pagination-request';

export interface ListRequest {
  linkedEntityId: string;
  paginationParam: PaginationRequest;
  filters: any;
}

export function listRequest(
  filters,
  pageSize: number,
  pageNumber: number
): ListRequest {
  return {
    filters,
    paginationParam: paginationRequest(pageNumber, pageSize),
  } as ListRequest;
}
