<div class="content" #contentWrap>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between title-section">
        <span class="title font-weight-bold">{{ classItem.className }}</span>
        <div class="close-btn" (click)="closeModule(false)">
          <img
            src="/src/assets/images/icons/close.svg"
            alt="close"
            class="icon-close"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="class-details">
        <span class="detail-item">{{ classItem.trainer }}</span>
        <span class="detail-item">{{ classItem.room }}</span>
        <span class="detail-item"
          >Min {{ classItem.minParticipants }} - Max
          {{ classItem.maxParticipants }}</span
        >
        <span class="detail-item">{{ startDate }}</span>
        <span class="detail-item">{{ startTime }}</span>
      </div>
      <div class="search-result-wrapper">
        <div *ngIf="searchResultMember" class="search-row">
          <div class="member-name">{{ searchResultMember.name }}</div>
          <button class="btn btn-primary px-5" (click)="bookMember()">
            Add to class
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="member-row header">
            <div class="section-title">Member list</div>
            <div class="d-flex flex-column custom-input">
              <input
                type="text"
                [(ngModel)]="searchInput"
                (input)="searchMemberSubject$.next(searchInput)"
                class="mb-0"
                placeholder="Search..."
              />
              <img
                src="../../../../assets/images/icons/input-search.svg"
                alt=""
                class="search-icon"
              />
            </div>
          </div>
          <div *ngFor="let member of bookedMembers" class="member-row">
            <div class="member-name">{{ member.memberName }}</div>
            <button
              class="btn btn-secondary px-5"
              (click)="removeMember(member)"
            >
              Remove
            </button>
            <button
              class="btn btn-primary px-5 ml-2"
              (click)="checkInMember(member)"
              [disabled]="member.isCheckedIn"
            >
              Check In
            </button>
            <img
              *ngIf="member.isCheckedIn"
              class="check-icon mr-2"
              type="button"
              src="../../../../assets/images/icons/check-blue.svg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div *ngIf="waitlistMembers.length" class="col">
          <div class="member-row header">
            <div class="section-title">Waiting list</div>
          </div>
          <div *ngFor="let member of waitlistMembers" class="member-row">
            <div class="member-name">{{ member.memberName }}</div>
            <button
              class="btn btn-secondary px-5"
              (click)="removeMember(member)"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
