import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products/products.component';
import { RouterModule } from '@angular/router';
import { ProductsEditComponent } from './products-edit/products-edit.component';
import { ProductListComponent } from './product-list/product-list.component';
import { SharedModule } from '../../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ProductsRoutingModule } from './products-routing.module';
import { HttpService } from '../../../services/http.service';
import { EntityService } from '../../../services/entity.service';
import { Product } from '../../../models/product';
import { ProductCategory } from '../../../models/membership-category';

@NgModule({
  declarations: [
    ProductsComponent,
    ProductsEditComponent,
    ProductListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    ProductsRoutingModule,
  ],
  providers: [
    {
      provide: 'ProductService',
      useFactory: productServiceFactory(),
      deps: [HttpService],
    },
  ],
})
export class ProductsModule {}

export function productServiceFactory() {
  return (httpService: HttpService) => new EntityService<Product>(httpService);
}

export function productCategoryServiceFactory() {
  return (httpService: HttpService) =>
    new EntityService<ProductCategory>(httpService);
}
