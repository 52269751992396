import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  PeriodFilter,
  PeriodFilterAction,
  PeriodFilters,
} from '../../../models/cards/period-filters';
import {
  NgbDate,
  NgbDateStruct,
  NgbCalendarGregorian,
  NgbPopover,
} from '@ng-bootstrap/ng-bootstrap';
import { dateFormat } from '../../utilities/constants';
import { DatePipe } from '@angular/common';
import { TranslationEnum } from '../../../../assets/i18n/translation-enum';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  startDate: string;
  endDate: string;
  maxDateStruct: NgbDateStruct;
  selectedOption = null;
  displayCalendar = false;
  hoveredDate: NgbDate | null = null;
  TranslationEnum = TranslationEnum;

  @Input()
  periodFilters = PeriodFilters;

  @Input()
  clearable = false;

  @Input()
  displayRangeOnly: boolean = false;

  @Input() set resetOption(value) {
    if (!value) {
      return;
    }

    this.selectedOption = null;
    this.fromDate = null;
    this.toDate = null;
    this.startDate = null;
    this.endDate = null;
  }

  @Input() set selectOption(value: PeriodFilter) {
    this.onSelectPeriod(value);
  }

  @Output() selectedDates = new EventEmitter<{
    startDate: NgbDate;
    endDate: NgbDate;
  }>();

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() - 1);
    this.maxDateStruct = {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate(),
    };
  }

  getNgbDate(date: Date): NgbDate {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    } as NgbDate;
  }

  onSelectPeriod(event): void {
    this.selectedOption = event;
    if (!event) {
      this.toDate = null;
      this.fromDate = null;
      this.selectedDates.emit({
        startDate: this.fromDate,
        endDate: this.toDate,
      });
      return;
    }
    let eventAction = event.action;
    if (eventAction) {
      this.onSelectPeriodAction(eventAction);
    }
  }

  onOpenRangePicker(popover: NgbPopover) {
    popover.open();
    this.onSelectPeriodAction(PeriodFilterAction.CUSTOM);
  }

  private onSelectPeriodAction(action) {
    const calendar = new NgbCalendarGregorian();
    const today = this.getNgbDate(new Date());

    switch (action) {
      case PeriodFilterAction.TODAY:
        this.toDate = today;
        this.fromDate = today;
        break;
      case PeriodFilterAction.YESTERDAY:
        this.toDate = calendar.getPrev(today, 'd', 1);
        this.fromDate = this.toDate;
        break;
      case PeriodFilterAction.LAST_WEEK:
        this.toDate = calendar.getPrev(today, 'd', calendar.getWeekday(today));
        this.fromDate = calendar.getPrev(this.toDate, 'd', 6);
        break;
      case PeriodFilterAction.THIS_WEEK:
        this.fromDate = calendar.getPrev(
          today,
          'd',
          calendar.getWeekday(today) - 1
        );
        this.toDate = today;
        break;
      case PeriodFilterAction.THIS_MONTH:
        this.toDate = today;
        this.fromDate = calendar.getPrev(today, 'd', today.day - 1);
        break;
      case PeriodFilterAction.LAST_MONTH:
        this.toDate = calendar.getPrev(today, 'd', today.day);
        this.fromDate = calendar.getPrev(this.toDate, 'd', this.toDate.day - 1);
        break;
      case PeriodFilterAction.LAST2_MONTHS:
        this.toDate = calendar.getPrev(today, 'd', today.day);
        this.fromDate = calendar.getNext(
          calendar.getPrev(this.toDate, 'm', 2),
          'd',
          1
        );
        break;
    }
    this.displayCalendar = action === PeriodFilterAction.CUSTOM;
    if (!this.displayCalendar) {
      this.selectedDates.emit({
        startDate: this.fromDate,
        endDate: this.toDate,
      });

      this.formatDates();
    }
  }

  onDateSelection(date: NgbDate) {
    this.selectedOption = null;
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (!!this.fromDate && !!this.toDate) {
      this.toDate = null;
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      (date.after(this.fromDate) || date.equals(this.fromDate))
    ) {
      this.toDate = date;
      this.displayCalendar = false;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.toDate && this.fromDate) {
      this.selectedDates.emit({
        startDate: this.fromDate,
        endDate: this.toDate,
      });
    }

    this.formatDates();
  }

  private formatDates() {
    this.startDate = this.datePipe.transform(
      new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
      dateFormat
    );
    this.endDate = !!this.toDate
      ? this.datePipe.transform(
          new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day),
          dateFormat
        )
      : null;
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
}
