import { Component, Input, OnInit } from '@angular/core';
import { MenuEntry } from '../../../models/misc/menu-entry';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { getUsername } from '../../../store/selectors/user.selector';
import { Observable } from 'rxjs';

@Component({
  selector: 'tg-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  username$: Observable<string>;
  menuOpen = false;
  @Input()
  entries: MenuEntry[];
  profileExpand = false;
  constructor(public router: Router, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.username$ = this.store.select(getUsername);
  }

  showMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  logout(): void {}

  expandProfile(): void {
    this.profileExpand = !this.profileExpand;
  }
  closeMenu() {
    this.menuOpen = false;
  }
}
