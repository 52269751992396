import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { Gym } from '../models/gym';
import { ListRequest } from '../models/requests/list-request';
import { HttpService } from './http.service';
import { GymLight } from '../models/gym/gym-light';
import { AdminSettingsNew } from '../models/admin-settings-new';
import { AdminCompanySettings } from '../models/admin-company-settings';
import { IContractTemplate } from '../models/settings/contract-template';

@Injectable({
  providedIn: 'root',
})
export class GymService {
  constructor(private http: HttpService) {}

  getGyms(request: ListRequest): Observable<PaginatedApiResponse<Gym>> {
    return this.http.post(`gyms`, request).pipe(catchError(() => of(null)));
  }

  getGymsLight(): Observable<GymLight[]> {
    return this.http.get(`gyms/light`).pipe(catchError(() => of(null)));
  }

  getGym(gymId: string): Observable<Gym> {
    return this.http.get(`gyms/${gymId}`).pipe(catchError(() => of(null)));
  }

  impersonateGym(gymId: string) {
    return this.http
      .get(`gyms/impersonate/${gymId}`)
      .pipe(catchError(() => of(false)));
  }

  exitImpersonation() {
    return this.http
      .get(`account/exit-impersonate`)
      .pipe(catchError((e) => of(e)));
  }

  inactivateGym(gymId: string): Observable<boolean> {
    return this.http
      .post(`gyms/inactivate/${gymId}`, null)
      .pipe(catchError(() => of(false)));
  }

  deleteGym(gymId: string): Observable<boolean> {
    return this.http
      .post(`gyms/delete/${gymId}`, null)
      .pipe(catchError(() => of(false)));
  }

  createGym(gym: Gym): Observable<Gym> {
    return this.http.post(`gyms/create`, gym).pipe(catchError(() => of(null)));
  }

  modifyGym(gymId: string, gym: Gym): Observable<Gym> {
    return this.http.put(`gyms/${gymId}`, gym).pipe(catchError(() => of(null)));
  }

  switchGymPermission(
    gymId: string,
    permissionName: string,
    turnOn: boolean = false
  ): Observable<boolean> {
    return this.http
      .post(`gyms/change-permission`, {
        gymId,
        permissionName,
        turnOn,
      })
      .pipe(catchError(() => of(null)));
  }

  adminSettingsNew(gymId, body) {
    return this.http
      .post(`settings/${gymId}/general`, body)
      .pipe(catchError(() => of(null)));
  }

  getAdminSettings(gymId): Observable<AdminSettingsNew> {
    return this.http
      .get(`settings/${gymId}/general`)
      .pipe(catchError(() => of(null)));
  }

  getAdminCompanySettings(gymId): Observable<AdminCompanySettings> {
    return this.http
      .get(`settings/${gymId}/company`)
      .pipe(catchError(() => of(null)));
  }

  modifyAdminCompanySettings(
    gymId: string,
    settings: AdminCompanySettings
  ): Observable<AdminCompanySettings> {
    return this.http
      .post(`settings/${gymId}/company`, { gymId, ...settings })
      .pipe(catchError(() => of(null)));
  }

  exportBarcodeXls(gymId, NrCards): Observable<any> {
    return this.http
      .post(`gyms/generate-csv-barcodes`, {
        gymId,
        NrCards,
      })
      .pipe(catchError(() => of(null)));
  }

  getContractTemplate(gymId: string) {
    return this.http
      .get(`settings/${gymId}/contract`)
      .pipe(catchError(() => of(null)));
  }

  changeContractTemplate(gymId: string, contract: IContractTemplate) {
    return this.http
      .post(`settings/${gymId}/contract`, contract)
      .pipe(catchError((response) => throwError(response?.error)));
  }
}
