import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ToasterNotification } from '../../../../models/toaster-notification';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { getNotifications } from '../../../../shared/utilities/utility-functions';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  showNotification = false;
  notification$: Observable<ToasterNotification>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.notification$ = getNotifications(
      this.store,
      (val) => (this.showNotification = val)
    );
  }
}
