import { MenuEntry } from './menu-entry';
import { GymPermission } from '../gym-permission';
import { Permission } from '../user/permission';
import { TranslationEnum } from '../../../assets/i18n/translation-enum';

export function appAdminMenu(): MenuEntry[] {
  return [
    {
      label: TranslationEnum.Menu.GYMS,
      route: '/admin-gyms/summary',
      icon: 'icon-home',
      activeIcon: 'icon-home',
    },
    {
      label: TranslationEnum.Menu.SEND_NOTIFICATIONS,
      route: '/admin-gyms/send-notifications',
      icon: 'notfication',
      activeIcon: 'notfication',
    },
    {
      label: TranslationEnum.Menu.CACHE_MANAGER,
      route: '/admin-gyms/cache-manager',
      icon: 'dashboard',
      activeIcon: '',
    },
    {
      label: TranslationEnum.Menu.CARDS_ORDERS,
      route: 'order-card',
      icon: 'cards',
      activeIcon: '',
    },
  ] as MenuEntry[];
}

export function vendorMenu(): MenuEntry[] {
  return [
    {
      label: TranslationEnum.Menu.ORDER_CARD,
      route: '/order-card',
      icon: 'cards',
      activeIcon: '',
    },
  ];
}

export function gymMenu(): MenuEntry[] {
  return [
    {
      label: TranslationEnum.Menu.DASHBOARD,
      route: '/gym/dashboard',
      icon: 'dashboard',
      activeIcon: '',
      visible: true,
    },
    {
      label: TranslationEnum.Menu.MEMBERS,
      route: '/gym/members',
      icon: 'members',
      activeIcon: '',
      visible: false,
      userPerm: [Permission.MANAGE_MEMBERS],
    },
    {
      label: TranslationEnum.Menu.MEMBERSHIPS,
      route: '/gym/memberships',
      icon: 'memberships',
      activeIcon: '',
      visible: false,
      userPerm: [Permission.MANAGE_MEMBERSHIP],
    },
    {
      label: TranslationEnum.Menu.PRODUCTS,
      route: '/gym/products',
      icon: 'products',
      activeIcon: '',
      userPerm: [Permission.MANAGE_PRODUCTS],
      gymPerm: GymPermission.POS,
      visible: false,
    },
    {
      label: TranslationEnum.Menu.POS,
      route: '/gym/pos',
      icon: 'pos',
      activeIcon: '',
      visible: false,
      userPerm: [Permission.MANAGE_POS],
    },
    {
      label: TranslationEnum.Menu.CLASSES,
      route: '/gym/classes',
      icon: 'classes',
      activeIcon: '',
      visible: false,
      gymPerm: GymPermission.BookClasses,
    },
    {
      label: TranslationEnum.Menu.REPORTS,
      route: '/gym/reports',
      icon: 'reports',
      activeIcon: '',
      visible: false,
      gymPerm: GymPermission.Reports,
      userPerm: [Permission.MANAGE_REPORTS],
    },
    {
      label: TranslationEnum.Menu.ORDER_CARD,
      route: '/order-card',
      icon: 'cards',
      activeIcon: '',
      gymPerm: GymPermission.OrderCards,
      userPerm: [Permission.MANAGE_CARD_ORDERS],
      visible: false,
    },
    {
      label: TranslationEnum.Menu.INVOICES,
      route: '/invoices',
      icon: 'invoices',
      activeIcon: '',
      visible: true,
    },
  ] as MenuEntry[];
}
