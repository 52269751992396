import { Component, Input, OnInit } from '@angular/core';
import { MenuEntry } from '../../../models/misc/menu-entry';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import {
  getPermissionsSelector,
  getUsername,
} from '../../../store/selectors/user.selector';
import { AccountService } from '../../../services/account.service';
import { Permission } from '../../../models/user/permission';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { TranslationEnum } from '../../../../assets/i18n/translation-enum';

@Component({
  selector: 'tg-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  username$: Observable<string>;
  hasGymPermission = false;

  private destroy: Subject<boolean> = new Subject<boolean>();
  permisions$: Observable<any>;

  @Input()
  entries: MenuEntry[];
  @Input()
  isGym: boolean;

  TranslationEnum = TranslationEnum;

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.username$ = this.store.select(getUsername);
    this.permisions$ = this.store.select(getPermissionsSelector);
    this.permisions$.pipe(takeUntil(this.destroy)).subscribe((response) => {
      if (response.includes(Permission.MANAGE_GYM)) {
        this.hasGymPermission = true;
      }
    });
  }

  navLinkActive(route: string) {
    return this.router.isActive(route, false);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  }
}
