import { createAction, props } from '@ngrx/store';
import { ListRequest } from '../../models/requests/list-request';
import { PaginatedApiResponse } from '../../models/paginated-api-response';
import { Invoice } from '../../models/invoices/invoice';

export const getInvoicesAction = createAction(
  '[Cards] Get card orders action',
  props<{ listRequest: ListRequest }>()
);

export const storeInvoicesAction = createAction(
  '[Cards] Store card orders action',
  props<{ data: PaginatedApiResponse<Invoice> }>()
);
