import { ActionCallback } from '../../../../models/table-data/action-callback';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Product, ProductStatus } from '../../../../models/product';
import { BehaviorSubject, Observable, of, Subject, zip } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityService } from '../../../../services/entity.service';
import { Store } from '@ngrx/store';
import { getDefaultLocationId } from '../../../../store/selectors/user.selector';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { saveToasterNotification } from '../../../../store/actions/misc.actions';
import {
  CreateToasterError,
  CreateToasterSuccess,
  errorsMessages,
} from '../../../../models/toaster-notification';
import { ProductCategory } from '../../../../models/membership-category';
import { SellItemVat } from '../../../../models/sell-item-vat';
import { PosService } from '../../../../services/pos.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormErrors,
  nameMaxLength,
  nameMinLength,
} from '../../../../shared/utilities/constants';
import { TranslationEnum } from '../../../../../assets/i18n/translation-enum';
import { TableActions } from 'src/app/models/table-data/table-actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-products-edit',
  templateUrl: './products-edit.component.html',
  styleUrls: ['./products-edit.component.scss'],
})
export class ProductsEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  parentUrl: string;

  @Input()
  productId: string;

  @ViewChild(NgSelectComponent) categorySelect: NgSelectComponent;
  @ViewChild('categoryNotFound') notFound: TemplateRef<any>;
  @ViewChild('confirmationModal') confirmationModal: TemplateRef<any>;
  @Output() closeModal = new EventEmitter<boolean>();
  @Output()
  actionCallback: EventEmitter<ActionCallback> = new EventEmitter<ActionCallback>();

  productForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(nameMinLength),
      Validators.maxLength(nameMaxLength),
      Validators.pattern('[a-zA-Z0-9 ,.-:-]+'),
    ]),
    price: new FormControl('', [Validators.required, Validators.min(0)]),
    stock: new FormControl({ value: 0, disabled: false }, [
      Validators.pattern('^[\\d]+$'),
      Validators.required,
    ]),
    category: new FormControl(''),
    barcode: new FormControl('', [Validators.required]),
    note: new FormControl(''),
    vat: new FormControl('', [Validators.required]),
    status: new FormControl(),
  });

  createCategoryOption = 'Create...';
  loading = false;
  selectCategory = false;
  statuses = [ProductStatus.ACTIVE, ProductStatus.INACTIVE];

  product: Product = {} as Product;
  showCreate = false;
  locationId$: Observable<string>;
  success = false;
  searchCategorySubject = new BehaviorSubject<{ term: string; items: any[] }>(
    null
  );
  categoryRequest = {
    linkedEntityId: null,
    paginationParam: {
      pageNumber: 1,
      pageSize: 10,
    },
    filters: {
      name: '',
    },
  };
  categoryCreateName: string;
  createCategoryInProgress = false;
  categories: ProductCategory[];
  productsVat: SellItemVat[];
  nameMaxLength = nameMaxLength;
  nameMinLength = nameMinLength;
  formErrors = FormErrors;
  TranslationEnum = TranslationEnum;
  confirmModal = null;
  initialProductName;
  initialPrice;
  showErrors = false;

  @HostListener('document:keyup', ['$event'])
  handleEscapeEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeModule(false);
    }
  }

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject('ProductService')
    private productService: EntityService<Product>,
    @Inject('ProductCategoryService')
    private categoryService: EntityService<ProductCategory>,
    private posService: PosService,
    private store: Store,
    private modalService: NgbModal,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.categorySelect.notFoundTemplate = this.notFound;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  closeModule(value) {
    this.closeModal.emit(value);
  }

  ngOnInit(): void {
    this.productService.setEntity('product');
    this.categoryService.setEntity('product/category');
    this.locationId$ = this.store.select(getDefaultLocationId).pipe(
      tap((value) => {
        this.categoryRequest.linkedEntityId = value;
        this.product.locationId = value;
      })
    );
    this.locationId$
      .pipe(
        switchMap((locationId) => this.posService.getProductsVat(locationId))
      )
      .subscribe((productVats) => {
        this.productsVat = productVats?.map((item) => ({
          ...item,
          value: `${item.value}% VAT`,
        }));
        if (this.product.vatId) {
          this.productForm
            .get('vat')
            .setValue(
              this.productsVat.find((vat) => vat.id === this.product.vatId)
            );
        }
      });

    this.locationId$
      .pipe(
        filter((locationId) => !!locationId),
        switchMap((value) => zip(of(value), this.route.params)),
        filter((data) => data[1]['productsId'] || this.productId),
        switchMap((data) =>
          this.productService.getEntity(
            data[1]['productsId'] || this.productId,
            data[0]
          )
        )
      )
      .subscribe((value) => {
        this.productId = value?.id;
        this.product = value;

        if (value) {
          this.setFormValues();
          this.initialProductName = this.product.name;
          this.initialPrice = this.product.price;
        }
      });

    this.searchCategorySubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy),
        withLatestFrom(this.locationId$),
        filter((value) => !!value?.[0]?.term),
        map((value) => value[0]),
        switchMap((value) => {
          this.categorySelect.loading = true;
          this.categoryRequest.filters.name = value?.term;
          this.categoryCreateName = value?.term;
          return this.categoryService.getEntityList(this.categoryRequest);
        })
      )
      .subscribe((value) => {
        this.categories = value.data;
        if (this.selectCategory && this.categories?.length) {
          const category = this.categories.find(
            (cat) => cat.name === this.categoryCreateName
          );
          if (category) {
            this.productForm.get('category').setValue(category);
          }
          this.selectCategory = false;
          this.categoryCreateName = null;
        }
        this.categorySelect.loading = false;
      });
  }

  changeVat(event) {
    this.product.vatId = event?.id;
  }

  detectChange(event) {
    if (event.name === this.createCategoryOption && !event.id) {
      this.addCategory();
      this.categorySelect.clearModel();
    }
  }

  hasErrors(control: AbstractControl) {
    return control.invalid && (control.dirty || control.touched);
  }

  setFormValues() {
    this.productForm.patchValue({
      name: this.product.name,
      price: this.product.price,
      category: this.product.category,
      barcode: this.product.barcode,
      stock: this.product.stock,
      note: this.product.note,
      vat: this.productsVat?.find((vat) => vat.id === this.product.vatId),
      status: this.product.status === this.statuses[0],
    });
  }

  saveProduct() {
    if (this.productForm?.invalid || this.loading) {
      this.showErrors = true;
      return;
    }

    this.loading = true;
    this.productForm.disable();

    this.product.name = this.productForm.get('name')?.value;
    this.product.price = this.productForm.get('price')?.value;
    this.product.barcode = this.productForm.get('barcode')?.value;
    this.product.stock = this.productForm.get('stock')?.value;

    this.product.note = this.productForm.get('note')?.value;
    this.product.vatPosition = +this.productForm.get('vatPosition')?.value;
    this.product.department = +this.productForm.get('dep')?.value;
    this.product.articleGroup = +this.productForm.get('grArt')?.value;
    this.product.category = this.productForm.get('category')?.value?.id;
    this.product.vatId = this.productForm.get('vat')?.value?.id;
    this.product.status =
      this.productForm.get('status')?.value === true
        ? this.statuses[0]
        : this.statuses[1];

    const apiCall = this.product.id
      ? this.productService.modifyEntity(this.product.id, this.product)
      : this.productService.createEntity(this.product);

    apiCall.subscribe((result) => {
      const error = result instanceof HttpErrorResponse;
      const errorMessage = error
        ? errorsMessages.get(result.error.errorType)
        : '';
      this.store.dispatch(
        saveToasterNotification({
          toasterNotification: error
            ? CreateToasterError(errorMessage)
            : CreateToasterSuccess(
                'The entity was ' +
                  (!!this.productId ? 'modified' : 'created' + ' successfully')
              ),
        })
      );
      this.loading = false;
      this.productForm.enable();
      if (!error) {
        this.closeModal.emit(true);
        this.productForm.reset();
        this.router.navigate(['/gym/products']);
      }
    });
  }

  addProductNew(event) {
    if (event.keyCode === 13) {
      this.saveProduct();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  createCategory(name: string) {
    this.locationId$.pipe().subscribe((value) => {
      this.categoryService
        .createEntity({
          name,
          locationId: value,
        } as ProductCategory)
        .subscribe();
    });
  }

  addCategory() {
    if (this.createCategoryInProgress || !this.categoryCreateName) {
      return;
    }

    this.createCategoryInProgress = true;

    this.locationId$
      .pipe(
        switchMap((locationId) =>
          this.categoryService.createEntity({
            name: this.categoryCreateName,
            locationId: locationId,
            canBeDeleted: false,
          } as ProductCategory)
        )
      )
      .subscribe(() => {
        this.createCategoryInProgress = false;
        this.searchCategorySubject.next({
          term: this.categoryCreateName,
          items: null,
        });
        this.selectCategory = true;
      });
  }

  toggleStatusClick() {
    this.actionCallback.emit({
      actionId: TableActions.DEACTIVATE,
      parameter: this.product.id,
    });
  }

  deleteClick() {
    this.closeModule(false);
    this.actionCallback.emit({
      actionId: TableActions.DELETE,
      parameter: this.product.id,
    });
  }
  openConfirmModal() {
    if (this.initialProductName || this.initialPrice) {
      this.confirmModal = this.modalService.open(this.confirmationModal);
    }
  }
  closeCustomModal() {
    this.confirmModal.close();
  }

  confimEdit() {
    this.product.name = this.productForm.get('name')?.value;
    this.product.price = this.productForm.get('price')?.value;
    this.productForm.patchValue({ price: this.product.price });
    this.productForm.patchValue({ name: this.product.name });
    this.closeCustomModal();
  }
  resetField() {
    if (this.initialProductName || this.initialPrice) {
      this.productForm.patchValue({ price: this.initialPrice });
      this.productForm.patchValue({ name: this.initialProductName });
    }
    this.confirmModal.close();
  }
}
