export interface FilterField {
  fieldName?: string;
  field: string;
  type: FilterType;
  values?: SelectFieldValue[];
  defaultValue?: string;
}

export interface SelectFieldValue {
  label: string;
  value: string;
}

export interface Column {
  field: string;
  header: string;
  link?: boolean;
  parseValue?: any;
  editable?: boolean;
  inputEditableType?:
    | 'text'
    | 'select'
    | 'calendar'
    | 'complex_calendar'
    | 'complex_input';
  selectOptions?: any[];
  decorations?: ColumnDecoration[];
  dateFormat?: string;
  labelColumn?: string;
  actionsDisplayCondition?: boolean;
  conditionToDisplay?: any;
  selectObjectBind?: boolean;
  disableOptionCondition?: any;
  iconPath?: string;
  displayTooltipOnOverflow?: boolean;
}

export enum FilterType {
  TEXT = 'text',
  NUMERIC = 'numeric',
  SELECT = 'select',
  CALENDAR = 'calendar',
  SEARCH_SELECT = 'sselect',
}

export enum ColumnDecoration {
  TIME_BAR = 1,
  ACTIONS_BUTTONS = 2,
  MORE_BUTTON = 3,
  CUSTOM_ICON = 4,
}
