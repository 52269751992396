import { createReducer, on } from '@ngrx/store';
import { PaginatedApiResponse } from '../../models/paginated-api-response';
import * as InvoiceActions from '../actions/invoice.actions';
import { Invoice } from '../../models/invoices/invoice';

export interface InvoiceState {
  invoices: PaginatedApiResponse<Invoice>;
}

export const initialCardsState: InvoiceState = {
  invoices: {
    pageNumber: 1,
    pageSize: 20,
    totalPages: 0,
    totalRecords: 0,
    data: [],
  },
};

export const invoiceReducer = createReducer(
  initialCardsState,
  on(InvoiceActions.storeInvoicesAction, (state, { data }) => ({
    ...state,
    cardOrders: data,
  }))
);
