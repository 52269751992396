<div class="header">
  <div class="main-section d-flex">
    <div class="photo">
      <img
        *ngIf="profilePhotoExists"
        [src]="profilePhoto"
        alt=""
        class="img-profile"
        (error)="onError()"
      />
      <img
        *ngIf="!profilePhotoExists"
        src="../../../../../assets/images/icons/profile-ico.svg"
        class="img-profile"
        alt="profile"
      />
      <div class="black-overlay"></div>
      <img
        src="../../../../../assets/images/profile/take_photo.svg"
        alt="camera"
        class="camera-icon"
        (click)="uploadPhoto()"
      />
      <div
        class="
          img-caption
          d-flex
          flex-row
          align-items-center
          justify-content-between
          cursor-pointer
        "
        (click)="goToProfile()"
        [ngStyle]="{
          'background-color': hasActiveMembership
            ? 'rgba(0, 199, 129, 0.8)'
            : 'rgba(255, 0, 0, 0.7)'
        }"
      >
        <div class="name cursor-pointer">
          {{ checkInResponse.username }}
        </div>
        <div class="text-uppercase profile cursor-pointer">
          view full profile
        </div>
      </div>
    </div>

    <div class="info d-flex flex-column justify-content-between">
      <div>
        <h4>{{ checkInResponse.allEntries }}</h4>
        <span>{{ TranslationEnum.Member.TOTAL_ENTRIES | translate }}</span>
      </div>
      <div>
        <h4>{{ checkInResponse.entriesToday }}</h4>
        <span>{{ TranslationEnum.Member.TODAY_ENTRIES | translate }}</span>
      </div>
      <div>
        <h4>{{ checkInResponse.openOrdersAmount + " RON" }}</h4>
        <span>{{ TranslationEnum.Member.CREDIT | translate }}</span>
      </div>
      <div
        class="cursor-pointer"
        [ngStyle]="{
          'background-color': hasActiveMembership ? '#00C781' : '#ff0000'
        }"
        (click)="goToPos()"
      >
        <h4 class="text-uppercase">
          {{ TranslationEnum.Pos.POS | translate }}
        </h4>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column mt-4">
    <div class="bottom-section d-flex">
      <div class="mr-3" *ngIf="checkInResponse.memberGroup">
        <img src="assets/images/icons/group.svg" alt="icon" class="mr-2" />{{
          checkInResponse.memberGroup
        }}
      </div>
      <div class="mr-3">
        <img
          src="assets/images/icons/member-card.svg"
          alt="icon"
          class="mr-2"
        />
        {{
          TranslationEnum.Member.MEMBER_FROM
            | translate
              : {
                  date: checkInResponse?.dateJoined
                    ? datePipe.transform(
                        checkInResponse?.dateJoined,
                        pipeDateFormat
                      )
                    : "-"
                }
        }}
      </div>
      <div
        class="mr-2 cursor-pointer"
        (click)="openMessaging()"
        *ngIf="checkInResponse?.phone"
      >
        <img src="assets/images/icons/phone.svg" alt="icon" class="mr-2" />
        {{ checkInResponse.phone }}
      </div>
    </div>
    <div class="comment w-100 mt-4">
      <input
        [(ngModel)]="comment"
        (ngModelChange)="updateComment($event)"
        [placeholder]="TranslationEnum.Member.WRITE_COMMENT | translate"
      />
    </div>
  </div>
</div>

<ng-template #webcamModal let-modal>
  <div class="webcam-container">
    <webcam
      [height]="400"
      [width]="500"
      [trigger]="triggerPhoto?.asObservable()"
      (imageCapture)="handleWebcamImage($event)"
    ></webcam>

    <div class="action-zones">
      <button
        [disabled]="inProgress"
        (click)="photoModal?.close()"
        class="btn btn-close"
      >
        {{ TranslationEnum.Common.CLOSE | translate }}
      </button>

      <button
        [disabled]="inProgress"
        (click)="triggerPhoto.next()"
        class="btn btn-action"
      >
        {{ TranslationEnum.Member.TAKE_PHOTO | translate }}
      </button>
    </div>
  </div>
</ng-template>
