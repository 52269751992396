import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ClassBooking } from '../../../../models/class-booking';
import { Observable } from 'rxjs';
import { ClassesService } from '../../../../services/classes.service';
import { map } from 'rxjs/operators';
import { MemberDetailsService } from '../../../../services/member-details.service';
import * as dayjs from 'dayjs';
import { dateFormat, tableHourFormat } from '../../../utilities/constants';
import { DatePipe } from '@angular/common';
import { TranslationEnum } from '../../../../../assets/i18n/translation-enum';
import * as _ from 'lodash-es';
import { Router } from '@angular/router';
import { CalendarViewComponent } from '../../calendar-view/calendar-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-check-in-classes',
  templateUrl: './check-in-classes.component.html',
  styleUrls: ['./check-in-classes.component.scss'],
})
export class CheckInClassesComponent implements OnInit {
  @ViewChild('calendarView', { static: true })
  calendarView: TemplateRef<CalendarViewComponent>;
  @Input() set info(value: {
    locationId: string;
    memberId: string;
    loadClasses: boolean;
  }) {
    this.locationId = value.locationId;
    this.memberId = value.memberId;
    this.loadInfo = value.loadClasses;
    if (!value.loadClasses) {
      return;
    }
    this.loadClasses();
  }

  @Input() memberName: string;

  @Output() refreshData = new EventEmitter();

  loadInfo = false;
  @Input() set futureClasses(classes: ClassBooking[]) {
    this._futureClasses = _.cloneDeep(classes);
    this._futureClasses = this._futureClasses.map((cls) => ({
      ...cls,
      durationLabel: this.memberDetailsService.getTimeDifference(
        cls.date,
        dayjs(cls.date).add(cls.durationMinutes, 'minutes')
      ),
      removable: dayjs(cls.date) > dayjs(),
    }));
  }

  get futureClasses() {
    return this._futureClasses;
  }

  _futureClasses: ClassBooking[];
  memberId: string;
  locationId: string;
  futureClasses$: Observable<ClassBooking[]>;
  pastClasses$: Observable<ClassBooking[]>;
  dateFormat = dateFormat;
  tableHourFormat = tableHourFormat;
  translateEnum = TranslationEnum;
  constructor(
    @Inject('ClassesService')
    private classesService: ClassesService,
    private memberDetailsService: MemberDetailsService,
    public datePipe: DatePipe,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {}

  loadClasses() {
    this.futureClasses$ = this.getFutureClasses();
    this.pastClasses$ = this.getPastClasses();
  }

  getPastClasses() {
    return this.classesService
      .getPastClasses(this.memberId, this.locationId)
      .pipe(
        map((classes: ClassBooking[]) =>
          classes.map((cls) => ({
            ...cls,
            durationLabel: this.memberDetailsService.getTimeDifference(
              cls.date,
              dayjs(cls.date).add(cls.durationMinutes, 'minutes')
            ),
            removable: dayjs(cls.date) > dayjs(),
          }))
        )
      );
  }

  getFutureClasses() {
    return this.classesService
      .getFutureClasses(this.memberId, this.locationId)
      .pipe(
        map((classes: ClassBooking[]) =>
          classes.map((cls) => ({
            ...cls,
            durationLabel: this.memberDetailsService.getTimeDifference(
              cls.date,
              dayjs(cls.date).add(cls.durationMinutes, 'minutes')
            ),
          }))
        )
      );
  }

  removeEntry(bookingId) {
    this.classesService
      .deleteCheckIn(this.memberId, bookingId)
      .subscribe(() => {
        if (!this.loadInfo) {
          this.refreshData.emit();
          return;
        }
        this.pastClasses$ = this.getPastClasses();
        this.futureClasses$ = this.getFutureClasses();
      });
  }

  checkInUser(cls) {
    if (cls.checkedIn) {
      this.removeEntry(cls.bookingId);
      return;
    }
    this.classesService
      .checkInClassOccurrence(this.memberId, cls.occurrenceId)
      .subscribe((result) => {
        if (!this.loadInfo && result) {
          this.refreshData.emit();
          return;
        }
        this.pastClasses$ = this.getPastClasses();
        this.futureClasses$ = this.getFutureClasses();
      });
  }

  book() {
    const modalRef = this.modalService.open(CalendarViewComponent, {
      windowClass: 'calendar-modal',
    });
    modalRef.componentInstance.selectedMemberId = this.memberId;
    modalRef.componentInstance.selectedMemberName = this.memberName;
  }
}
