import { AppState } from '../index';
import { createSelector } from '@ngrx/store';
import { MemberState } from '../reducers/member.reducer';

const selectMemberStateState = (state: AppState) => state.memberState;

export const getMemberDocuments = createSelector(
  selectMemberStateState,
  (state: MemberState) => state?.documents
);

export const getMemberDocumentsUploadedCounter = createSelector(
  selectMemberStateState,
  (state: MemberState) => state?.documents?.length ?? 0
);
