import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  getCurrentGymId,
  getGymFeaturesSelector,
} from '../../../../store/selectors/user.selector';
import { filter, map, takeUntil } from 'rxjs/operators';
import { getDocumentsAction } from '../../../../store/actions/member.actions';
import {
  getMemberDocuments,
  getMemberDocumentsUploadedCounter,
} from '../../../../store/selectors/member.selector';
import { MemberDocument } from '../../../../models/member/member-document';
import { TranslationEnum } from '../../../../../assets/i18n/translation-enum';
import { saveToasterNotification } from '../../../../store/actions/misc.actions';
import {
  CreateToasterError,
  CreateToasterSuccess,
} from '../../../../models/toaster-notification';
import { MemberDetailsService } from '../../../../services/member-details.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { downloadFileAsPdf } from '../../../utilities/utility-functions';

@Component({
  selector: 'app-check-in-docs',
  templateUrl: './check-in-docs.component.html',
  styleUrls: ['./check-in-docs.component.scss'],
})
export class CheckInDocsComponent implements OnInit {
  @Input() memberId: string;
  @Input() memberName: string;
  gymId: string;
  documents;
  translateEnum = TranslationEnum;
  documentsUploadedCounter: number;
  gymFeatures;
  destroy$ = new Subject();

  documentsPermissions: {
    documentsEnabled: boolean;
    uploadDocumentsEnabled: boolean;
    maxDocuments: number;
  };
  constructor(
    private store: Store,
    private memberDetailsService: MemberDetailsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.store
      .select(getGymFeaturesSelector)
      .pipe(takeUntil(this.destroy$))
      .subscribe((gymFeatures: any) => {
        this.documentsPermissions = {
          documentsEnabled: gymFeatures.memberDocumentsEnabled,
          uploadDocumentsEnabled: gymFeatures.memberDocumentsUploadEnabled,
          maxDocuments: gymFeatures.memberDocumentsUploadMaxFiles,
        };
      });

    this.store
      .pipe(select(getMemberDocumentsUploadedCounter), takeUntil(this.destroy$))
      .subscribe(
        (counter: number) => (this.documentsUploadedCounter = counter)
      );

    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(getCurrentGymId),
        filter((gymId: string) => !!gymId),
        map((gymId: string) => {
          this.gymId = gymId;
          this.store.dispatch(
            getDocumentsAction({ gymId: gymId, memberId: this.memberId })
          );
        })
      )
      .subscribe();

    this.store
      .select(getMemberDocuments)
      .pipe(takeUntil(this.destroy$))
      .subscribe((documents: MemberDocument[]) => {
        this.documents = documents?.map((item) => {
          if (item.name) {
            let type = item.name.split('.');

            return {
              ...item,
              type:
                type?.length &&
                ['pdf', 'jpg', 'jpeg', 'png'].includes(type[type.length - 1])
                  ? type[type.length - 1]
                  : '-',
            };
          }

          return item;
        });
      });
  }

  downloadContract() {
    this.memberDetailsService
      .getMemberContract(this.gymId, this.memberId)
      .subscribe((file: Blob) => {
        downloadFileAsPdf(file, `Contract-${this.memberName}`);
      });
  }

  downloadDocument(selectedDocument) {
    let link = document.createElement('a');
    link.download = selectedDocument.name;
    link.href = selectedDocument.url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  uploadMemberDocument(document) {
    const file = document.target?.files[0];
    const formData = new FormData();

    if (file) {
      formData.append('files', file as Blob, file.name);

      this.memberDetailsService
        .uploadDocument(this.gymId, this.memberId, formData)
        .subscribe(
          () => {
            this.store.dispatch(
              saveToasterNotification({
                toasterNotification: CreateToasterSuccess(
                  this.translateService.instant(
                    TranslationEnum.Member.DOCUMENT_UPLOAD_SUCCESS
                  )
                ),
              })
            );

            this.store.dispatch(
              getDocumentsAction({
                gymId: this.gymId,
                memberId: this.memberId,
              })
            );
          },
          () => {
            this.store.dispatch(
              saveToasterNotification({
                toasterNotification: CreateToasterError(
                  this.translateService.instant(
                    TranslationEnum.Member.DOCUMENT_UPLOAD_ERROR
                  )
                ),
              })
            );
          }
        );
    }
  }

  deleteDocument(document) {
    this.memberDetailsService
      .deleteDocument(this.gymId, this.memberId, document.name)
      .subscribe(
        () => {
          this.store.dispatch(
            saveToasterNotification({
              toasterNotification: CreateToasterSuccess(
                this.translateService.instant(
                  TranslationEnum.Member.DOCUMENT_DELETE_SUCCESS
                )
              ),
            })
          );

          this.store.dispatch(
            getDocumentsAction({ gymId: this.gymId, memberId: this.memberId })
          );
        },
        () => {
          this.store.dispatch(
            saveToasterNotification({
              toasterNotification: CreateToasterError(
                this.translateService.instant(
                  TranslationEnum.Member.DOCUMENT_DELETE_ERROR
                )
              ),
            })
          );
        }
      );
  }
}
