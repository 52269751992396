<div class="date-picker position-relative" [class.disabled]="disabled">
  <input
    class="form-control py-2"
    name="dp"
    ngbDatepicker
    #d="ngbDatepicker"
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="1"
    [placeholder]="placeholder"
    [navigation]="navigation"
    [dayTemplate]="t"
    outsideDays="hidden"
    [readonly]="true"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [placement]=""
    type="hidden"
    [startDate]="startDate"
  />
  <ng-template #t let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date?.day || placeholder }}
    </span>
  </ng-template>

  <div (click)="d.toggle()">
    <img src="assets/images/icons/calendar.svg" alt="" class="icon-calendar" />

    <input
      type="text"
      class="form-control py-2"
      [placeholder]="placeholder"
      [readOnly]="true"
      [value]="dateFormat()"
    />
  </div>
</div>
