import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './shared/guards/authenticated.guard';
import { PermissionsGuard } from './shared/guards/permissions.guard';
import { Permission } from './models/user/permission';
import { GymPermission } from './models/gym-permission';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'gym',
    pathMatch: 'full',
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./containers/account/account.module').then(
        (m) => m.AccountModule
      ),
  },
  {
    path: 'gym',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./containers/gym/gym.module').then((m) => m.GymModule),
  },
  {
    path: 'admin-gyms',
    canActivate: [AuthenticatedGuard, PermissionsGuard],
    data: {
      andPermissions: [Permission.MANAGE_APP, Permission.IMPERSONATION],
    },
    loadChildren: () =>
      import('./containers/topgym-admin-home/topgym-admin-home.module').then(
        (m) => m.TopGymAdminHomeModule
      ),
  },
  {
    path: 'settings',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./containers/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'order-card',
    canActivate: [AuthenticatedGuard, PermissionsGuard],
    data: {
      masterPermissions: [
        Permission.MANAGE_ORDERS_PROVIDER,
        Permission.MANAGE_APP,
      ],
      gymPermission: GymPermission.OrderCards,
      orPermissions: [Permission.MANAGE_CARD_ORDERS],
    },
    loadChildren: () =>
      import('./containers/cards/cards.module').then((m) => m.CardsModule),
  },
  {
    path: 'invoices',
    canActivate: [AuthenticatedGuard, PermissionsGuard],
    data: {
      masterPermissions: [Permission.MANAGE_INVOICES, Permission.MANAGE_GYM],
      gymPermission: GymPermission.OrderCards,
    },
    loadChildren: () =>
      import('./containers/invoices/invoice.module').then(
        (m) => m.InvoiceModule
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import('./containers/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
