import { MemberSetting } from './member-logs/member-setting';

export interface Members {
  name: string;
  locationId: string;
  membershipName: string;
  phoneNumber: string;
  email: string;
  status: MemberStatus;
  hasPicture: boolean;
  address: string;
  birthdate: string;
  sex: MemberGender;
  id: string;
  groupId: string;
  entriesToday: number;
  allEntries: string;
  totalSpent: number;
  openOrdersCount: number;
  openOrdersAmount: number;
  dateJoined: string;
  lastVisit: string;
  lastYearChecks: any[];
  freeText: string;
  lockerKey: string;
  documentId: string;
  emergencyPhone: string;
  emergencyName: string;
  internalId: string;
  customCardCode: string;
  barcode: string;
  settings: MemberSetting[];
}

export enum MemberStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum MemberGender {
  Female = 'F',
  Male = 'M',
}
