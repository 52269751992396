import { Inject, Injectable } from '@angular/core';
import { ConfigModel } from '../models/config-model';
import { APP_CONFIG } from '../shared/utilities/constants';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: ConfigModel;

  constructor(@Inject(APP_CONFIG) private readonly appConfig: ConfigModel) {
    this.config = appConfig;
  }
}
