import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[inputRestriction]',
})
export class RestrictInputDirective {
  inputElement: ElementRef;

  @Input('inputRestriction') inputRestriction: RestrictInputType;
  arabicRegex = '[\u0600-\u06FF]';

  constructor(el: ElementRef) {
    this.inputElement = el;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    switch (this.inputRestriction) {
      case RestrictInputType.DIGITS_ONLY:
        RestrictInputDirective.digitsOnly(event);
        break;
      case RestrictInputType.LETTERS_ONLY:
        this.lettersOnly(event);
        break;
      case RestrictInputType.NO_SPECIAL_CHARS:
        this.noSpecialChars(event);
    }
  }

  private static digitsOnly(e) {
    if (
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true)
    ) {
      // let it happen, don't do anything
      return;
    }
    if (
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1
    ) {
      e.preventDefault();
    }
  }

  private lettersOnly(event) {
    this.noSpecialChars(event);
    if (
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(event.key) !==
      -1
    ) {
      event.preventDefault();
    }
  }

  private noSpecialChars(event) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode;
    if (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      k === 32 ||
      (k >= 48 && k <= 57)
    ) {
      return;
    }
    const ch = String.fromCharCode(+e.code);
    const regEx = new RegExp(this.arabicRegex);
    if (regEx.test(ch)) {
      return;
    }
    e.preventDefault();
  }
}

export enum RestrictInputType {
  NO_SPECIAL_CHARS,
  DIGITS_ONLY,
  LETTERS_ONLY,
}
