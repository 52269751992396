import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Classes } from '../models/classes';
import { ListRequest } from '../models/requests/list-request';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { HttpParams } from '@angular/common/http';

export interface CalendarFilters {
  startDate?: string;
  endDate?: string;
  locationId: string;
}

export interface RoomPayload {
  name: string;
  zone: {
    zoneId: string;
    zoneName: string;
    isNew: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ClassesService {
  constructor(private http: HttpService) {}

  getClasses(request: ListRequest): Observable<PaginatedApiResponse<Classes>> {
    return this.http
      .post('class/list', request)
      .pipe(catchError(() => of(null)));
  }

  getClass(
    gymId: string,
    locationId: string,
    classId?: string,
    roomId?: string,
    trainerId?: string,
    selectedHour?: number,
    durationMinutes?: number
  ): Observable<any> {
    return this.http
      .post(`class/check`, {
        locationId,
        gymId,
        roomId,
        trainerId,
        selectedHour,
        durationMinutes,
        classId,
      })

      .pipe(catchError(() => of(false)));
  }

  createClass(classPayload: Classes, locationId: string): Observable<any> {
    return this.http
      .post(`class/add/${locationId}`, classPayload)
      .pipe(catchError((err) => of(err)));
  }

  modifyClass(
    classPayload: Classes,
    locationId: string,
    classId: string
  ): Observable<any> {
    return this.http
      .put(`class/add/${locationId}/${classId}`, classPayload)
      .pipe(catchError((err) => of(err)));
  }

  deleteClass(classId: string): Observable<any> {
    return this.http
      .delete(`class/delete/${classId}`)
      .pipe(catchError(() => of(false)));
  }

  getClassesCalendar({
    startDate,
    endDate,
    locationId,
  }: CalendarFilters): Observable<any> {
    const options = { params: new HttpParams() };

    if (startDate) {
      options.params = options.params.set('startDate', startDate);
    }
    if (endDate) {
      options.params = options.params.set('endDate', endDate);
    }
    return this.http
      .get(`class/calendar/${locationId}`, options)
      .pipe(catchError(() => of(false)));
  }

  getRoom(locationId: string, roomId?: string): Observable<any> {
    return this.http
      .get(`class/room/add/${locationId}` + (roomId ? `?roomId=${roomId}` : ``))
      .pipe(catchError(() => of(false)));
  }

  createRoom(roomPayload: RoomPayload, locationId: string): Observable<any> {
    return this.http
      .post(`class/room/add/${locationId}`, roomPayload)
      .pipe(catchError((err) => of(err)));
  }

  modifyRoom(
    roomPayload: Classes,
    locationId: string,
    roomId: string
  ): Observable<any> {
    return this.http
      .put(`class/room/add/${locationId}/${roomId}`, roomPayload)
      .pipe(catchError((err) => of(err)));
  }

  deleteRoom(roomId: string): Observable<any> {
    return this.http
      .delete(`class/room/delete/${roomId}`)
      .pipe(catchError((err) => of(err)));
  }

  getCalendarOccurrence(occurrenceId: string): Observable<any> {
    return this.http
      .get(`class/calendar/occurrence/${occurrenceId}`)
      .pipe(catchError((err) => of(err)));
  }

  bookMemberClassOccurrence(
    memberId: string,
    classOccurrenceId: string
  ): Observable<any> {
    return this.http
      .post(`memberfeed/bookclass/${memberId}/${classOccurrenceId}`, {})
      .pipe(catchError((err) => of(err)));
  }

  deleteClassOccurrence(classBookingId: string): Observable<any> {
    return this.http
      .delete(`memberfeed/cancelbooking/${classBookingId}`)
      .pipe(catchError((err) => of(err)));
  }

  checkInClassOccurrence(
    memberId: string,
    classOccurrenceId: string
  ): Observable<any> {
    return this.http
      .post(`memberfeed/checkin/${memberId}/${classOccurrenceId}`, {})
      .pipe(catchError((err) => of(err)));
  }

  getFutureClasses(memberId: string, locationId: string) {
    return this.http
      .get(`memberfeed/future/${memberId}/${locationId}`)
      .pipe(catchError((err) => of(err)));
  }

  getPastClasses(memberId: string, locationId: string) {
    return this.http
      .get(`memberfeed/past/${memberId}/${locationId}`)
      .pipe(catchError((err) => of(err)));
  }

  deleteCheckIn(memberId: string, bookingId: string) {
    return this.http
      .post(`memberfeed/remove-checkin`, { memberId, bookingId })
      .pipe(catchError((err) => of(err)));
  }
}
