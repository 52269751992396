import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  appInit,
  logoutAction,
  saveToasterNotification,
} from './store/actions/misc.actions';
import { AppState } from './store';
import { combineLatest, Observable } from 'rxjs';
import {
  getCurrentGymCustomCardLength,
  getDefaultLocationId,
  getGymFeaturesSelector,
  getGymName,
  getGymPermissionsSelector,
  getProfile,
  getUsername,
  isImpersonated,
} from './store/selectors/user.selector';
import { MenuEntry } from './models/misc/menu-entry';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Profile } from './models/user/profile';
import { Permission } from './models/user/permission';
import { appAdminMenu, gymMenu, vendorMenu } from './models/misc/menu-values';
import {
  CreateToasterError,
  ToasterNotification,
} from './models/toaster-notification';
import { MemberDetailsService } from './services/member-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeCheck } from './models/barcode-check';
import 'dayjs/locale/ro';
import * as dayjs from 'dayjs';
import { CheckInResponse } from './models/check-in-response';
import { GymService } from './services/gym.service';
import { LoginResponse } from './models/account/login-response';
import { AccountService } from './services/account.service';
import { TranslateService } from '@ngx-translate/core';
import { GymFeaturesI } from './models/gym-features';
import { TranslationEnum } from '../assets/i18n/translation-enum';
import { CognitoService } from './services/cognito.service';
import { MemberMembership } from './models/member-membership';

dayjs.locale('ro');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'TopGymFE';
  profile$: Observable<Profile>;
  menuEntries$: Observable<MenuEntry[]>;
  username$: Observable<string>;
  showNotification = false;
  notification$: Observable<ToasterNotification>;
  locationId: string;
  showCheckIn = false;
  memberships: MemberMembership[];
  checkInResponse: CheckInResponse;
  gymName$: Observable<string>;
  gymCustomCardLength?: number;
  startBarcode = null;
  barcode = '';
  barCodeLastChar = ''; // 2nd reader type
  barcodeLength = 13;
  barcodeCheck = BarcodeCheck;
  impersonated$: Observable<boolean>;
  isGym$: Observable<boolean>;
  modalMemberId: string;
  gymFeatures: GymFeaturesI;
  isRefreshInProgress: boolean;
  TranslationEnum = TranslationEnum;
  availableLanguages = [
    { name: 'English', code: 'en', flag: 'gb' },
    { name: 'Română', code: 'ro', flag: 'ro' },
  ];
  selectedLanguage: { name: string; code: string; flag: string };
  langStorageKey = 'pref_lang';

  footerMenu = [
    {
      label: 'FAQ',
      link: '',
    },
    {
      label: 'Termeni si conditii',
      link: '',
    },
    {
      label: 'Politica de confidentialitate',
      link: '',
    },
    {
      label: 'ANSPC',
      link: '',
    },
  ];

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (
      (event.key === '?' ||
        (event.key === '/' && this.barCodeLastChar === 'Shift')) &&
      (this.startBarcode === '%^' || this.startBarcode === '%') &&
      this.barcode.length === this.barcodeLength
    ) {
      this.processBarcode();
    } else if (
      this.gymCustomCardLength &&
      event.key === 'Enter' &&
      this.barcode.length >= this.gymCustomCardLength
    ) {
      this.barcode = this.barcode.substring(
        this.barcode.length - this.gymCustomCardLength
      );
      this.processBarcode();
    }
    this.readBarcode(event);
    if (event.key === 'Escape' && this.showCheckIn) {
      this.showCheckIn = false;
    }
  }

  constructor(
    private store: Store<AppState>,
    private memberDetailsService: MemberDetailsService,
    private router: Router,
    private route: ActivatedRoute,
    private gymService: GymService,
    private accountService: AccountService,
    private cognitoService: CognitoService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('ro');
    this.changeLanguage(localStorage.getItem(this.langStorageKey) || 'ro');
  }

  changeLanguage(code: string) {
    this.selectedLanguage = this.availableLanguages.find((e) => e.code == code);
    this.translate.use(code);
    localStorage.setItem(this.langStorageKey, code);
  }

  readCustomBarCode(event: KeyboardEvent) {
    this.barcode += event.key;
    if (this.barcode.length > this.gymCustomCardLength) {
      this.barcode = this.barcode.substr(
        this.barcode.length - this.gymCustomCardLength
      );
    }
    console.log(this.barcode);
  }

  readBarcode(event: KeyboardEvent) {
    // We have 2 reader types that input special characters differently.
    // Reader type 1 inputs % key as %. Reader type 2 inputs % key as Shift and 5.

    if (
      event.key === '%' ||
      (event.key === '5' && this.barCodeLastChar === 'Shift')
    ) {
      this.startBarcode = '%';
    } else if (
      (event.key === '^' ||
        (event.key === '6' && this.barCodeLastChar === 'Shift')) &&
      this.startBarcode === '%'
    ) {
      this.startBarcode = '%^';
      this.barcode = '';
    } else if (
      !!event.key &&
      !isNaN(+event.key) &&
      +event.key >= 0 &&
      +event.key <= 9
    ) {
      this.barcode += event.key;
    }

    this.barCodeLastChar = event.key;
  }

  processBarcode() {
    if (
      !this.barcode ||
      (this.barcode.length !== this.gymCustomCardLength &&
        this.barcode.length > this.barcodeLength)
    ) {
      return;
    }
    this.showCheckIn = false;
    if (this.router.url.includes('pos')) {
      this.router.navigate(['/gym/pos/'], {
        queryParams: { barcode: this.barcode },
        queryParamsHandling: 'merge',
      });
      this.startBarcode = null;
      this.barcode = '';
      return;
    } else if (this.router.url.includes('?changeCard')) {
      this.router.navigate(
        [this.router.routerState.snapshot.url.split('?')[0]],
        {
          queryParams: { barcode: this.barcode },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        }
      );

      this.startBarcode = null;
      this.barcode = '';
      return;
    }

    this.startBarcode = null;
    const barcode = this.barcode;
    this.memberDetailsService
      .checkInVerify({
        barcode: this.barcode,
        locationId: this.locationId,
        membershipId: null,
        memberId: null,
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
      })
      .subscribe((result: CheckInResponse) => {
        if (!result) {
          this.memberDetailsService.updateData.next(
            MemberDetailsService.invalidString
          );
          this.showToasterError();
          return;
        }

        if (result.status === BarcodeCheck.EMPTY_BARCODE) {
          this.router.navigate(['/gym/members/'], {
            queryParams: { barcode },
            queryParamsHandling: 'merge',
          });
          return;
        }

        if (!result.memberId || !result.username) {
          this.showToasterError('Card invalid');
          return;
        }

        if (
          result?.status === BarcodeCheck.MULTIPLE_ACTIVE ||
          result?.status === BarcodeCheck.NO_MEMBERSHIP ||
          result?.status === BarcodeCheck.CHECKED_IN ||
          result?.status === BarcodeCheck.CHECKED_OUT ||
          result?.status === BarcodeCheck.OUTSIDE_HOURS ||
          result?.status === BarcodeCheck.SKIPPED
        ) {
          this.router.navigate([], { queryParams: { closeModal: true } });

          if (this.gymFeatures.showMemberModal) {
            this.showCheckIn = true;
            this.checkInResponse = result;
            this.modalMemberId = result.memberId;
            this.memberDetailsService.updateData.next(this.modalMemberId);
          } else {
            this.router.navigate(['/gym/members/' + result.memberId], {});
          }
        }
      });
    this.barcode = '';
  }

  showToasterError(message = 'An error occurred. Please try again') {
    this.store.dispatch(
      saveToasterNotification({
        toasterNotification: CreateToasterError(message),
      })
    );
  }

  ngOnInit(): void {
    this.store.dispatch(appInit());
    this.cognitoService.checkUserSession();
    this.cognitoService.setAccessTokenRefreshInterval();
    this.profile$ = this.store.select(getProfile);
    this.impersonated$ = this.store.select(isImpersonated);
    this.menuEntries$ = combineLatest([
      this.store.select(getGymPermissionsSelector),
      this.profile$.pipe(
        filter((profile) => !!profile),
        map((profile) => profile.permissions)
      ),
    ]).pipe(
      map(([gymPerm, perm]) => {
        if (perm?.includes(Permission.MANAGE_APP)) {
          return appAdminMenu();
        } else if (perm?.includes(Permission.MANAGE_ORDERS_PROVIDER)) {
          return vendorMenu();
        }

        const menu = gymMenu();
        menu.forEach((item) => {
          item.visible =
            (!item?.gymPerm || gymPerm.includes(item.gymPerm)) &&
            (!item.userPerm ||
              item.userPerm.filter((permission) => perm.includes(permission))
                .length >= 1);
        });
        return menu?.filter((item) => item.visible);
      }),
      shareReplay(1)
    );

    this.isGym$ = this.profile$.pipe(
      map(
        (value) =>
          !value?.permissions?.includes(Permission.MANAGE_APP) &&
          !value?.permissions?.includes(Permission.MANAGE_ORDERS_PROVIDER)
      )
    );
    this.username$ = this.store.select(getUsername);
    this.gymName$ = this.store.select(getGymName);
    this.store
      .select(getDefaultLocationId)
      .subscribe((id) => (this.locationId = id));

    this.route.queryParams.subscribe((params) => {
      if (params['closeModal']) {
        this.router.navigate([], { queryParams: {} });
      }

      this.isRefreshInProgress = params['refreshInProgress'];
    });

    this.store
      .select(getGymFeaturesSelector)
      .subscribe((data: GymFeaturesI) => {
        this.gymFeatures = data;
      });

    this.store
      .select(getCurrentGymCustomCardLength)
      .subscribe((data) => (this.gymCustomCardLength = data));
  }

  exitImpersonation() {
    this.gymService.exitImpersonation().subscribe((response: LoginResponse) => {
      this.accountService.processLoginResponse(response);
    });
  }

  changeModalState() {
    this.showCheckIn = !this.showCheckIn;
    if (this.showCheckIn) {
      this.memberDetailsService.updateData.next(this.modalMemberId);
    }
  }

  logout(): void {
    this.store.dispatch(logoutAction());
  }
}
