import { createReducer, on } from '@ngrx/store';
import * as MiscActions from '../actions/misc.actions';
import { ToasterNotification } from '../../models/toaster-notification';
import { setAuthTokenAction } from '../actions/misc.actions';

export interface MiscState {
  toasterNotification: ToasterNotification;
  accessToken: string;
}

export const initialMiscState: MiscState = {
  toasterNotification: undefined,
  accessToken: undefined,
};

export const miscReducer = createReducer(
  initialMiscState,
  on(MiscActions.saveToasterNotification, (state, { toasterNotification }) => ({
    ...state,
    toasterNotification,
  })),
  on(MiscActions.setAuthTokenAction, (state, { accessToken }) => ({
    ...state,
    accessToken: accessToken,
  }))
);
