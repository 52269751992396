import { TranslationEnum } from '../../../assets/i18n/translation-enum';

export enum PeriodFilterAction {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'week',
  LAST_WEEK = 'lastWeek',
  THIS_MONTH = 'month',
  LAST_MONTH = 'lastMonth',
  LAST2_MONTHS = 'last2Months',
  CUSTOM = 'custom',
}

export interface PeriodFilter {
  id: number;
  label: string;
  action: PeriodFilterAction;
}

export const PeriodFilters: PeriodFilter[] = [
  {
    id: 0,
    label: TranslationEnum.Common.TODAY,
    action: PeriodFilterAction.TODAY,
  },
  {
    id: 1,
    label: TranslationEnum.Common.YESTERDAY,
    action: PeriodFilterAction.YESTERDAY,
  },
  {
    id: 2,
    label: TranslationEnum.Common.THIS_WEEK,
    action: PeriodFilterAction.THIS_WEEK,
  },
  {
    id: 3,
    label: TranslationEnum.Common.LAST_WEEK,
    action: PeriodFilterAction.LAST_WEEK,
  },
  {
    id: 4,
    label: TranslationEnum.Common.THIS_MONTH,
    action: PeriodFilterAction.THIS_MONTH,
  },
  {
    id: 5,
    label: TranslationEnum.Common.LAST_MONTH,
    action: PeriodFilterAction.LAST_MONTH,
  },
  {
    id: 6,
    label: TranslationEnum.Common.LAST_TWO_MONTHS,
    action: PeriodFilterAction.LAST2_MONTHS,
  },
  {
    id: 7,
    label: TranslationEnum.Common.CUSTOM_RANGE,
    action: PeriodFilterAction.CUSTOM,
  },
];
