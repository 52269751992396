export interface ToasterNotification {
  type: ToasterNotificationType;
  message: string;
}

export enum ToasterNotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
}

export enum ErrorType {
  EMAIL_EXISTS = 'EmailAlreadyExists',
  BARCODE_EXISTS = 'BarcodeAlreadyExists',
  MUST_CHECKOUT_FIRST = 'Must check-out first',
  CARd_ASSIGNED = 'CardAlreadyAssigned',
  BARCODE_INVALID = 'BarcodeNotValid',
}

export const errorsMessages = new Map([
  [ErrorType.EMAIL_EXISTS, 'This email already exists'],
  [ErrorType.BARCODE_EXISTS, 'This barcode already exists'],
  [
    ErrorType.MUST_CHECKOUT_FIRST,
    'In order to delete the membership, you must check-out first',
  ],
  [ErrorType.CARd_ASSIGNED, 'Cardul este deja asignat'],
  [ErrorType.BARCODE_INVALID, 'Cardul nu este valid'],
]);

export function CreateToasterError(message = 'There was an error.') {
  return {
    type: ToasterNotificationType.DANGER,
    message: message || 'Error',
  } as ToasterNotification;
}

export function CreateToasterSuccess(message: string = null) {
  return {
    type: ToasterNotificationType.SUCCESS,
    message: message || 'Success',
  } as ToasterNotification;
}
