<input
  *ngIf="displayRangeOnly; else displayAsDropdown"
  id="date-range-input"
  placeholder="{{
    !!startDate && !!endDate
      ? startDate + ' - ' + endDate
      : (TranslationEnum.Common.SELECT_PERIOD | translate)
  }}"
  #calendarPopover="ngbPopover"
  (click)="onOpenRangePicker(calendarPopover)"
  [ngbPopover]="rangeCalendar"
  placement="bottom-start"
  triggers="manual"
  autoClose="outside"
/>

<ng-template #displayAsDropdown>
  <ng-select
    [items]="periodFilters"
    bindLabel="label"
    [searchable]="false"
    [clearable]="clearable"
    placeholder="{{
      selectedOption
        ? ''
        : !!startDate && !!endDate
        ? startDate + ' - ' + endDate
        : (TranslationEnum.Common.SELECT_PERIOD | translate)
    }}"
    [ngModel]="selectedOption"
    (ngModelChange)="onSelectPeriod($event)"
    class="p-0"
  >
    <ng-template ng-label-tmp let-item="item">
      <span>{{ item.label | translate }}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <span>{{ item.label | translate }}</span>
    </ng-template>
  </ng-select>
  <ng-template
    *ngIf="displayCalendar"
    [ngTemplateOutlet]="rangeCalendar"
  ></ng-template>
</ng-template>

<ng-template #rangeCalendar>
  <div class="range-picker">
    <ngb-datepicker
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="1"
      [dayTemplate]="t"
      outsideDays="hidden"
      [maxDate]="!displayRangeOnly ? maxDateStruct : null"
    >
    </ngb-datepicker>
    <ngb-datepicker
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="1"
      [dayTemplate]="t"
      outsideDays="hidden"
      [maxDate]="!displayRangeOnly ? maxDateStruct : null"
    >
    </ngb-datepicker>

    <ng-template #t let-date let-focused="focused">
      <span
        class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
    </ng-template>
  </div>
</ng-template>
