import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import { Profile } from '../../models/user/profile';
import { UserGym } from '../../models/user/user-gym';

export interface UserState {
  profile: Profile;
  gym: UserGym;
  impersonated: boolean;
  isRefreshInProgress: boolean;
}

export const initialUserState: UserState = {
  profile: undefined,
  gym: undefined,
  impersonated: undefined,
  isRefreshInProgress: undefined,
};

export const userReducer = createReducer(
  initialUserState,
  on(UserActions.storeProfileAction, (state, { profile }) => ({
    ...state,
    profile,
  })),
  on(UserActions.storeMainGymAction, (state, { gym }) => ({
    ...state,
    gym,
  })),
  on(UserActions.markImpersonated, (state, { impersonated }) => ({
    ...state,
    impersonated,
  })),
  on(UserActions.setRefreshInProgress, (state, { inProgress }) => ({
    ...state,
    isRefreshInProgress: inProgress,
  })),
  on(
    UserActions.updatePosOrderPreferences,
    (state, { memberships, products }) => ({
      ...state,
      profile: {
        ...state.profile,
        membershipOrderPreference: memberships,
        productOrderPreference: products,
      },
    })
  )
);
