import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getProfile } from '../../store/selectors/user.selector';
import { filter, map } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private accountService: AccountService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.pipe(
      select(getProfile),
      filter((user) => user !== undefined),
      map((user) => {
        if (user) {
          return true;
        }

        this.accountService.initiateAuth();
        return false;
      })
    );
  }
}
