import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { LoginResponse } from '../models/account/login-response';
import { LoginPayload } from '../models/account/login';
import { ServerErrors } from '../models/errors/server-errors';
import { Profile } from '../models/user/profile';
import { AppState } from '../store';
import { select, Store } from '@ngrx/store';
import {
  getProfileAction,
  getProfileRedirectAction,
  markImpersonated,
} from '../store/actions/user.actions';
import { HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
import { UserGym } from '../models/user/user-gym';
import { getAuthToken } from '../store/selectors/user.selector';
import { logoutAction } from '../store/actions/misc.actions';
import { ConfigService } from './config.service';
import { ChangeProfileDto } from '../containers/profile/models/change-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  accessToken: string;

  constructor(
    private http: HttpService,
    private store: Store<AppState>,
    private configService: ConfigService
  ) {
    this.store
      .pipe(
        select(getAuthToken),
        filter((accessToken) => accessToken !== undefined)
      )
      .subscribe((accessToken) => {
        if (accessToken) {
          this.accessToken = accessToken;
          this.store.dispatch(getProfileAction());
        } else {
          this.store.dispatch(logoutAction());
        }
      });
  }

  initiateAuth() {
    window.location.assign(
      `https://${this.configService.config.cognitoDomain}/login?client_id=${
        this.configService.config.cognitoClientId
      }&response_type=code&scope=email+openid+aws.cognito.signin.user.admin&redirect_uri=${encodeURIComponent(
        window.location.origin + '/account/authenticated'
      )}`
    );
  }

  getAuthorizationToken() {
    return this.accessToken;
  }

  getProfile(): Observable<Profile> {
    return this.http.get(`account/profile`).pipe(catchError(() => of(null)));
  }

  getProfileGym(gymId: string): Observable<UserGym> {
    return this.http
      .get(`account/gym/${gymId}`)
      .pipe(catchError(() => of(null)));
  }

  login(loginInformation: LoginPayload): Observable<ServerErrors | string> {
    const headers = new HttpHeaders().set(
      'Authorization',
      'Basic ' +
        btoa(loginInformation.username + ':' + loginInformation.password)
    );
    return this.http.post(`auth/login`, null, { headers: headers }).pipe(
      map((loginResponse: LoginResponse) => {
        if (!loginResponse) {
          return ServerErrors.GENERAL_ERROR;
        }
        this.processLoginResponse(loginResponse);
        return ServerErrors.NONE;
      }),
      catchError((response) => of(response?.error?.message))
    );
  }

  submitResetPasswordRequest(resetInfo: any): Observable<any> {
    return this.http
      .post(`auth/request-password-reset`, resetInfo)
      .pipe(catchError(() => of(ServerErrors.GENERAL_ERROR)));
  }

  confirmResetPassword(resetInfo: any): Observable<boolean> {
    return this.http.post(`auth/confirm-password-reset`, resetInfo).pipe(
      map((response) => !!response),
      catchError(() => of(false))
    );
  }

  resetPassword(resetInfo: any): Observable<boolean> {
    return this.http.post(`auth/password-reset`, resetInfo).pipe(
      map((response) => !!response),
      catchError(() => of(false))
    );
  }

  verifyEmail(userId: string, token: string) {
    return this.http
      .get(`auth/verify-email/${userId}/${token}`)
      .pipe(catchError((e) => of(e)));
  }

  changeProfileDetails(profileDto: ChangeProfileDto) {
    return this.http.put('account/profile', profileDto).pipe(
      map((data) => !!data?.id),
      catchError((error) => of(error))
    );
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http
      .post('auth/change-password', { oldPassword, newPassword })
      .pipe(catchError((error) => of(error)));
  }

  uploadUserPhoto(formData: FormData): Observable<any> {
    return this.http.post(`account/photo`, formData);
  }

  deleteUserPhoto(userId: string) {
    return this.http.delete(`account/photo/${userId}`, null);
  }

  public processLoginResponse(loginResponse: LoginResponse): void {
    if (loginResponse.impersonated) {
      this.store.dispatch(markImpersonated({ impersonated: true }));
    }
    this.store.dispatch(getProfileRedirectAction());
  }
}
