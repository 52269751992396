import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
  CalendarFilters,
  ClassesService,
} from 'src/app/services/classes.service';
import { filter, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import {
  getDefaultLocationId,
  getPermissionsSelector,
} from 'src/app/store/selectors/user.selector';
import { TranslationEnum } from 'src/assets/i18n/translation-enum';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { format } from 'date-fns';
import { Permission } from 'src/app/models/user/permission';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import {
  dateFormat,
  dateFormatHour,
  tableHourFormat,
} from '../../utilities/constants';
import { ServerErrors } from '../../../models/errors/server-errors';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
})
export class CalendarViewComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('confirmationModal') confirmationModal: TemplateRef<any>;
  calendarApi: any;

  calendarOptions: CalendarOptions = {
    timeZone: 'local',
    eventClick: this.handleEventClick.bind(this),
    datesSet: this.handleDatesSet.bind(this),
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, timeGridPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    events: [],
  };
  dateFormat = dateFormat;
  tableHourFormat = tableHourFormat;

  requestPayload: CalendarFilters = {
    locationId: null,
  };
  locationId$: Observable<string>;
  TranslationEnum = TranslationEnum;
  trainers = [];
  selectedTrainer = null;
  rooms = [];
  selectedRoom = null;
  classItems = [];
  showClassDetails = false;
  class: any = {};
  permisions$: Observable<any>;
  hasAddPermission = false;
  selectedMemberId: string;
  selectedMemberName: string;
  confirmModal = null;
  translateEnum = TranslationEnum;
  bookingInProgress = false;
  bookingSuccess = false;
  bookingEnded = false;
  serverErrors = ServerErrors;
  bookingError;

  constructor(
    @Inject('ClassesService')
    private classesService: ClassesService,
    private store: Store,
    private modalService: NgbModal,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (history.state.selectedMemberId && history.state.selectedMemberName) {
      this.selectedMemberId = history.state.memberId;
      this.selectedMemberName = history.state.memberName;
    }

    this.locationId$ = this.store.select(getDefaultLocationId);
    this.permisions$ = this.store.select(getPermissionsSelector);
    this.permisions$.pipe(takeUntil(this.destroy)).subscribe((response) => {
      if (response.includes(Permission.MANAGE_CLASSES)) {
        this.hasAddPermission = true;
      }
    });
  }

  ngAfterViewInit() {
    this.calendarApi = this.calendarComponent.getApi();
    this.requestPayload.startDate = new Date(
      this.calendarApi.view.activeStart
    ).toISOString();
    this.requestPayload.endDate = new Date(
      this.calendarApi.view.activeEnd
    ).toISOString();
    this.loadClasses(this.requestPayload);
  }

  handleEventClick(e) {
    if (!this.hasAddPermission) {
      return;
    }
    this.class = this.classItems.find((c) => c.occurrenceId === e?.event?.id);
    if (this.selectedMemberId) {
      this.openConfirmModal();
    } else {
      this.showClassDetails = true;
    }
  }

  handleDatesSet(event) {
    this.requestPayload.startDate = new Date(event.start).toISOString();
    this.requestPayload.endDate = new Date(event.end).toISOString();

    this.loadClasses(this.requestPayload);
  }

  loadClasses(requestPayload: CalendarFilters) {
    this.locationId$
      .pipe(
        takeUntil(this.destroy),
        filter((value) => !!value),
        switchMap((locationId: string) =>
          this.callApi({ ...requestPayload, locationId })
        )
      )
      .subscribe((response) => {
        this.setFilters(response);
        this.classItems = response;
        this.applyFilters();
      });
  }

  callApi(requestPayload: CalendarFilters) {
    if (!requestPayload) {
      return;
    }

    return this.classesService
      .getClassesCalendar(requestPayload)
      .pipe(shareReplay(1));
  }

  setFilters(response) {
    this.trainers = [];
    this.rooms = [];
    response?.forEach((event) => {
      if (!this.trainers.some((t) => t === event.trainer)) {
        this.trainers.push(event.trainer);
      }
      if (!this.rooms.some((r) => r === event.room)) {
        this.rooms.push(event.room);
      }
    });
  }

  selectTrainer(item) {
    this.selectedTrainer = item;
    this.applyFilters();
  }

  selectRoom(item) {
    this.selectedRoom = item;
    this.applyFilters();
  }

  applyFilters() {
    this.setCalendarEvents(
      this.classItems.filter((e) => {
        const trainerValid = this.selectedTrainer
          ? e.trainer === this.selectedTrainer
          : true;

        const roomValid = this.selectedRoom
          ? e.room === this.selectedRoom
          : true;

        return trainerValid && roomValid;
      })
    );
  }

  setCalendarEvents(items) {
    this.calendarOptions.events = items.map((item) => {
      const start = new Date(item.date);
      const end = new Date(start.getTime() + item.durationMinutes * 60000);
      return {
        title: item.className,
        start: start,
        end: end,
        id: item.occurrenceId,
      };
    });
  }

  dateToTime(event) {
    const eventItem = this.classItems.find((e) => e.occurrenceId === event.id);
    const start = new Date(event.start);
    const end = new Date(
      start.getTime() + (eventItem?.durationMinutes || 0) * 60000
    );
    const startTime = format(start, 'p');
    const endTime = format(end, 'p');
    return `${startTime} - ${endTime}`;
  }

  closeItem() {
    this.class = {};
    this.showClassDetails = false;
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  }

  openConfirmModal() {
    this.confirmModal = this.modalService.open(this.confirmationModal);
  }
  closeCustomModal() {
    this.confirmModal.close();
  }

  bookMember() {
    this.bookingInProgress = true;
    this.classesService
      .bookMemberClassOccurrence(this.selectedMemberId, this.class.occurrenceId)
      .pipe()
      .subscribe((result) => {
        this.bookingInProgress = false;
        this.bookingEnded = true;
        if (result instanceof HttpErrorResponse) {
          this.bookingSuccess = false;
          this.bookingError = result.error;
        } else {
          this.bookingSuccess = true;
        }
        setTimeout(() => {
          this.confirmModal.close();
          this.bookingEnded = false;
          this.bookingError = null;
          this.bookingSuccess = false;
        }, 2500);
      });
  }
}
