import { createAction, props } from "@ngrx/store";
import { ListRequest } from "../../models/requests/list-request";
import { CardOrder } from "../../models/cards/card-order";
import { PaginatedApiResponse } from "../../models/paginated-api-response";

export const getCardOrdersAction = createAction(
  '[Cards] Get card orders action',
  props<{ path: string, listRequest: ListRequest }>()
);

export const storeCardOrdersAction = createAction(
  '[Cards] Store card orders action',
  props<{ data: PaginatedApiResponse<CardOrder> }>()
);

export const updateCardOrderAction = createAction(
  '[Cards] Update card order action',
  props<{ data: CardOrder }>()
);

export const deleteCardOrderAction = createAction(
  '[Cards] Delete card order action',
  props<{ id: string }>()
);
