<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{ titleText }}</h5>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="bodyText">
  <p class="description">
    {{ bodyText }}
  </p>
</div>
<div class="d-flex buttons-container modal-footer">
  <button
    type="button"
    class="btn th-btn-secondary"
    (click)="modal.close(false)"
  >
    {{ cancelText }}
  </button>
  <button type="button" class="btn btn-primary" (click)="modal.close(true)">
    {{ confirmText }}
  </button>
</div>
