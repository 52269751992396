import { Component, Input } from '@angular/core';
import { TranslationEnum } from '../../../../assets/i18n/translation-enum';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-member-birthday',
  templateUrl: './member-birthday.component.html',
  styleUrls: ['./member-birthday.component.scss'],
})
export class MemberBirthdayComponent {
  @Input()
  set memberBirthday(date: NgbDate) {
    this._memberBirthday = date;
    this.day =
      date?.day && (date.day >= 10 ? date?.day?.toString() : `0${date.day}`);
    this.month =
      date?.month &&
      (date.month >= 10 ? date?.month?.toString() : `0${date.month}`);
    this.year = date?.year?.toString();
  }

  @Input() showLabel = false;

  @Input()
  isBirthdayValid: boolean = true;

  @Input()
  displayHorizontally: boolean;

  birthdayTimeout: any;

  TranslationEnum = TranslationEnum;

  day: string;

  month: string;

  year: string;

  _memberBirthday: NgbDate;

  get memberBirthday() {
    return this._memberBirthday;
  }

  onBirthdayChange(event, unit) {
    const value = event.target.value;

    if (this.birthdayTimeout) {
      clearTimeout(this.birthdayTimeout);
    }

    this.birthdayTimeout = setTimeout(() => {
      if (unit === 'day') {
        this.memberBirthday.day = value && value[0] === '0' ? value[1] : value;
        this.day = value;
      } else if (unit === 'month') {
        this.memberBirthday.month =
          value && value[0] === '0' ? value[1] : value;
        this.month = value;
      } else if (unit === 'year') {
        this.memberBirthday.year = value;
        this.year = value;
      }
    }, 500);
  }
}
