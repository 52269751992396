import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from './account.service';

@Injectable()
export class HttpAuthenticationInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authToken = this.accountService.getAuthorizationToken();

    const authReq = this.addTokenToRequest(request, authToken);

    return next.handle(authReq) as Observable<HttpEvent<any>>;
  }

  addTokenToRequest(request, authToken) {
    return request.headers.has('Authorization') &&
      request.headers.get('Authorization').startsWith('Basic ')
      ? request
      : request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + authToken),
        });
  }
}
