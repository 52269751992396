import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input()
  modal: NgbModalRef;

  @Input()
  titleText: string;

  @Input()
  bodyText: string;

  @Input()
  cancelText: string;

  @Input()
  confirmText: string;

  constructor() {}

  ngOnInit(): void {}
}
