import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Members } from '../../../models/members';
import { Store } from '@ngrx/store';
import {
  getDefaultLocationId,
  getGymInfo,
} from '../../../store/selectors/user.selector';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  skip,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { RestrictInputType } from '../../directives/restrict-input.directive';
import { TranslationEnum } from '../../../../assets/i18n/translation-enum';
import { Classes } from 'src/app/models/classes';
import { ClassesService } from 'src/app/services/classes.service';
import { EntityService } from 'src/app/services/entity.service';
import { ListRequest } from 'src/app/models/requests/list-request';
import { PaginatedApiResponse } from 'src/app/models/paginated-api-response';
import { format } from 'date-fns';
import { HttpErrorResponse } from '@angular/common/http';
import { saveToasterNotification } from 'src/app/store/actions/misc.actions';
import { CreateToasterError } from 'src/app/models/toaster-notification';

@Component({
  selector: 'app-calendar-class',
  templateUrl: './calendar-class.component.html',
  styleUrls: ['./calendar-class.component.scss'],
})
export class CalendarClassComponent implements OnInit, OnDestroy {
  @ViewChild('confirmationModal', { static: true })
  confirmationModal: TemplateRef<any>;
  @Input()
  classItem: Classes = {} as Classes;
  loading = false;
  success = false;
  @Input()
  classId: string;
  locationId$: Observable<string>;
  gymId$: Observable<string>;
  locationId = null;
  gymId = null;
  TranslationEnum = TranslationEnum;

  memberListRequest: ListRequest = {
    linkedEntityId: this.locationId,
    paginationParam: {
      pageSize: 1,
      pageNumber: 1,
    },
    filters: {
      global: '',
    },
  };
  searchInput = '';
  searchMemberSubject$ = new BehaviorSubject<any>(null);
  searchResultMember: Members = null;

  @Output() closeAddModule = new EventEmitter<boolean>();
  restrictInputType = RestrictInputType;
  startDate: string;
  startTime: string;

  bookedMembers = [];
  waitlistMembers = [];

  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject('ClassesService')
    private classesService: ClassesService,
    private store: Store,
    @Inject('MemberService')
    private memberService: EntityService<Members>
  ) {
    this.memberService.setEntity('member');
  }

  ngOnInit(): void {
    this.locationId$ = this.store.select(getDefaultLocationId).pipe(
      tap((value) => {
        this.locationId = value;
      })
    );

    this.gymId$ = this.store.select(getGymInfo).pipe(
      map((value) => {
        this.gymId = value.gymId;
        return value.gymId;
      })
    );

    this.getMembersList();

    this.searchMemberSubject$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy),
        withLatestFrom(this.locationId$),
        skip(1),
        switchMap(([input, locationId]) => {
          this.memberListRequest.filters.global = input;
          this.memberListRequest.linkedEntityId = locationId;
          return this.memberService
            .getEntityList(this.memberListRequest)
            .pipe(catchError(() => of(false)));
        })
      )
      .subscribe((value: PaginatedApiResponse<Members>) => {
        this.searchResultMember = value.data[0] || null;
      });

    this.startDate = format(new Date(this.classItem.date), 'dd.MM.yyyy');
    this.startTime = format(new Date(this.classItem.date), 'p');
  }

  getMembersList() {
    this.classesService
      .getCalendarOccurrence(this.classItem.occurrenceId)
      .subscribe((response) => {
        this.bookedMembers = response.filter((m) => m.isBooked);
        this.waitlistMembers = response.filter((m) => !m.isBooked);
      });
  }

  bookMember() {
    this.classesService
      .bookMemberClassOccurrence(
        this.searchResultMember.id,
        this.classItem.occurrenceId
      )
      .pipe()
      .subscribe((result) => {
        this.getMembersList();

        const error = result instanceof HttpErrorResponse;
        if (error) {
          this.store.dispatch(
            saveToasterNotification({
              toasterNotification: CreateToasterError(result.error.message),
            })
          );
        }
      });
  }

  removeMember(member) {
    this.classesService
      .deleteClassOccurrence(member.bookingId)
      .subscribe(() => {
        this.getMembersList();
      });
  }

  checkInMember(member) {
    this.classesService
      .checkInClassOccurrence(member.memberId, this.classItem.occurrenceId)
      .subscribe(() => {
        this.getMembersList();
      });
  }

  @HostListener('document:keyup', ['$event'])
  handleEscapeEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeModule(false);
    }
  }

  closeModule(value) {
    this.closeAddModule.emit(value);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  }
}
