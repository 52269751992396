import { createAction, props } from '@ngrx/store';
import { MemberDocument } from '../../models/member/member-document';

export const getDocumentsAction = createAction(
  '[Member] Get documents',
  props<{ gymId: string; memberId: string }>()
);

export const storeDocumentsAction = createAction(
  '[Member] Store documents',
  props<{ documents: MemberDocument[] }>()
);
