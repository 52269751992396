import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PaginatedApiResponse } from '../../../models/paginated-api-response';
import {
  Column,
  ColumnDecoration,
  FilterField,
  FilterType,
} from '../../../models/table-data/table-data';
import { FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { ColumnFilterFormElement, Table } from 'primeng/table';

import {
  ListRequest,
  listRequest,
} from '../../../models/requests/list-request';
import {
  ActionCallback,
  TableAction,
} from '../../../models/table-data/action-callback';
import { TableActions } from '../../../models/table-data/table-actions';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  NgbDate,
  NgbDateStruct,
  NgbDropdown,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {
  getISOStringDateNoTime,
  getISOStringEndOfDayNoTime,
} from '../../utilities/utility-functions';
import { TranslationEnum } from '../../../../assets/i18n/translation-enum';

ColumnFilterFormElement.prototype.onModelChange = function (value) {
  this.filterConstraint.value = value;
  if (this.type || value === '') {
    this.dt._filter();
  }
};

ColumnFilterFormElement.prototype.onNumericInputKeyDown = function (
  event: KeyboardEvent
) {
  if (event.code === 'Backspace') {
    this.filterConstraint.value = Math.floor(this.filterConstraint.value / 10);
    if (this.filterConstraint.value === 0) {
      this.filterConstraint.value = null;
    }
  } else if (event.code === 'Delete') {
    this.filterConstraint.value = null;
  } else {
    const value = +event?.key;
    if (value >= 0 && value <= 9) {
      this.filterConstraint.value *= 10;
      this.filterConstraint.value += value;
    }
  }

  this.dt._filter();
  event.preventDefault();
};

@Component({
  selector: 'tg-top-gym-data-table',
  templateUrl: './top-gym-data-table.component.html',
  styleUrls: ['./top-gym-data-table.component.scss'],
})
export class TopGymDataTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('deleteModal', { static: true })
  deleteNotificationModal: TemplateRef<any>;

  @ViewChild('filterDrop', { static: false, read: NgbDropdown })
  dropdown: NgbDropdown;

  @Input()
  set paginatedResponse(value) {
    this.loading = false;
    this.showTable = false;
    this.paginationResponseInternal =
      !value || !value.totalRecords ? null : value;

    this.listData = value ? JSON.parse(JSON.stringify(value?.data)) : []; // clone of response list
    this.showTable = true;

    if (value && this.actions) {
      const actionsInternal = JSON.stringify(this.actions);
      this.actions = JSON.parse(actionsInternal);
    }

    if (
      value &&
      value.totalRecords > 0 &&
      value.data.length === 0 &&
      value.pageNumber !== 1 &&
      !this.retriedEmptyPage
    ) {
      this.updateData.emit();
      this.retriedEmptyPage = true;
    }
  }

  get paginatedResponse(): PaginatedApiResponse<any> {
    return this.paginationResponseInternal;
  }

  showTable = false;

  retriedEmptyPage = false;

  @Input()
  pageSize = 10;

  @Input()
  entity: string;

  @Input()
  editable: boolean = false;

  @Input()
  editableInline: boolean = false;

  @Input()
  noDataPicture: string;
  @Input()
  noDataTitle: string;
  @Input()
  noDataDescription: string;
  @Input()
  showAction: boolean = true;
  @Input()
  actionsDropdown: boolean = false;
  @Input()
  redirectToPos: boolean = false;
  @Input()
  member;

  @Input()
  paginator = true;
  paginationResponseInternal: PaginatedApiResponse<any>;
  success = false;
  matchMode = 'contains';
  columnDecorationType = ColumnDecoration;
  resetPickerOption = false;

  loading = true;
  filterType = FilterType;
  actionType = TableActions;

  filterValues: Map<string, string>;

  @Input()
  placeholderSearchInput;

  @Input()
  globalFilterFields;

  @Input()
  rowsPerPageOptions = [10, 20, 30, { showAll: 'All' }];

  @Input()
  columns: Column[];

  @Input()
  set filterFields(values: FilterField[]) {
    this._filterFields = values;
    this.filterValues = new Map();
    this._filterFields?.forEach((value) =>
      this.filterValues.set(value.field, null)
    );
  }
  _filterFields: FilterField[];
  get filterFields() {
    return this._filterFields;
  }

  @Input()
  actions: TableAction[];

  @Input()
  entityPageUrl: string;

  @Input()
  advancedStatus: boolean;

  @Input()
  returnIdOnEdit: boolean = true;

  @Input()
  tableCompressed: boolean;

  @Input()
  editByModal: boolean;

  @Input()
  complexInputErrorText: string;

  @Input()
  rowClickable: boolean;

  @Input()
  itemIdField: string = 'id';

  @Input()
  hasAddEntity: boolean = true;

  @Input()
  hasRedirect = true;

  @Input()
  dataLoadBlocked = false;

  @Input()
  displayTotalRecords = false;

  @ViewChild('pTable') pTable;

  @Output()
  updateData: EventEmitter<ListRequest> = new EventEmitter<ListRequest>();

  @Output()
  actionCallback: EventEmitter<ActionCallback> = new EventEmitter<ActionCallback>();

  @Output()
  selectItem: EventEmitter<any> = new EventEmitter<any>();

  clonedItem: any;

  @Output()
  rowEdited: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  addNew: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  editEntity: EventEmitter<string> = new EventEmitter<string>();

  loadDataSubject: Subject<LazyLoadEvent> = new Subject();
  private destroy: Subject<boolean> = new Subject<boolean>();
  console = console;
  model = '';
  changeStatusAction = TableActions.DEACTIVATE;

  listData: any = [];

  displayComplexInputError: boolean;

  saveEditDisabled: boolean;

  TranslationEnum = TranslationEnum;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    public datePipe: DatePipe,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadDataSubject
      .pipe(takeUntil(this.destroy))
      .subscribe((event: LazyLoadEvent) => {
        this.loading = true;
        const filters = JSON.parse(JSON.stringify(event.filters));
        for (const key of Object.getOwnPropertyNames(event.filters)) {
          filters[key] =
            filters?.[key]?.value === 'All' ? null : filters?.[key]?.value;
        }

        const pageSize = event.rows || this.pageSize;
        const pageNumber = event.first / (event.rows || this.pageSize) + 1;

        if (pageSize && pageNumber && !this.dataLoadBlocked) {
          const apiListRequest = listRequest(filters, pageSize, pageNumber);
          this.updateData.emit(apiListRequest);
        }
        this.dataLoadBlocked = false;
      });
  }

  changeInput(event) {
    this.model = event;
    this.pTable.filterGlobal(event, 'equals');
  }

  ngAfterViewInit(): void {
    if (this.pTable && this.filterFields) {
      this.filterFields.forEach((filter) => {
        if (filter.defaultValue) {
          this.pTable.filter(filter.defaultValue, filter.field, this.matchMode);
        }
      });
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  addFilterValue(field, value) {
    this.filterValues.set(field, value);
  }

  addFilterValueRangeDate(field, value) {
    this.filterValues.set(
      field,
      JSON.stringify({
        startDate: getISOStringDateNoTime(value.startDate),
        endDate: getISOStringEndOfDayNoTime(value.endDate),
      })
    );
  }

  filterTable() {
    this.filterValues.forEach((value, key) => {
      if (value) {
        this.pTable?.filter(value, key, this.matchMode);
      }
    });
    this.dropdown.close();
  }

  resetFilters() {
    // this.resetPickerOption = true;
    this.filterValues.forEach((value, key) => {
      if (value) {
        const field = this.filterFields.find((item) => item.field === key);
        if (field) {
          this.pTable?.filter(field?.defaultValue, key, this.matchMode);
        }
      }
    });
    this.filterValues = new Map();
    this.dropdown.close();
  }

  loadData(event: LazyLoadEvent) {
    this.loadDataSubject.next(event);
  }

  trackEntity(index: any, information: { id: any }) {
    return information ? information.id : undefined;
  }

  clear(table: Table) {
    table.clear();
  }

  checkConfirmation(actionId: TableActions, itemId: string) {
    if (actionId !== TableActions.DELETE) {
      this.actionCallback.emit({
        actionId: actionId,
        parameter: itemId,
      });
      return;
    }
    const modalToOpen = this.deleteNotificationModal;
    const modal = this.modalService.open(modalToOpen);
    modal.closed.pipe(take(1)).subscribe((result) => {
      if (result) {
        this.actionCallback.emit({
          actionId: actionId,
          parameter: itemId,
        });
      }
    });
  }

  redirectToRoute(id) {
    if (this.editByModal) {
      this.editEntity.emit(id);
    } else if (this.entityPageUrl) {
      this.router.navigate([this.entityPageUrl, id]);
    } else {
      this.selectItem.emit(id);
    }
  }

  rowClickAction(item) {
    if (!this.rowClickable) {
      return;
    }

    if (this.hasRedirect) {
      this.redirectToRoute(item[this.itemIdField]);
    } else {
      this.selectItem.emit(item);
    }
  }

  onRowEditInit(item) {
    this.clonedItem = { ...item };
  }

  onRowEditSave(item) {
    this.rowEdited.emit(item);
  }

  onRowEditCancel(item, index: number) {
    this.listData.map((item, i) => (i === index ? this.clonedItem : item));
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  matchModeByType(filter: FilterField) {
    return filter.type === FilterType.NUMERIC
      ? FilterMatchMode.STARTS_WITH
      : FilterMatchMode.CONTAINS;
  }

  startEditing(table, row) {
    if (!table.isRowEditing(row)) {
      table.initRowEdit(row);
    }
  }

  transformDate(dateString: string) {
    const date = new Date(dateString);
    return NgbDate.from({
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    } as NgbDateStruct);
  }

  actionClick(event, editing, item, actionId) {
    event?.stopPropagation();
    if (!editing) {
      this.actionCallback.emit({
        actionId: actionId,
        parameter: item.id,
      });
    } else {
      this.rowEdited.emit(item);
    }
  }

  changeDate(date: NgbDate, item) {
    item.dateEnded = new Date(
      date.year,
      date.month - 1,
      date.day
    ).toISOString();
  }

  editAction(action, column, item, table) {
    if (action.id !== TableActions.EDIT || !this.editable) {
      this.actionCallback.emit({
        actionId: action.id,
        parameter: this.returnIdOnEdit ? item.id : item,
      });
      return;
    }

    this.startEditing(table, item);
  }

  closeFilter() {
    if (this.dropdown?.isOpen()) {
      this.dropdown.close();
    }
  }

  get dropdownOpen() {
    return this.dropdown?.isOpen();
  }

  redirectPos(value) {
    this.router.navigate(['/gym/pos'], { state: { data: value } });
  }

  complexInputCondition(event, maxValue) {
    this.displayComplexInputError =
      parseInt(event.target.value) > parseInt(maxValue);
    this.saveEditDisabled = this.displayComplexInputError;
  }

  onEdit(table, item, actionId) {
    if (this.saveEditDisabled) {
      return;
    }
    this.actionClick(null, table, item, actionId);
  }
}
