import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ConfigModel } from './app/models/config-model';
import { APP_CONFIG } from './app/shared/utilities/constants';
import { Amplify, Auth } from 'aws-amplify';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/angular';

fetch(`./assets/config.json?v=${environment.configId}`)
  .then((response) => response.json())
  .then((config: ConfigModel) => {
    if (environment.production) {
      enableProdMode();

      Sentry.init({
        environment: config.sentryEnvironmentId,
        dsn: config.sentryDsn,
        tracesSampleRate: +config.sentrySampleRate,
        release: `${config.sentryProjectName}@${config.releaseId}`,
        ignoreErrors: ['Non-Error exception captured'],
        integrations: [
          new BrowserTracing({
            tracePropagationTargets: [
              config.apiUrl.replace(/(^\w+:|^)\/\/|(\/+$)/g, ''),
            ],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
      });
    }

    const awsConfig = {
      Auth: {
        userPoolId: config.cognitoUserPoolId,
        userPoolWebClientId: config.cognitoClientId,
        region: config.cognitoRegion,
        oauth: {
          domain: config.cognitoDomain,
          scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: window.location.origin + '/account/authenticated',
          redirectSignOut: window.location.origin,
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    };

    Amplify.configure(awsConfig);
    Auth.configure(awsConfig);

    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: config },
    ]).bootstrapModule(AppModule);
  });
