<div class="calendar-view-wrapper">
  <div class="filter-wrapper box-container mb-3">
    <div class="mr-3" *ngIf="trainers">
      <ng-select
        [items]="trainers"
        bindLabel="trainer"
        (change)="selectTrainer($event)"
        [placeholder]="
          selectedTrainer ? '' : (TranslationEnum.Classes.TRAINER | translate)
        "
        class="calendar-filter"
      ></ng-select>
    </div>
    <div class="mr-3" *ngIf="rooms">
      <ng-select
        [items]="rooms"
        bindLabel="room"
        (change)="selectRoom($event)"
        [placeholder]="
          selectedRoom ? '' : (TranslationEnum.Classes.ROOM | translate)
        "
        class="calendar-filter"
      ></ng-select>
    </div>
  </div>
  <div class="box-container">
    <full-calendar #calendar [options]="calendarOptions">
      <ng-template #eventContent let-arg>
        <div class="calendar-box">
          <span class="calendar-box-text">
            {{ dateToTime(arg.event) }}
          </span>
          <span class="calendar-box-text">
            {{ arg.event.title }}
          </span>
        </div>
      </ng-template>
    </full-calendar>
  </div>
</div>

<app-calendar-class
  [classItem]="class"
  *ngIf="showClassDetails"
  class="show-class"
  [class.show]="showClassDetails"
  (closeAddModule)="closeItem()"
>
</app-calendar-class>
<div class="background" *ngIf="showClassDetails" (click)="closeItem()"></div>

<ng-template #confirmationModal let-modal>
  <div class="customModal">
    <div class="custom-modal-header">
      <span class="custom-modal-title">{{
        translateEnum.Common.CONFIRM_CHANGE | translate
      }}</span>

      <span class="ml-auto close-custom-modal" (click)="closeCustomModal()">
        <img
          src="/src/assets/images/icons/close-vector.svg"
          alt=""
          class="custom-check"
        />
      </span>
    </div>
    <div class="custom-modal-body">
      <div *ngIf="!bookingEnded">
        Vei rezerva clasa
        <span class="font-weight-bold">{{ class.className }}</span> din data de
        <span class="font-weight-bold">{{
          datePipe.transform(class.date, dateFormat)
        }}</span>
        la ora
        <span class="font-weight-bold">{{
          datePipe.transform(class.date, tableHourFormat)
        }}</span>
        pentru
        <span class="font-weight-bold">{{ selectedMemberName }}</span>
      </div>
      <div *ngIf="bookingEnded">
        <span *ngIf="bookingSuccess">{{
          translateEnum.Common.BOOK_CLASS_SUCCESS | translate
        }}</span>
        <ng-container *ngIf="!bookingSuccess">
          <span
            *ngIf="bookingError.errorType === serverErrors.BOOKING_NOT_OPEN"
            >{{ translateEnum.Common.BOOK_NOT_OPEN | translate }}</span
          >
          <span *ngIf="bookingError.errorType === serverErrors.NO_MORE_SEATS">{{
            translateEnum.Common.BOOK_NO_MORE_SEATS | translate
          }}</span>
          <span
            *ngIf="bookingError.errorType === serverErrors.BOOKING_EXISTS"
            >{{ translateEnum.Common.BOOK_EXISTS | translate }}</span
          >
        </ng-container>
      </div>
    </div>

    <div class="custom-modal-actions">
      <button
        class="btn btn-cancel mr-3"
        [disabled]="bookingInProgress"
        (click)="closeCustomModal()"
      >
        {{
          (bookingEnded
            ? TranslationEnum.Common.CLOSE
            : TranslationEnum.Common.CANCEL
          ) | translate
        }}
      </button>
      <button
        class="btn btn-confirm"
        (click)="bookMember()"
        *ngIf="!bookingEnded"
      >
        {{ TranslationEnum.Common.CONFIRM | translate }}
      </button>
    </div>
  </div>
</ng-template>
