<div class="d-flex flex-column history">
  <div class="d-flex justify-content-between align-content-center">
    <div class="align-self-center title">
      {{ TranslationEnum.Dashboard.ENTRIES_LOG | translate }}
    </div>
    <div (click)="downloadReport()" class="download">
      {{ TranslationEnum.Member.DOWNLOAD_REPORT | translate }}
    </div>
  </div>
  <div class="d-flex flex-column entries mt-5">
    <div
      *ngFor="let entry of entries$ | async as entries"
      class="d-flex justify-content-between entry"
    >
      <div
        class="left-section d-flex justify-content-between align-items-center"
      >
        <div class="d-flex flex-column small-gap">
          <div class="font-weight-bold">{{ entry.membershipName }}</div>
          <div>
            {{ TranslationEnum.Card.STAFF_NAME | translate }}:
            {{ entry.staffName }}
          </div>
        </div>
        <div class="d-flex flex-column grey-small-text small-gap">
          <div>
            <span class="font-weight-bold">Check in:</span>
            {{ datePipe.transform(entry.checkInDate, dateFormatHour) }}
          </div>
          <div>
            <span class="font-weight-bold">Check out:</span>
            {{ datePipe.transform(entry.checkOutDate, dateFormatHour) }}
          </div>
        </div>
      </div>
      <div
        class="
          right-section
          d-flex
          flex-column
          justify-content-center
          align-items-center
        "
      >
        <div>{{ entry.timeDifference }}</div>
        <div class="small-text">
          {{ TranslationEnum.Report.TOTAL_TIME_SPENT | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
