<ng-container *ngrxLet="username$; let username">
  <tg-mobile-menu
    *ngIf="username && !isRefreshInProgress"
    class="d-block d-md-none"
    [entries]="menuEntries$ | async"
  ></tg-mobile-menu>

  <div class="d-flex">
    <div
      *ngIf="username && !isRefreshInProgress"
      class="menu-section d-none d-md-block"
    >
      <tg-main-menu
        [isGym]="isGym$ | async"
        [entries]="menuEntries$ | async"
      ></tg-main-menu>
    </div>
    <div
      class="content-section"
      [class.noUserLogged]="!username || isRefreshInProgress"
    >
      <div class="top-bar d-flex" *ngIf="username && !isRefreshInProgress">
        <span class="gymName align-self-center">
          {{ gymName$ | async }}
        </span>
        <div class="ml-auto">
          <div class="d-flex menu-right">
            <div class="notification">
              <img
                src="../assets/images/menu/notification.png"
                alt=""
                class="notification-icon"
              />
            </div>

            <div class="translation">
              <div
                class="button-wraper"
                ngbDropdown
                placement="bottom-right"
                [autoClose]="true"
              >
                <button
                  type="button"
                  class="profileMenu"
                  id="dropdownLanguage"
                  ngbDropdownToggle
                >
                  <div
                    class="d-flex align-items-center"
                    *ngIf="selectedLanguage"
                  >
                    <img
                      class="flag-icon mr-2"
                      [src]="
                        'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/1x1/' +
                        selectedLanguage.flag +
                        '.svg'
                      "
                      alt="flag"
                    />
                    <span>{{ selectedLanguage.name }}</span>
                  </div>
                </button>

                <div ngbDropdownMenu aria-labelledby="dropdownLanguage">
                  <a
                    ngbDropdownItem
                    class="sideMenuItem d-flex align-items-center"
                    *ngFor="let lang of availableLanguages"
                    (click)="changeLanguage(lang.code)"
                  >
                    <img
                      class="flag-icon mr-2"
                      [src]="
                        'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/1x1/' +
                        lang.flag +
                        '.svg'
                      "
                      alt="flag"
                    />
                    <span>{{ lang.name }}</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="avatar">
              <div *ngIf="!(impersonated$ | async); else imporsonateMenu">
                <div
                  class="d-inline-block mr-2 button-wraper"
                  ngbDropdown
                  #filterDrop="ngbDropdown"
                  placement="bottom-right"
                  [autoClose]="true"
                >
                  <button
                    type="button"
                    class="profileMenu exitImpersonation"
                    id="dropdownFilter"
                    ngbDropdownToggle
                  >
                    <div class="d-flex align-items-center">
                      <span class="avatar-placeholder"></span>
                      <span class="avatar-name">
                        {{ username }}
                      </span>
                    </div>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownFilter">
                    <a
                      ngbDropdownItem
                      class="sideMenuItem"
                      [routerLink]="['/profile']"
                    >
                      {{ TranslationEnum.Menu.PROFILE | translate }}
                    </a>
                    <span
                      ngbDropdownItem
                      class="sideMenuItem"
                      (click)="logout()"
                      >{{ TranslationEnum.Menu.LOG_OUT | translate }}
                    </span>
                  </div>
                </div>
              </div>

              <ng-template #imporsonateMenu>
                <div
                  class="d-inline-block button-wraper"
                  ngbDropdown
                  #myDrop="ngbDropdown"
                  *ngIf="impersonated$ | async"
                >
                  <button
                    type="button"
                    class="exitImpersonation"
                    id="dropdownManual"
                    ngbDropdownAnchor
                    (focus)="myDrop.open()"
                  >
                    <div class="d-flex align-items-center">
                      <span class="avatar-placeholder"></span>
                      <span class="avatar-name">
                        {{ username }}
                      </span>
                    </div>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownManual">
                    <span
                      ngbDropdownItem
                      (click)="exitImpersonation()"
                      class="sideMenuItem"
                    >
                      Exit Impersonation
                    </span>
                    <span ngbDropdownItem class="sideMenuItem">
                      {{ TranslationEnum.Menu.PROFILE | translate }}
                    </span>
                    <span
                      ngbDropdownItem
                      class="sideMenuItem"
                      (click)="logout()"
                      >{{ TranslationEnum.Menu.LOG_OUT | translate }}
                    </span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="main-content">
        <router-outlet></router-outlet>
      </div>

      <div
        class="
          bottom-bar
          d-flex
          justify-content-between
          flex-column flex-md-row
        "
      >
        <ul class="footer-menu d-flex">
          <li *ngFor="let item of footerMenu">
            <a href="{{ item.link }}">
              {{ item.label }}
            </a>
          </li>
        </ul>

        <span class="copy">Copyrights © TopGym</span>
      </div>
    </div>
  </div>

  <div
    *ngIf="username && showCheckIn"
    class="background"
    (click)="changeModalState()"
  ></div>

  <app-check-in
    *ngIf="showCheckIn"
    class="check-in"
    [class.show]="showCheckIn"
    [checkInResponse]="checkInResponse"
    [memberships]="checkInResponse?.membershipCheckIns"
    [memberId]="checkInResponse?.memberId"
    [memberName]="checkInResponse?.username"
    [locationId]="locationId"
    [checkin]="checkInResponse?.status !== barcodeCheck.CHECKED_OUT"
    (close)="changeModalState()"
  ></app-check-in>
</ng-container>
