<tg-top-gym-data-table
  [paginatedResponse]="paginatedResponse"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [pageSize]="pageSize"
  [columns]="columns"
  [filterFields]="filterFields"
  [entityPageUrl]="'/gym/products/'"
  (actionCallback)="actionCallback($event?.actionId, $event?.parameter)"
  (updateData)="loadProducts($event)"
  [entity]="productEntity"
  [globalFilterFields]="globalFilterFields"
  [placeholderSearchInput]="TranslationEnum.Product.SEARCH_PRODUCT | translate"
  (addNew)="addNew()"
  (editEntity)="editProduct($event)"
  [tableCompressed]="true"
  [editByModal]="true"
  [rowClickable]="true"
  [noDataPicture]="'noDataProducts'"
  [noDataTitle]="'No product found'"
  [noDataDescription]="
    'Please click Add Product button to start adding a new product'
  "
>
</tg-top-gym-data-table>

<app-products-edit
  class="add-product"
  *ngIf="showAddProduct || showEditProduct"
  [class.show]="showAddProduct || showEditProduct"
  (closeModal)="closeActionModal($event)"
  [productId]="productEditedId"
  (actionCallback)="actionCallback($event?.actionId, $event?.parameter)"
>
</app-products-edit>

<div
  class="background"
  *ngIf="showEditProduct || showAddProduct"
  (click)="closeActionModal(false)"
></div>
