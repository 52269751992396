import { createAction, props } from '@ngrx/store';
import { ProductCategory } from '../../models/membership-category';

export const getProductCategories = createAction(
  '[Gym] Get product categories'
);

export const storeProductCategories = createAction(
  '[Gym] Store product categories',
  props<{ productCategories: ProductCategory[] }>()
);
