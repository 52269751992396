import { Injectable } from '@angular/core';
import { CardsService } from '../../services/cards.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import * as CardsActions from '../actions/cards.actions';
import { filter, map, switchMap } from "rxjs/operators";

@Injectable()
export class CardsEffects {
  constructor(
    private actions$: Actions,
    private cardsService: CardsService,
  ) {}

  getCards: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CardsActions.getCardOrdersAction),
      filter((action) => !!action?.listRequest && !!action?.path),
      switchMap((data) =>
        this.cardsService.getCardOrders(data.path, data.listRequest)
      ),
      map((data) => CardsActions.storeCardOrdersAction({ data }))
    )
  );

}
