import { ActionReducerMap } from '@ngrx/store';
import { userReducer, UserState } from './reducers/user.reducer';
import { miscReducer, MiscState } from './reducers/misc.reducer';
import { gymReducer, GymState } from './reducers/gym.reducer';
import { cardsReducer, CardsState } from './reducers/cards.reducer';
import { memberReducer, MemberState } from './reducers/member.reducer';
import { invoiceReducer, InvoiceState } from './reducers/invoice.reducer';

export interface AppState {
  userState: UserState;
  miscState: MiscState;
  gymState: GymState;
  cardsState: CardsState;
  memberState: MemberState;
  invoiceState: InvoiceState;
}

export const reducers: ActionReducerMap<AppState> = {
  userState: userReducer,
  miscState: miscReducer,
  gymState: gymReducer,
  cardsState: cardsReducer,
  memberState: memberReducer,
  invoiceState: invoiceReducer,
};
