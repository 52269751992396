import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GymActions from '../actions/gym.actions';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { Router } from '@angular/router';
import { EntityService } from '../../services/entity.service';
import { ProductCategory } from '../../models/membership-category';
import { Store } from '@ngrx/store';
import { getDefaultLocationId } from '../selectors/user.selector';

@Injectable()
export class GymEffects {
  categoryRequest = {
    linkedEntityId: null,
    paginationParam: {
      pageNumber: 1,
      pageSize: 100,
    },
    filters: {
      name: '',
    },
  };

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private router: Router,
    @Inject('ProductCategoryService')
    private categoryService: EntityService<ProductCategory>,
    private store: Store
  ) {
    this.categoryService.setEntity('product/category');
  }

  getProductCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GymActions.getProductCategories),
      withLatestFrom(this.store.select(getDefaultLocationId)),
      filter((locationId) => !!locationId),
      map((locationId) => locationId[1]),
      switchMap((locationId) => {
        this.categoryRequest.linkedEntityId = locationId;
        return this.categoryService.getEntityList(this.categoryRequest);
      }),
      map((result) =>
        GymActions.storeProductCategories({
          productCategories: result?.data,
        })
      )
    )
  );
}
