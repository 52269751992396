<ng-container>
  <app-toaster-notifications
    [notification]="notification$ | async"
    [showNot]="showNotification"
    (hideEvent)="showNotification = false"
  >
  </app-toaster-notifications>
</ng-container>

<router-outlet></router-outlet>
