export interface Product {
  id: string;
  locationId: string;
  name: string;
  price: number;
  barcode: string;
  stock: number;
  note: string;
  vatPosition: number;
  department: number;
  articleGroup: number;
  status: ProductStatus;
  category: string;
  vatId: number;
}

export enum ProductStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
