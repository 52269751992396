<div [class.editSection]="productId">
  <div class="title-section">
    <div class="d-flex justify-content-between">
      <span class="title font-weight-bold">
        {{
          (productId
            ? TranslationEnum.Product.EDIT_PRODUCT
            : TranslationEnum.Product.ADD_PRODUCT
          ) | translate
        }}
      </span>
      <div class="close-btn cursor-pointer" (click)="closeModule(false)">
        <img
          src="/assets/images/icons/close.svg"
          alt="close"
          class="icon-close"
        />
      </div>
    </div>
  </div>

  <div [formGroup]="productForm" class="content-wrapper">
    <h5 class="section-info d-flex justify-content-between">
      {{ TranslationEnum.Product.PRODUCT_DETAILS | translate }}

      <div class="custom-input flex-row" *ngIf="!!productId">
        <span class="label mr-2">
          {{ TranslationEnum.Common.STATUS | translate }}
        </span>
        <tg-toggle
          [disabled]="productForm.disabled"
          formControlName="status"
          [switchActive]="product.status === statuses[0]"
          (switchActiveChange)="toggleStatusClick()"
        >
        </tg-toggle>
      </div>
    </h5>

    <div class="flex-column d-flex">
      <div class="custom-input">
        <span class="label">
          {{ TranslationEnum.Common.NAME | translate }}
          <span class="text-danger">*</span>
        </span>
        <input
          formControlName="name"
          type="text"
          [class.error]="showErrors && productForm.get('name').invalid"
          (change)="openConfirmModal()"
        />
        <div
          class="error-container"
          *ngIf="productForm.controls['name'].invalid && showErrors"
        >
          <span *ngIf="productForm.controls['name'].errors.required">
            {{ formErrors.mandatoryField }}
          </span>

          <span *ngIf="productForm.controls['name'].errors.minlength">
            {{
              formErrors.pleaseInsert +
                nameMinLength +
                formErrors.orMoreCharacters
            }}
          </span>

          <span *ngIf="productForm.controls['name'].errors.maxlength">
            {{ formErrors.maxCharacters + nameMaxLength }}
          </span>

          <span *ngIf="productForm.controls['name'].errors.pattern">
            {{ formErrors.specialCharacters }}
          </span>
        </div>
      </div>

      <div class="custom-input">
        <span class="label">
          {{ TranslationEnum.Common.PRICE | translate }}
          <span class="text-danger">*</span>
        </span>
        <input
          type="number"
          formControlName="price"
          [class.error]="showErrors && productForm.get('price').invalid"
          class="w-100"
          (change)="openConfirmModal()"
        />
        <div
          class="error-container"
          *ngIf="productForm.controls['price'].invalid && showErrors"
        >
          <span *ngIf="productForm.controls['price'].errors.required">
            {{ formErrors.mandatoryField }}
          </span>

          <span *ngIf="productForm.controls['price'].errors.min">
            {{ formErrors.specialCharacters }}
          </span>
        </div>
      </div>

      <div class="custom-input">
        <span class="label">
          {{ TranslationEnum.Common.CATEGORY | translate }}
        </span>
        <span *ngIf="showCreate" (click)="addCategory()">Create</span>
        <ng-select
          #categorySelect
          bindLabel="name"
          class="mb-1"
          id="category"
          formControlName="category"
          [items]="categories"
          [multiple]="false"
          [maxSelectedItems]="1"
          (search)="searchCategorySubject.next($event)"
          (change)="detectChange($event)"
          [class.error]="hasErrors(productForm.get('category'))"
        >
        </ng-select>
      </div>

      <h5 class="section-info mt-4">
        {{ TranslationEnum.Common.SETTING | translate }}
      </h5>

      <div class="custom-input">
        <span class="label">
          {{ TranslationEnum.Product.BARCODE | translate }}
          <span class="text-danger">*</span>
        </span>
        <input
          type="text"
          formControlName="barcode"
          [class.error]="showErrors && productForm.get('barcode').invalid"
          class="w-100"
        />
        <div
          class="error-container"
          *ngIf="productForm.controls['barcode'].invalid && showErrors"
        >
          <span *ngIf="productForm.controls['barcode'].errors.required">
            {{ formErrors.mandatoryField }}
          </span>
        </div>
      </div>

      <div class="custom-input">
        <span class="label">
          {{ TranslationEnum.Product.STOCK | translate }}
          <span class="text-danger">*</span>
        </span>
        <input
          type="number"
          formControlName="stock"
          [class.error]="showErrors && productForm.get('stock').invalid"
          class="w-100"
        />

        <div
          class="error-container"
          *ngIf="productForm.controls['stock'].invalid && showErrors"
        >
          <span *ngIf="productForm.controls['stock'].errors.required">
            {{ formErrors.mandatoryField }}
          </span>

          <span *ngIf="productForm.controls['stock'].errors.pattern">
            {{ formErrors.specialCharacters }}
          </span>
        </div>
      </div>

      <div class="d-flex flex-column custom-input">
        <span class="label">
          {{ TranslationEnum.Common.VAT | translate }}
          <span class="text-danger">*</span>
        </span>
        <ng-select
          [items]="productsVat"
          [multiple]="false"
          formControlName="vat"
          bindLabel="value"
          (change)="changeVat($event)"
          [class.error]="showErrors && productForm.get('vat').invalid"
        >
        </ng-select>

        <div
          class="error-container"
          *ngIf="productForm.controls['vat'].invalid && showErrors"
        >
          <span *ngIf="productForm.controls['vat'].errors.required">
            {{ formErrors.mandatoryField }}
          </span>
        </div>
      </div>

      <div class="d-flex flex-column note-container mt-2 custom-input">
        <span class="label">
          {{ TranslationEnum.Common.NOTE | translate }}
        </span>
        <textarea id="note" name="note" formControlName="note"> </textarea>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button
        *ngIf="productId"
        class="btn btn-danger mr-auto"
        (click)="deleteClick()"
        [disabled]="loading"
      >
        {{ TranslationEnum.Common.DELETE | translate }}
      </button>

      <span class="btn btn-abort mr-4" (click)="closeModule(false)">
        {{ TranslationEnum.Common.CANCEL | translate }}
      </span>

      <button
        class="btn btn-primary"
        (click)="saveProduct()"
        (window:keypress)="addProductNew($event)"
      >
        {{ TranslationEnum.Common.SAVE | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #categoryNotFound>
  <span
    class="ng-option"
    [class.option-disabled]="!categoryCreateName"
    (click)="addCategory()"
  >
    {{ TranslationEnum.Common.CREATE | translate }}...
  </span>
</ng-template>

<ng-template #confirmationModal let-modal>
  <div class="customModal">
    <div class="custom-modal-header">
      <span class="custom-modal-title">Confirm Change</span>

      <span class="ml-auto close-custom-modal" (click)="closeCustomModal()">
        <img
          src="/assets/images/icons/close-vector.svg"
          alt=""
          class="custom-check"
        />
      </span>
    </div>
    <div class="custom-modal-body">
      <img src="/assets/images/icons/check2.svg" alt="" class="custom-check" />
      <p class="custom-modal-description">
        {{ TranslationEnum.Product.BY_EDITING_THE | translate }}
        <span class="product_name">{{ initialProductName }}</span> ,
        {{ TranslationEnum.Product.ALL_USAGE | translate }}.
        {{ TranslationEnum.Product.CONFIRM_EDIT | translate }}
      </p>
    </div>

    <div class="custom-modal-actions">
      <button class="btn btn-cancel mr-3" (click)="resetField()">
        {{ TranslationEnum.Common.CANCEL | translate }}
      </button>
      <button class="btn btn-confirm" (click)="confimEdit()">
        {{ TranslationEnum.Common.CONFIRM | translate }}
      </button>
    </div>
  </div>
</ng-template>
