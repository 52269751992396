import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SecurityContext,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslationEnum } from '../../../../../assets/i18n/translation-enum';
import { dateFormat } from '../../../utilities/constants';
import { CheckInResponse } from '../../../../models/check-in-response';
import { of, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, take } from 'rxjs/operators';
import { MemberDetailsService } from '../../../../services/member-details.service';
import { WebcamImage } from 'ngx-webcam';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../../../services/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-in-header',
  templateUrl: './check-in-header.component.html',
  styleUrls: ['./check-in-header.component.scss'],
})
export class CheckInHeaderComponent implements OnInit {
  TranslationEnum = TranslationEnum;
  @Input() set checkInResponse(value: CheckInResponse) {
    this.info = value;
    this.comment = value.comment;
    this.setMemberIcon();
  }

  get checkInResponse() {
    return this.info;
  }

  @Input() locationId: string;
  @Input() hasActiveMembership = false;

  info: CheckInResponse;
  comment: string = null;
  updateComment$ = new Subject<string>();
  photoModal = null;
  formData = new FormData();
  inProgress = false;
  profilePhotoExists = true;
  profilePhoto;
  pipeDateFormat = dateFormat;
  triggerPhoto = new Subject<void>();
  @ViewChild('webcamModal') webcamModal: TemplateRef<any>;
  @Output() close = new EventEmitter();
  constructor(
    private memberDetailsService: MemberDetailsService,
    private modalService: NgbModal,
    private configService: ConfigService,
    public domSanitizer: DomSanitizer,
    public datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.updateComment$.pipe(debounceTime(500)).subscribe((comment) => {
      this.memberDetailsService
        .editMemberComment(
          this.checkInResponse.memberId,
          this.locationId,
          comment
        )
        .subscribe();
    });
  }

  setMemberIcon(): void {
    const rand = new Date().getTime();
    if (this.checkInResponse.memberId) {
      this.profilePhoto = this.domSanitizer.sanitize(
        SecurityContext.URL,
        `${this.configService.config.profilePhotoPath}/profile/${this.checkInResponse.memberId}.jpg?rand=${rand}`
      );
    }
  }

  onError() {
    this.profilePhotoExists = false;
  }

  goToPos() {
    this.router.navigate(['/gym/pos'], {
      queryParams: { memberId: this.checkInResponse.memberId },
    });
    this.close.emit();
  }

  updateComment(value) {
    this.updateComment$.next(value);
  }

  uploadPhoto() {
    this.photoModal = this.modalService.open(this.webcamModal);
  }

  handleWebcamImage(photo: WebcamImage) {
    this.inProgress = true;
    this.formData = new FormData();
    const arr = photo.imageAsDataUrl.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File(
      [u8arr],
      this.checkInResponse.barcode + '.jpg',
      {
        type: 'jpg',
      }
    );
    this.profilePhotoExists = true;

    this.formData.append('file', file, this.checkInResponse.memberId + '.jpg');
    this.memberDetailsService
      .uploadMemberPhoto(this.formData)
      .pipe(
        take(1),
        switchMap(() => {
          this.photoModal.close();
          return of(true);
        }),
        delay(1000),
        switchMap(() => {
          this.setMemberIcon();
          return of(true);
        }),
        delay(1000),
        switchMap(() => {
          if (!this.profilePhotoExists) {
            this.profilePhotoExists = true;
            this.setMemberIcon();
          }
          return of(true);
        })
      )
      .subscribe(() => {
        this.inProgress = false;
      });
  }

  goToProfile() {
    this.router.navigate([`/gym/members/${this.checkInResponse.memberId}`]);
    this.close.emit();
  }

  openMessaging() {
    window.open(`https://wa.me/4${this.checkInResponse?.phone}`, '_blank');
  }

  protected readonly dateFormat = dateFormat;
}
