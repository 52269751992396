import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { dateFormat } from '../../utilities/constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input()
  placeholder: string = '';

  @Input()
  navigation: 'select' | 'arrows' | 'none' = 'arrows';

  @Input()
  minDate: NgbDateStruct;

  @Input()
  maxDate: NgbDateStruct;

  hoveredDate: NgbDate | null = null;

  @Input()
  dateSelected: NgbDate;

  @Input()
  startDate: NgbDate;

  @Input()
  disabled = false;

  @Output()
  dateSelectedChange = new EventEmitter<NgbDate>();

  constructor(public datePipe: DatePipe) {}

  ngOnInit(): void {}

  onDateSelection(date: NgbDate) {
    this.dateSelected = date;
    this.dateSelectedChange.emit(this.dateSelected);
    // d.toggle();
  }

  dateFormat() {
    return !this.dateSelected
      ? this.placeholder
      : this.datePipe.transform(
          new Date(
            this.dateSelected.year,
            this.dateSelected.month - 1,
            this.dateSelected.day
          ),
          dateFormat
        );
  }
}
